import { CONSTANTS } from '..'

export const allFilterOptions = {
  isEqualTo: { label: 'Is equal to', value: 'equals' },
  isNotEqualTo: { label: 'Is not equal to', value: 'notEquals' },
  isGreaterThan: { label: 'Is greater than', value: 'greaterThan' },
  isGreaterThanEquals: { label: 'Is greater than equals', value: 'greaterThanEquals' },
  isLessThan: { label: 'Is less than', value: 'lessThan' },
  isLessThanEquals: { label: 'Is less than equals', value: 'lessThanEquals' },
  null: { label: 'Null', value: 'null' },
  contains: { label: 'Contains', value: 'contains' },
  hasValue: { label: 'Has value', value: 'in' },
  startsWith: { label: 'Starts with', value: 'startsWith' },
  endsWith: { label: 'Ends with', value: 'endsWith' },
}

export const filterConditions = {
  [CONSTANTS.FIELDTYPES.DATETIME]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Is greater than', value: 'greaterThan' },
    { label: 'Is greater than equals', value: 'greaterThanEquals' },
    { label: 'Is less than', value: 'lessThan' },
    { label: 'Is less than equals', value: 'lessThanEquals' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.TEXT]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts with', value: 'startsWith' },
    { label: 'Ends with', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
    { label: 'Has value', value: 'in' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.SELECT]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Has value', value: 'in' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.BOOLEAN]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.NUMBER]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Is greater than', value: 'greaterThan' },
    { label: 'Is greater than equals', value: 'greaterThanEquals' },
    { label: 'Is less than', value: 'lessThan' },
    { label: 'Is less than equals', value: 'lessThanEquals' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.ASSETGROUPNAMETEXT]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts with', value: 'startsWith' },
    { label: 'Ends with', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
    { label: 'Null', value: 'null' },
  ],
  [CONSTANTS.FIELDTYPES.FILE]: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Null', value: 'null' },
  ],
}

export const assetDefaultFields = [
  {
    id: 'image',
    fieldName: 'image',
    label: 'Image',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 0,
    class: 'list_image',
  },
  {
    id: 'blitzId',
    fieldName: 'blitzId',
    label: 'Blitz Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 1,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'contentId',
    fieldName: 'contentId',
    label: 'Content Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 2,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'legacyHouseId',
    fieldName: 'legacyHouseId',
    label: 'Legacy House Id',
    fieldType: 'STRING',
    sort: false,
    filter: false,
    viewInListing: false,
    position: 4,
    class: '',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'state',
    fieldName: 'state',
    label: 'Asset State',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 5,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetType',
    fieldName: 'assetType',
    label: 'Asset Type',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 6,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetCategory',
    fieldName: 'assetCategory',
    label: 'Asset Category',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 7,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'contentCategory',
    fieldName: 'contentCategory',
    label: 'Content Category',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 8,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'firstCreatedOn',
    fieldName: 'firstCreatedOn',
    label: 'Creation Date',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 14,
    fieldType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.DATETIME],
  },
  {
    id: 'serviceAlias',
    fieldName: 'serviceAlias',
    label: 'Partner',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 15,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
]

export const assetDefaultFieldsAssociated = [
  {
    id: 'image',
    fieldName: 'image',
    label: 'Image',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 0,
    class: 'list_image',
  },
  {
    id: 'blitzId',
    fieldName: 'blitzId',
    label: 'Blitz Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 1,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'contentId',
    fieldName: 'contentId',
    label: 'Content Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 2,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'state',
    fieldName: 'state',
    label: 'Asset State',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 5,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetType',
    fieldName: 'assetType',
    label: 'Asset Type',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 6,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetCategory',
    fieldName: 'assetCategory',
    label: 'Asset Category',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 7,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'contentCategory',
    fieldName: 'contentCategory',
    label: 'Content Category',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 8,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'firstCreatedOn',
    fieldName: 'firstCreatedOn',
    label: 'Creation Date',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 14,
    fieldType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.DATETIME],
  },
  {
    id: 'serviceAlias',
    fieldName: 'serviceAlias',
    label: 'Partner',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 15,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
]

export const assetStatusDefaultFields = [
  {
    id: 'image',
    fieldName: 'image',
    label: 'Image',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 0,
    class: 'list_image',
  },
  {
    id: 'blitzId',
    fieldName: 'blitzId',
    label: 'Blitz Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 1,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'contentId',
    fieldName: 'contentId',
    label: 'Content Id',
    fieldType: 'NUMBER',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 2,
    class: 'list_asset_id',
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.NUMBER],
  },
  {
    id: 'state',
    fieldName: 'state',
    label: 'Asset State',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 5,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetType',
    fieldName: 'assetType',
    label: 'Asset Type',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 6,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'assetCategory',
    fieldName: 'assetCategory',
    label: 'Asset Categroy',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 7,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'contentCategory',
    fieldName: 'contentCategory',
    label: 'Content Category',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 8,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
  {
    id: 'firstCreatedOn',
    fieldName: 'firstCreatedOn',
    label: 'Creation Date',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 14,
    fieldType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueType: CONSTANTS.FIELDTYPES.DATETIME,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.DATETIME],
  },
  {
    id: 'serviceAlias',
    fieldName: 'serviceAlias',
    label: 'Partner',
    sort: true,
    filter: true,
    viewInListing: true,
    position: 15,
    filterValueCondition: filterConditions[CONSTANTS.FIELDTYPES.TEXT],
  },
]
export const dateFilterOption = [
  {
    label: 'Is equal to',
    value: 'equals',
  },
  {
    label: 'Is not equal to',
    value: 'notEquals',
  },
  {
    label: 'Is greater than',
    value: 'greaterThan',
  },
  {
    label: 'Is greater than equals',
    value: 'greaterThanEquals',
  },
  {
    label: 'Is less than',
    value: 'lessThan',
  },
  {
    label: 'Is less than equals',
    value: 'lessThanEquals',
  },
]
