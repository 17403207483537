import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

interface OrchestrationPersisitState {
  existingId: any
  appliedFilter?: []
}
const initialState: OrchestrationPersisitState = {
  existingId: {},
  appliedFilter: [],
}
const orchestrationPersistSlice = createSlice({
  name: 'orchestrationPersist',
  initialState,
  reducers: {
    setSelectedGroupId: (state, action: PayloadAction<any>) => {
      state.existingId = action.payload
    },
    setAppliedFilterinStore: (state, action) => {
      state.appliedFilter = action.payload
    },
  },
})
export const { setSelectedGroupId, setAppliedFilterinStore } = orchestrationPersistSlice.actions

const orchestrationPersistSelector = (state: {
  orchestrationPersist: OrchestrationPersisitState
}) => state.orchestrationPersist

export const selectSelectedId = createSelector(
  orchestrationPersistSelector,
  (state) => state.existingId,
)
export const selectAppliedFilter = createSelector(
  orchestrationPersistSelector,
  (state) => state.appliedFilter,
)
export default orchestrationPersistSlice
