import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import RoleConfirmation from '../../Components/Dialogs/RoleConfirmation'
import ListingComponent from '../../Components/Molecule/ListingComponent'
import { Breadcrumbs } from '../../Components/Shared'
import BackButton from '../../Components/Shared/BackButton'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../Constants'
import { HOME_PAGE, USER_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import { combineErrorMessage, objectToQueryString } from '../../Helpers/Util'
import { RoleConfirmationModal, UserListingModal, viewRoleAPIDefinationModal } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { setLoader } from '../../Store/Slice/loader.slice'

const SaveSearchList = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const [locationState, setLocationState] = useState<any>(null)
  const [SaveDataCount, SetSaveDataCount] = useState(0)
  const [SaveRecord, SetSaveRecord] = useState<any[]>([])
  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [selectedPinnedId, setSelectedPinnedId] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    setLocationState(state)
  }, [])
  const getSaveSearchListFunction = async (options: UserListingModal) => {
    const paramsData = objectToQueryString(options)

    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal =
      USER_MANAGEMENT_CRUD.SAVE_SEARCH_LIST(paramsData)
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      SetSaveDataCount(result?.data?.count)
      const tempData: any[] = []
      result?.data?.records?.forEach((ele: any, index: any) => {
        tempData.push({
          _id: ele?._id,
          name: ele.name,
          isPinnedToDashboard: ele.isPinnedToDashboard ? 'Yes' : 'No',
          action: (
            <div
              className='col37'
              data-testid={`remove-${index}`}
              onClick={(e: any) => {
                e.stopPropagation()
                openDialog(ele.name, ele._id)
              }}
            >
              Remove
            </div>
          ),
        })
      })
      SetSaveRecord(tempData)
      dispatch(setLoader(false))
    } else {
      SetSaveRecord([])
      dispatch(setLoader(false))
    }
  }
  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
    setSelectedPinnedId('')
  }
  const openDialog = (name: string, id: any) => {
    setSelectedPinnedId(id)
    setDialogProps({
      heading: 'Remove Saved Searches',
      description: (
        <div>
          <p>Are you sure you want to remove {name}?</p>
        </div>
      ),
      color: 'warning',
      Icon: 'DeleteRoleWarIcon',
      open: true,
      iconColor: 'warning',
      cancelButtonLabel: 'Close',
      successButtonLabel: 'Remove',
      handleClose: handleClose,
    })
  }

  const handleUnpinClick = async () => {
    const requestedData = HOME_PAGE.DELETE_PINNED_SEARCH(selectedPinnedId, false)
    const result = await mainApiService(requestedData)
    if (result.message) {
      handleClose()
      dispatch(showAlert([true, result?.message, 'success']))
      getSaveSearchListFunction({ page: 1, limit: 10 })
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    }
  }

  return (
    <div className='p-16'>
      <div className='p-8'>
        <div className='p-8'>
          <Breadcrumbs breadcrums={BREADCRUMS.SAVE_SEARCH_LIST() as any} />
        </div>
        <div>
          <BackButton
            label={'Saved Searches'}
            className='col73 p-8'
            handleOnClick={(e: any) => {
              e.preventDefault()
              // navigate(reservePath?.pathName, { state: reservePath?.state })
              navigate(locationState?.pathName, { state: locationState?.state })
            }}
          />
        </div>
      </div>
      <div>
        <div className='associated-users-custom-table custom-col'>
          <ListingComponent
            columns={CONSTANTS.SAVE_SEARCH_LISTING_COLUMNS}
            showCreateButton={false}
            showSearchField={false}
            tableRows={SaveRecord}
            dataCount={SaveDataCount}
            handleRowClick={(ev: any) => {
              navigate(`/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search`, {
                state: { id: ev._id },
              })
            }}
            searchFunction={(search, sorttype, coltype, filter, pagenumber, pagecount) => {
              getSaveSearchListFunction({
                sortBy: coltype,
                sortType: sorttype,
                page: pagenumber,
                limit: pagecount,
              })
            }}
            paginationOption={[
              { value: 10, label: 10 },
              { value: 20, label: 20 },
              { value: 50, label: 50 },
            ]}
          />
        </div>
      </div>
      <RoleConfirmation
        handleSuccess={handleUnpinClick}
        {...dialogProps}
        handleClose={handleClose}
      />
    </div>
  )
}

export default SaveSearchList
