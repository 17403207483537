import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React, { useEffect, useState } from 'react'
import { CONSTANTS } from '../../../Constants/Constant'
import style from '../../Molecule/Molecule.module.scss'

interface MarkAsDoneModal {
  isViewMode?: boolean
  disabled: boolean | undefined
  statusAll: any
  metaCategory: string | undefined
  onClickMarkAsDone?: () => void
}

export const MarkAsDone: React.FC<MarkAsDoneModal> = ({
  isViewMode = false,
  disabled = false,
  statusAll = [],
  metaCategory = '',
  onClickMarkAsDone,
}) => {
  const [label, setLabel] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (disabled) {
      setLabel(CONSTANTS.MARK_DONE_LABEL.FIRST)
    } else {
      const status =
        (statusAll || [])?.find((item: any) => item?.metaCategoryKey === metaCategory)
          ?.markDoneStatus || ''
      setLabel(
        status === CONSTANTS.MARK_DONE_STATUS.PASS
          ? CONSTANTS.MARK_DONE_LABEL.THIRD
          : CONSTANTS.MARK_DONE_LABEL.SECOND,
      )
      setStatus(status)
    }
  }, [metaCategory, disabled, statusAll])

  const handleClickMarkAsDone = () => {
    if (onClickMarkAsDone) {
      onClickMarkAsDone()
    }
  }
  let fill = '#b1b4b6'
  if (!disabled) {
    fill = status === CONSTANTS.MARK_DONE_STATUS.PASS ? '#005a30' : '#B0CCBF'
  }
  return (
    <div
      data-testid='mark-as-done'
      className={!(disabled || isViewMode) ? 'cursor-pointer' : ''}
      onClick={() => (disabled ? '' : handleClickMarkAsDone())}
    >
      <div
        style={{ textAlign: 'center' }}
        className={`${disabled ? style.markDone : style.markDoneActive} `}
      >
        <span className='d-flex justify-content-end align-items-center h-26px'>
          <CheckCircleIcon style={{ fill }} data-testid='unchecked-icon' />
          &nbsp;
          {label}
        </span>
      </div>
    </div>
  )
}
