import { unwrapResult } from '@reduxjs/toolkit'
import { addDays, addWeeks, endOfDay, format, getTime } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import { PARTNER_CONSTENT } from '../Components/Partners/PartnersEditor/PartnersConstent'
import CONSTANTS, { PAGES_ROUTES } from '../Constants'
import { AUTH_MANAGEMENT, BULK_UPDATE_APIS } from '../Constants/ApiDefinations'
import { TypeObject, roleModulesModal } from '../Models'
import mainApiService from '../Services'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
import { generatePresignedUrlForSubtitle } from '../Store/Slice/orchestration.slice'
import { setAppliedFilterinStore } from '../Store/Slice/orchestrationPersist.slice'
import { setAuthConfig } from '../Store/Slice/user.slice'
import { secondsToHMS } from './DurationHelper'
import { getLocalStorage, setLocalStorage } from './Storage'

export const breadcrumsParentMetaCategory = (assetCategory: string, metaCategory: string) => {
  if (
    (assetCategory === CONSTANTS.ASSET_CATEGORY_ORDER[0] ||
      assetCategory === CONSTANTS.ASSET_CATEGORY_ORDER[1]) &&
    metaCategory === CONSTANTS.CATEGORIES.CONTENT_MEDIA_VIDEO
  ) {
    return CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY
  }
  return metaCategory
}

export const getStatus = (status: any) => {
  const statusMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: 'Draft-Created',
    [CONSTANTS.ASSET_STATUS.DRAFT]: 'Draft',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: 'Draft Complete',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: 'Draft Under QC',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING]: 'Ready for Publishing',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_PUBLISH]: 'Scheduled For Publish',
    [CONSTANTS.ASSET_STATUS.FAILED]: 'Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: 'Publishing Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: 'Published',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: 'Archived',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: 'Depublished',
    [CONSTANTS.ASSET_STATUS.DEPUBLISHED]: 'De Publish',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: 'Rights Expired',
  }

  return statusMap[status] || status || ''
}

export const getAssetStatusBackgroundColor = (status: any) => {
  const colorMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.DRAFT]: '#9F760D',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: '#5C4200',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: '#402080',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING]: '#160B2D',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_PUBLISH]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.FAILED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: '#005A30',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DEPUBLISHED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: '#D4351C',
  }

  return colorMap[status] || '#E6F7FD'
}
export const stringToHexColor = (str: string) => {
  let hashCode = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hashCode = (hashCode << 5) - hashCode + char
    hashCode &= hashCode
  }

  const colorCode = '#' + (hashCode & 0xffffff).toString(16).padStart(6, '0')

  return colorCode
}
export const getContentCategoryColor = (status: any) => {
  const colorMap: Record<string, string> = {
    ['TV_SHOW']: '#7CAB13',
    ['ORIGINALS']: '#F96E5A',
    ['MOVIES']: '#7557FB',
    ['NON_VOD_ASSETS']: '#FFB26A',
    ['SPORTS']: '#DE76C1',
    ['INDEPENDENT_VOD_ASSETS']: '#79DEFE',
  }

  return colorMap[status] || stringToHexColor(status)
}

export const getMetaCategoryName = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD Assets',
    ['NON_VOD_ASSETS']: 'Non VOD Assets',
  }

  return statusMap[status] || status || ''
}
export const getMetaCategoryNameHomepage = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD',
    ['NON_VOD_ASSETS']: 'Non VOD',
  }

  return statusMap[status] || status || ''
}
export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000

export const setToken = (token: string) => {
  const decoded = jwtDecode(token)
  const { exp = 0 } = decoded || {}
  const expireTimeInMiliSec = exp * 1000
  const authConfig: any = {
    token: token,
    expireTime: expireTimeInMiliSec,
  }
  setLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG, authConfig)
  store.dispatch(setAuthConfig(authConfig) as any)
  setRefreshTokenCallback()
}

export const setRefreshTokenCallback = () => {
  const token: { token: string; expireTime: number } = JSON.parse(
    localStorage.getItem(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG) as string,
  )
  if (token?.expireTime) {
    const expireTimeInMiliSec = new Date(token?.expireTime).setMinutes(
      new Date(token.expireTime).getMinutes() - 5,
    )
    if (new Date(expireTimeInMiliSec) < new Date()) refreshToken()
  }
}

export const refreshToken = async () => {
  const { data = {} } = await mainApiService(AUTH_MANAGEMENT.REFRESH_TOKEN())
  const { token = '' } = data
  if (token) setToken(token)
}

export const getDateInFormat = (date: string, dFormat: string) => {
  if (date === CONSTANTS.DEFAULT_TEXT_NA) return CONSTANTS.DEFAULT_TEXT
  if (date) return format(new Date(date), dFormat)
  return ''
}

export const isAtLeastOneTrue = (arr: roleModulesModal[]) => {
  for (const module of arr) {
    for (const submodule of module.submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
  }
  return false
}

export const objectToQueryString = (obj: any): any => {
  const keys = Object.keys(obj)
  const keyValuePairs = keys.map((key) => {
    if (obj[key]) {
      return (key + '=' + obj[key]) as string | number | boolean
    }
  })

  const filteredArray = keyValuePairs.filter((element) => element !== undefined)
  return filteredArray.join('&')
}

export const getPageTitle = (id: string) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  switch (id) {
    case VIEW as string:
      return VIEW
    case CREATE:
      return CREATE
    default:
      return EDIT
  }
}
export const getPageTitleManageFile = (id: string) => {
  const { VIEW, CREATE } = CONSTANTS.PAGE_TYPE
  switch (id) {
    case VIEW as string:
      return VIEW
    case CREATE:
      return CREATE
    default:
      return CREATE
  }
}

export const getSubstring = (value: string, limit: number) => {
  return value?.length > limit ? `${value?.substring(0, limit)}...` : value
}

export const getStringLimit = (value: string, limit: number): string => {
  if (value?.length > limit) {
    return value
  } else return ''
}

export const combineErrorMessage = (unwrappableRes: any) => {
  if (Array.isArray(unwrappableRes?.error?.errors)) {
    if (unwrappableRes?.error?.errors?.length > 0) {
      return unwrappableRes?.error?.errors.join(',\n')
    }
    if (unwrappableRes?.error?.data?.length > 0) {
      return unwrappableRes?.error?.data.join(',\n')
    }
  }

  return unwrappableRes?.error?.message ?? unwrappableRes?.error
}

export const isValidObject = (obj: any) => {
  if (obj) {
    return Object.keys(obj).length > 0 ? true : false
  }
  return false
}

export const getValueFromObjArr = (obArr: any[], key: string) => {
  let value = ''
  if (obArr?.length > 0 && key) {
    obArr?.forEach((obj: any) => {
      if (isValidObject(obj) && obj?.key === key) {
        value = obj?.value
      }
    })
  }
  return value ? value : ''
}

export const createAttributePayload = (attributeFormData: any, type: string) => {
  const tmpArr: any[] = []
  attributeFormData?.attributeRef?.forEach((item: any, index: number) => {
    tmpArr.push({
      _id: item?._id,
      sortOrder: index + 1,
    })
  })

  let affiliatePartner = []
  if (attributeFormData?.affiliatePartner?.length > 0) {
    affiliatePartner = attributeFormData?.affiliatePartner.map((x: any) => x.key)
  }

  let masterValue
  if (!attributeFormData?.master) masterValue = undefined
  if (attributeFormData?.master === 'customOption') masterValue = ''
  if (attributeFormData?.master && attributeFormData?.master !== 'customOption')
    masterValue = attributeFormData?.master

  const defaultPayload = {
    fieldType: attributeFormData?.fieldType ? attributeFormData?.fieldType : undefined,
    dataType: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    fieldName: attributeFormData?.fieldName ? attributeFormData?.fieldName?.trim() : undefined,
    isRequired: attributeFormData?.isRequired
      ? attributeFormData?.isRequired === 'Yes'
        ? true
        : false
      : undefined,
    isMultiple: attributeFormData?.isMultiple
      ? attributeFormData?.isMultiple === 'Yes'
        ? true
        : false
      : undefined,
    label: attributeFormData?.label ? attributeFormData?.label : undefined,
    isB2B: attributeFormData?.isB2B
      ? attributeFormData?.isB2B === 'Yes'
        ? true
        : false
      : undefined,
    defaultValue: attributeFormData?.defaultValue ? attributeFormData?.defaultValue : '',
    affiliatePartner: attributeFormData?.affiliatePartner?.length > 0 ? affiliatePartner : [],
    placeholder: attributeFormData?.placeholder ? attributeFormData?.placeholder : undefined,
    master: masterValue,
    options: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    addMore: attributeFormData?.addMore
      ? attributeFormData?.addMore === 'Yes'
        ? true
        : false
      : undefined,
    isEMA: attributeFormData?.isEMA
      ? attributeFormData?.isEMA === 'Yes'
        ? true
        : false
      : undefined,
    status: attributeFormData?.status ? attributeFormData?.status : undefined,
    isMultilingual: attributeFormData?.isMultilingual
      ? attributeFormData?.isMultilingual === 'Yes'
        ? true
        : false
      : undefined,
    esField: attributeFormData?.esField ? attributeFormData?.esField : null,
    description: attributeFormData?.description ? attributeFormData?.description : undefined,
    additionalProperties: attributeFormData?.additionalProperties
      ? JSON.parse(attributeFormData?.additionalProperties)
      : null,

    attributeRef: tmpArr,
    metaCategory: 'NULL',
    validations: {},
  }
  if (type === CONSTANTS.PAGE_TYPE.CREATE) {
    const reqData = {
      ...defaultPayload,
      options: attributeFormData?.options?.split(',') || [],
    }
    return reqData
  }
  if (type === CONSTANTS.PAGE_TYPE.EDIT) {
    let options = []
    if (Array.isArray(attributeFormData?.options)) {
      options = attributeFormData?.options?.toString()
      options = options?.split(',')
    } else {
      options = attributeFormData?.options?.split(',') || []
    }
    const reqData = {
      ...defaultPayload,
      options: options,
    }
    return reqData
  }
  return {}
}

export const removeDuplicateAttributes = (arr: any[]) => {
  const uniqueArr: any[] = []
  arr?.forEach((x: any) => {
    const index = uniqueArr.findIndex((y: any) => y?.fieldName === x?.fieldName)
    if (index < 0) {
      uniqueArr.push(x)
    }
  })
  return uniqueArr
}

export const isValidArray = (array: any[]) => {
  return typeof array === 'object' && typeof array !== 'string' && array?.length > 0 ? true : false
}

export const isValidJSON = (str: string) => {
  /*
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
  */
  try {
    const o = JSON.parse(str)
    if (o && typeof o === 'object') return true
    return false
  } catch (e) {
    return false
  }
}

// delete element from object if its null/undefined/empty
export const ValidateObject = (Obj: any) => {
  try {
    Object.keys(Obj).forEach((key) => {
      if (Obj[key] === null || Obj[key] === undefined || Obj[key] === '') {
        delete Obj[key]
      }
    })
    return Obj
  } catch (e) {
    return {}
  }
}

export const getSystemConfigurationMasterObject = (
  systemConfigurationPageType: string,
  systemConfigurationList?: any[],
) => {
  let result: any = {}
  systemConfigurationList?.forEach((sc: any) => {
    const { url = '', pageName = '', name = '' } = sc
    if (url && pageName && name) {
      const splittedURL = url?.split('/')
      const pageType = splittedURL[splittedURL?.length - 1]
      if (systemConfigurationPageType === pageType) {
        result = sc
      }
    }
  })
  return result
}

export const emptyFunction = () => {
  return
}

export const getMasterPayload = (masterKey: string, obj: any) => {
  const body = {
    key: masterKey,
    value: {
      key: obj?.key || '',
      value: obj?.value || '',
    },
  }
  return body
}

const replacer = (match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) => {
  const key = '<span class=json-key>'
  const val = '<span class=json-value>'
  const str = '<span class=json-string>'
  let r = pIndent || ''
  if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: '
  if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>'
  return r + (pEnd || '')
}

export const prettyPrint = (obj: any) => {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, '&amp;')
    .replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(jsonLine, replacer)
}

export const updatingSortOrderValue = (array: any[]) => {
  const copyArr = JSON.parse(JSON.stringify(array))
  copyArr?.forEach((item: any, index: number) => {
    item.sortOrder = index
  })
  return copyArr
}

export const uploadInPresignedURL = async (
  presignedUrl: any,
  file: any,
  dispatch: any,
  uploadFileName: any,
) => {
  try {
    if (!presignedUrl) return ''
    if (!file) {
      dispatch(showAlert([true, 'Please select a file', 'error']))
      return
    }
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    })
    if (response.ok) {
      return uploadFileName
    } else {
      dispatch(showAlert([true, 'Upload Failed', 'error']))
      return ''
    }
  } catch (error) {
    dispatch(showAlert([true, 'Upload Failed', 'error']))
    return ''
  }
}

export const uploadSubtitleToS3 = async (
  dispatch: any,
  uploadFileName: string,
  file: any,
  contentId: string,
) => {
  try {
    let reqUrl: any = ''
    const result: any = dispatch(
      generatePresignedUrlForSubtitle(`key=${uploadFileName}&contentId=${contentId}`) as any,
    )
    const unwrapRes = unwrapResult(await result)
    if (unwrapRes?.error) {
      dispatch(showAlert([true, combineErrorMessage(unwrapRes), 'error']))
      return reqUrl
    }
    const resUrl = unwrapRes?.data?.url || ''
    const fileName = await uploadInPresignedURL(resUrl, file, dispatch, uploadFileName)
    reqUrl = fileName ? `${CONSTANTS.S3_SUBTITLE_BUCKET_BASE_URL}/${fileName}` : ''
    return reqUrl
  } catch (error: any) {
    console.log('error', error)
    return ''
  }
}

export const downloadFile = async (dispatch: any, path: string, markAsDoneDetail: any) => {
  try {
    let id = markAsDoneDetail?.legacyHouseId
    if (!id) {
      id = markAsDoneDetail?.blitzId
    }
    const url = `${CONSTANTS.CLOUDFRONT_BASE_URL_SUBTITLE}/subtitles/${id}/${path}`
    const res: any = await fetch(url)
    const blob: any = await res.blob()

    if (blob) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${path}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}
export const downloadBulkUpdateDataFunction = async (data: any, dispatch: any) => {
  try {
    const requestedData = BULK_UPDATE_APIS.DOWNLOAD_JOB_CSV(data._id)
    const result = await mainApiService(requestedData)
    if (result.responseCode === 200) {
      const url = result.data
      const res: any = await fetch(url)
      const blob: any = await res.blob()
      if (blob) {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `BulkUpdateReport_${data.jobName}.csv`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      dispatch(showAlert([true, 'Report downloaded successfully', 'success']))
    }
    if (result?.error?.responseCode === 422 && result?.error?.message) {
      dispatch(showAlert([true, `${result?.error?.message}`, 'error']))
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}
export const checkModuleAccess = (role: string | undefined) => {
  let result = true
  if (role) {
    const roleData: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA)
    for (let i = 0; i < roleData?.length; i++) {
      for (let j = 0; j < roleData[i]?.submodules?.length; j++) {
        if (roleData[i]?.submodules[j]?.key === role) {
          result = roleData[i]?.submodules[j]?.permission?.canAccess
          break
        }
      }
    }
  }
  return result
}
export const getCapitalise = (value: string) => {
  return value?.charAt(0)?.toUpperCase() + value?.replace('_', ' ')?.slice(1)?.toLowerCase()
}

export const fetchOptionsValue = (options: any, value: string) => {
  if (options && options.length) {
    const displayObj = options.find((item: any) => item.key === value)
    return displayObj?.value ?? ''
  }
  return ''
}

export const getValueBasedOnString = (
  value: any,
  fieldType: string,
  tagInput: boolean,
  dataType: string,
  element: {
    attribute?: {
      dataType?: string
      options?: {
        value: any
        label: string
      }[]
    }
  },
): string => {
  if (fieldType !== CONSTANTS.FIELDTYPES.FILE) {
    if ((typeof value !== 'boolean' && !value) || (Array.isArray(value) && value.length === 0)) {
      return 'NULL'
    }
    if (
      tagInput ||
      (fieldType === CONSTANTS.FIELDTYPES.SELECT &&
        dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN)
    ) {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value
      }
    } else if (fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      if (Array.isArray(value)) {
        return value
          .map((item: any) => fetchOptionsValue(element?.attribute?.options, item))
          .join(', ')
      } else {
        return fetchOptionsValue(element?.attribute?.options, value)
      }
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.DATE ||
      fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
      fieldType === CONSTANTS.FIELDTYPES.TIME
    ) {
      if (dataType === CONSTANTS.FIELDTYPES.NUMBER) {
        return secondsToHMS(value)
      }
      return getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME)
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.CHECKBOX ||
      fieldType === CONSTANTS.FIELDTYPES.RADIO ||
      fieldType === CONSTANTS.FIELDTYPES.SWITCH
    ) {
      return value ? 'Yes' : 'No'
    } else {
      return value
    }
  }
  return ''
}

export const subStringWithEllipses = (value: string, count = 50, elipses = true): string => {
  if (value.length <= count) {
    return value
  }
  const truncatedString = value.substring(0, count)
  return elipses ? `${truncatedString}...` : truncatedString
}
// Utilized it if you need temporary unique id
export const uniqueId = () => {
  return Math.random() + getTime(new Date())
}

export const replaceUnwanted = (data: string) => {
  return data?.replace(/[^A-Z0-9]+/gi, '_')?.toLowerCase()
}
export const filterObject = <T extends Record<string, string | number>>(obj: T): T => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (
      obj[key] !== 0 &&
      obj[key] !== '' &&
      key !== 'filterCondition' &&
      key !== 'filterValue' &&
      key !== 'filterBy'
    ) {
      result[key] = obj[key]
    }
  }

  return result as T
}

export const changeToCapitalCase = (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

/**
 * Function to return parsed data of JWT token in the form of object.
 * @function getParsedToken
 * @returns {object} JWT parsed object which is parsed from token stored in localstorage
 */
export const getParsedToken = () => {
  const authConfig: { token: string } = getLocalStorage('auth_config')
  if (authConfig?.token) {
    const parsedToken = jwtDecode(authConfig.token)
    return parsedToken
  }
}

export const logoutCyberArk = (message = '') => {
  if (message) {
    setLocalStorage(CONSTANTS.LOCAL_KEYS.LOGOUT_MESSAGE, { message })
  }
  const authToken: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG)
  if (authToken && authToken.token) {
    const parsedToken: { sub: { accessToken: string } } = jwtDecode(authToken.token)
    const origin = process.env.REACT_APP_URL
    if (parsedToken?.sub?.accessToken) {
      const redirectUrl = `${CONSTANTS.SESSION_END_URL}?id_token_hint=${parsedToken?.sub
        ?.accessToken}&post_logout_redirect_uri=${`${origin}/login`}`
      window.location.href = redirectUrl
    }
  } else {
    window.location.href = PAGES_ROUTES.LoginPage
  }
}

export const logout = async () => {
  const { responseCode, message, error = {} } = await mainApiService(AUTH_MANAGEMENT.LOGOUT())
  if (responseCode === 200) {
    logoutCyberArk(message)
    return
  }
  if (error) store.dispatch(showAlert([true, error?.message, 'error']))
}
export const getSelectedAttributeDetails = (selectedAttri: any, attributes: any) => {
  const matchingObjects = []

  for (const selectedObj of selectedAttri) {
    const matchingDataObject = attributes.find((ele: any) => ele._id === selectedObj._id)

    if (matchingDataObject) {
      matchingObjects.push(matchingDataObject)
    }
  }

  return matchingObjects
}

export const AVS_MESSAGES: any = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  AVS_INGESTION: 'AVS Ingestion',
  AVS_PUBLISH: 'AVS Publishing',
  AVS_DEPUBLISH: 'AVS De-Publish',
}

export const getColor = (status: string) => {
  return status === 'SUCCESS' ? '#4C6A0B' : status === 'FAILED' ? '#D4351C' : ''
}

export const getBGColor = (status: string) => {
  return status === 'SUCCESS' ? '#F4F9EA' : status === 'FAILED' ? '#FFDBD5' : ''
}

export const getStatusMessage = (avsStatus: string, avsAction: string) => {
  const statusMessage = AVS_MESSAGES[avsStatus] || ''
  const actionMessage = AVS_MESSAGES[avsAction] || ''
  return actionMessage && statusMessage ? `${actionMessage}: ${statusMessage}` : ''
}

export const validValueOrEmptyString = (value: any) => {
  if (!value) {
    return ''
  }
  return value
}

/**
 * To get the uniqe array obj removes deuplicate value based on keys
 * @function removeDuplicates
 * @param arr Array of object from which duplicate value need to be removed
 * @param key the key of by which we need to remove duplicate data from array
 * @returns {array} returns unique array of object based on key.
 */
export function removeDuplicates(arr: TypeObject[], key: string) {
  const seenAssetTypes = new Set()
  const uniqueObjects: TypeObject[] = []

  arr?.forEach((obj: TypeObject) => {
    const keyObj = obj[key]
    if (!seenAssetTypes.has(keyObj)) {
      seenAssetTypes.add(keyObj)
      uniqueObjects.push(obj)
    }
  })

  return uniqueObjects
}
export const nullValuesPresent = (data: any) => {
  return Object?.values(data)?.every((value) => value === null)
}
export const getInheritanceRuleName = (inheritanceRule: string, inheritanceData: any) => {
  return (
    (Array.isArray(inheritanceData) ? inheritanceData : [])?.find(
      (item: any) => item?.key === inheritanceRule,
    )?.value || 'NULL'
  )
}
export const getAttrDefValidationName = (validationNameKey: string, validationsList: any) => {
  return (
    (Array.isArray(validationsList) ? validationsList : [])?.find(
      (item: any) => item?.functionName === validationNameKey,
    )?.displayName || 'NULL'
  )
}
export const getAttrDefDataSourceName = (dataSourceKey: string, dataSourceList: any) => {
  return (
    (Array.isArray(dataSourceList) ? dataSourceList : [])?.find(
      (item: any) => item?.key === dataSourceKey,
    )?.value || 'NULL'
  )
}
export const getAttrDefScopeName = (scopeValueKey: string, scopesList: any) => {
  return (
    (Array.isArray(scopesList) ? scopesList : [])?.find((item: any) => item?.key === scopeValueKey)
      ?.value || 'NULL'
  )
}
export const getAttrDefSourceAttributeName = (sourceAttributeKey: string, attributesList: any) => {
  return (
    (Array.isArray(attributesList) ? attributesList : [])?.find(
      (item: any) => item?.fieldName === sourceAttributeKey,
    )?.label || 'NULL'
  )
}
export const staticCheckToHideAsterisk = (element: any) => {
  return element?.fieldName === 'class' && element?.fieldType === 'SELECT'
}
export const getfilterby = (tab: any, assetCategory: any, contentCategoryOrder: any) => {
  if (assetCategory !== 'CONTENT_ITEM') {
    if (contentCategoryOrder.indexOf(tab) < contentCategoryOrder.indexOf(assetCategory)) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
export const groupAndSortBasedOnBlitzId = (data: Record<string, any>[]) => {
  // Step 1: Group by blitzId
  const grouped = data.reduce((acc: any, item: any) => {
    if (!acc[item.blitzId]) {
      acc[item.blitzId] = []
    }
    acc[item.blitzId].push(item)
    return acc
  }, {})

  // Define the order of statuses
  const statusOrder = ['DRAFT', 'PUBLISHED', 'SCHEDULE PUBLISHED']

  // Function to get the order index
  const getStatusOrderIndex = (status: string) => {
    const index = statusOrder.indexOf(status)
    return index === -1 ? statusOrder.length : index
  }

  // Step 2: Sort each group by status order
  for (const blitzId in grouped) {
    grouped[blitzId].sort((a: any, b: any) => {
      return getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status)
    })
  }

  // Convert the grouped object back to an array of grouped arrays
  const groupedArray = Object.entries(grouped).map(([blitzId, items]) => ({
    blitzId: Number(blitzId),
    items: items,
  }))

  return groupedArray
}

export const normalizeCuepoints = (jsonObj: any) => {
  for (const cuepoint of jsonObj?.cuePointList || []) {
    cuepoint.cuepointStartTime = parseInt(cuepoint.cuepointStartTime)
    cuepoint.cuepointEndTime = parseInt(cuepoint.cuepointEndTime)
  }
  return jsonObj
}

export const getDataBasedOnkey = (arr: Record<string, any>[], key: string, fetchValue: any) => {
  const data = new Map(arr?.map((item: Record<string, any>) => [item.key, item]))
  const dataObj = data.get(fetchValue)
  return dataObj?.value ?? ''
}

export const validImageFormat = (file: File) => {
  if (file) {
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
    return acceptedFormats.includes(file.type)
  } else {
    return false
  }
}

export const getValueByKey = (keyName: string, arrayOfObjects: any[]) => {
  const keyValueMap = new Map(arrayOfObjects.map((obj: any) => [obj?.key, obj?.value]))
  return keyValueMap.get(keyName)
}

/**
 * Deep comparison of two values.
 * @param {any} val1 - First value to compare.
 * @param {any} val2 - Second value to compare.
 * @returns {boolean} - True if values are deeply equal, false otherwise.
 */
export const deepCompare = (val1: any, val2: any) => {
  // Check if both values are the same object or primitive value
  if (val1 === val2) {
    return true
  }

  // Check if either of the values is null or not an object/array
  if (val1 === null || val2 === null || typeof val1 !== 'object' || typeof val2 !== 'object') {
    return false
  }

  // Handle arrays
  if (Array.isArray(val1) && Array.isArray(val2)) {
    if (val1.length !== val2.length) {
      return false
    }

    for (let i = 0; i < val1.length; i++) {
      if (!deepCompare(val1[i], val2[i])) {
        return false
      }
    }

    return true
  }

  // Handle objects
  if (
    Object.prototype.toString.call(val1) === '[object Object]' &&
    Object.prototype.toString.call(val2) === '[object Object]'
  ) {
    const keys1 = Object.keys(val1)
    const keys2 = Object.keys(val2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepCompare(val1[key], val2[key])) {
        return false
      }
    }

    return true
  }

  return false
}

export const getDetailedErrorMessage = (jsonString: string, error: { message: string }) => {
  const lines = jsonString.split('\n')
  const positionMatch: any = error.message.match(/position (\d+)/)

  if (positionMatch) {
    const position = parseInt(positionMatch[1], 10)
    let lineNumber = 0
    let charCount = 0
    const lineLengthArr = []
    // let count = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i].length + 1
      lineLengthArr.push(lines[i].length)
      // count += lines[i].length
      const charValue = charCount + lineLength
      if (charValue > position) {
        lineNumber = i + 1
        break
      }

      charCount += lineLength
    }

    const nearbyCode = jsonString.substring(position - 10, position + 10)
    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('}') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${lineNumber - 1}: Misplaced comma near line ${
        lineNumber - 1
      } in JSON at position ${charCount - 2} (line ${lineNumber - 1} column ${
        lines[lineNumber - 2].length
      })`
    }

    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('{') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${
        lineNumber - 1
      }: Misplaced comma near line ${lineNumber} in JSON at position ${
        positionMatch[1]
      } (line ${lineNumber} column ${lines[lineNumber - 1].length})`
    }

    return `Parse error on line ${lineNumber}: ${error.message}`
  } else if (!error.message.includes('position') && error.message.includes('Unexpected token')) {
    const undefinedPosition: any = []
    const errorIndex: any = []
    let positionCount = 0
    const lineBreakPosition = []
    let errorLineNumber = 0
    let errorLinerPosition = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i]?.length + 1
      const elements = lines[i]?.split(' ')
      positionCount = positionCount + lines[i].length
      lineBreakPosition?.push(lineLength)
      for (const ele of elements) {
        const trimmedElement = `'${ele.trim()}'`
        if (trimmedElement === error.message?.split('Unexpected token')[1]?.split(',')[0]?.trim()) {
          errorIndex.push(lines[i].indexOf(ele.charAt(0)))
          undefinedPosition?.push(ele)
          errorLineNumber = lineBreakPosition.length - 1
          errorLinerPosition = lineBreakPosition[lineBreakPosition.length - 1]
          break
        }
      }
    }
    return `${error.message} at position ${positionCount} (line ${errorLineNumber} column ${errorLinerPosition})`
  }
  return error.message
}

export const getRouteFromLocalStorage = (returnSingle = false, contentId = '') => {
  const paths: any = localStorage.getItem('pathHistory')
  if (paths) {
    const parsePaths = JSON.parse(paths)
    if (returnSingle) {
      const lastPathFromArray = contentId
        ? parsePaths.find((item: string) => item.includes(contentId))
        : parsePaths[parsePaths.length - 1]

      parsePaths.pop()
      localStorage.setItem('pathHistory', JSON.stringify(parsePaths))
      return lastPathFromArray
    }
    if (parsePaths?.length) {
      return parsePaths
    }
  }

  return returnSingle ? false : []
}

export const setRouteToLocalStorage = (path: string) => {
  let paths = getRouteFromLocalStorage(false)
  const pathIdx = paths.findIndex((item: string) => path === item)
  if (pathIdx !== -1) {
    paths.splice(pathIdx + 1, paths.length - 1)
    localStorage.setItem('pathHistory', JSON.stringify(paths))
    return
  }
  paths = [...paths, path]
  localStorage.setItem('pathHistory', JSON.stringify(paths))
}
export const removeFilterKey = (currentTab?: string) => {
  return currentTab === 'Parent' ? 'blitzId' : currentTab === 'Child' ? 'parentId' : ''
}

export const cascadeKeyValueForLabel = (records: []) => {
  if (records?.length) {
    const result = records?.map((item: any) => {
      return {
        _id: item?._id || '',
        key: item?.key || '',
        value: `${item?.key} ${item?.value}` || '',
      }
    })
    return result
  }
  return []
}

export const saveTransactionIDInStorage = (contentId: string, metaCategory: string): string => {
  const timestamp = Date.now()
  const codedContentId = contentId.slice(contentId.length - 5)
  const metaCategoryInitial = metaCategory
    ?.split('_')
    ?.map((item) => item?.slice(0, 1)?.toLowerCase())
    ?.join('')

  const transactionId = `${metaCategoryInitial}-${codedContentId}-${timestamp}`
  localStorage.setItem(CONSTANTS.LOCAL_KEYS.TRANSACTION_ID, transactionId)

  return transactionId
}

export const getStatusText = (status: any) => {
  return status === 'Active' ? 'ACTIVE' : status === 'In Active' ? 'INACTIVE' : status
}

export const getValueObjbyKeyfromArr = (keyName: string, keyValue: string, array: any[]) => {
  const valueObj = array?.find((element: any) => element[keyName] === keyValue)
  return valueObj || {}
}

export const findScrollableParent = (element: HTMLElement | null): HTMLElement | Window => {
  if (!element) return window

  const isScrollable = (ele: HTMLElement) => {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight
    const overflowYStyle = window.getComputedStyle(ele).overflowY
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

    return hasScrollableContent && !isOverflowHidden
  }

  if (isScrollable(element)) {
    return element
  }

  return findScrollableParent(element.parentElement) || window
}

export const checkErrorData = (ev: any) => {
  const regex = /[eE+-.]/
  if (regex.test(ev)) {
    return true
  } else {
    return false
  }
}
export const isPastable = (fieldType: any, ev: any) => {
  return fieldType === 'NUMBER' && checkErrorData(ev.clipboardData.getData('text'))
}
export const isAllowKeydown = (fieldType: any, ev: any) => {
  return fieldType === 'NUMBER' && PARTNER_CONSTENT.AVOID_KEY_FOR_PHONE?.includes(ev.key)
}
export const getStickyIndex = (sticky?: 'R' | 'L', headers?: any[]) => {
  return sticky === 'L' ? 0 : sticky === 'R' ? headers?.length && headers?.length - 1 : ''
}

export const convertBooleanIntoYesAndNo = (value: boolean | undefined): string =>
  value ? 'Yes' : 'No'

export const getStatusBoxClass = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return 'avs-success-color'
    case 'FAILED':
      return 'avs-failed-color'
    default:
      return ''
  }
}

export const getStatusLabelClass = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return 'avs-success-label-color'
    case 'FAILED':
      return 'avs-failed-label-color'
    default:
      return ''
  }
}

export const truncatedValueByLength = (value: string | null | undefined, count = 40): string => {
  if (!value) return ''

  const shouldTruncate = value.length >= count
  const truncatedValue = value.slice(0, count)

  return `${truncatedValue}${shouldTruncate ? '...' : ''}`
}

export const sortByLocaleCompare = (
  array: { [key: string]: any }[] | null | undefined,
  key: string,
) => {
  if (!Array.isArray(array)) return []

  return array.sort((a, b) => {
    const aValue = a?.[key]
    const bValue = b?.[key]

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue)
    } else if (aValue === null || aValue === undefined) {
      return 1 // Null/undefined values should appear last
    } else if (bValue === null || bValue === undefined) {
      return -1 // Null/undefined values should appear last
    } else if (typeof aValue === 'string') {
      return -1 // Strings should come before non-strings
    } else if (typeof bValue === 'string') {
      return 1 // Strings should come before non-strings
    } else {
      return 0 // Non-string, non-null values are considered equal
    }
  })
}

export const stringifyIfNotNull = (data: any): string => {
  const jsonString = JSON.stringify(data)
  return jsonString === 'null' ? '' : jsonString
}

export const getStatusType = (attribute: any): string => {
  const status = attribute?.plainStatus ?? attribute?.status
  return status === 'ACTIVE' ? 'success' : 'secondary'
}

export const getStatusLabelOrValue = (attribute: any): string => {
  const status = attribute?.plainStatus ?? attribute?.status
  return status === 'ACTIVE' ? 'Active' : CONSTANTS.InActiveText
}

export const deepClone = <T>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as any as T
  }
  if (Array.isArray(obj)) {
    const arrCopy = [] as any[]
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepClone(obj[i])
    }
    return arrCopy as any as T
  }

  const objCopy = {} as { [key: string]: any }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (
        typeof obj[key] === 'string' &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(obj[key] as string)
      ) {
        objCopy[key] = new Date(obj[key] as string) // Convert string to Date
      } else {
        objCopy[key] = deepClone(obj[key])
      }
    }
  }
  return objCopy as T
}

export const scrollMetaCategoriesInAssetCreation = () => {
  const element = document.getElementById('menu-metadata-categories')
  if (element) {
    const positionFromTop = element.getBoundingClientRect().top
    if (positionFromTop < 67) {
      let scrollHeight = 180
      if (document.getElementById('create-asset-header-details')) {
        scrollHeight = 220
      }
      const scrollableParent = findScrollableParent(element)
      if (scrollableParent) {
        scrollableParent.scroll({ top: scrollHeight, behavior: 'smooth' })
      }
    }
  }
}
export const getsortedHeader = (headers: any) => {
  const headersCopy = JSON.parse(JSON.stringify(headers))
  const seletedColTemp: any = []
  const unSelectedColTemp: any = []
  headersCopy.forEach((ele: any) => {
    if (ele.viewInListing) {
      seletedColTemp.push(ele)
    } else {
      unSelectedColTemp.push(ele)
    }
  })
  return [
    ...seletedColTemp,
    ...unSelectedColTemp.sort((first: any, second: any) => first.label.localeCompare(second.label)),
  ]
}
export const getCancelJobHeading = (status: string) => {
  const statusMessages: Record<string, string> = {
    IN_PROGRESS: 'Cancel Job?',
    IN_QUEUE: 'Cancel In Queued Job?',
    SCHEDULED: 'Cancel Scheduled Job?',
  }

  return statusMessages[status] || ''
}
export const updateAudioLanguage = (assetGroupDetails: any, language: any, atIndex: any) => {
  const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
  copyArr.forEach((item: any, index: number) => {
    if (index === atIndex) {
      item.language = language
    }
  })
  return copyArr || []
}

/**
 * getPublishingStatus to determine the publishing status.
 *
 * @param {string | undefined} id - The identifier to compare.
 * @param {object} asset - The asset object which may contain the isStandAlonePublishing property.
 * @returns {string} - Returns 'TRUE' or 'FALSE' based on the conditions.
 */
export const getPublishingStatus = (id: string | undefined, asset: any): string => {
  const { CREATE } = CONSTANTS.PAGE_TYPE
  if (id !== CREATE) {
    return asset?.isStandAlonePublishing ? 'TRUE' : 'FALSE'
  }
  return 'FALSE'
}

export const getPackageMasterValue = (item: any, value: any) => {
  const packageMasterValue = item?.filter((ele: any) => ele?.key === value) || []
  return packageMasterValue[0]?.value || null
}
export const getMultiSelectValue = (value: any, selected: boolean, isMultiple?: boolean) => {
  if (isMultiple) {
    return value.split(',').length > CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS && selected
      ? `${value?.split(',')?.slice(0, CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS)?.join(', ')}...`
      : value
  } else {
    return value
  }
}

export const uniqueSelectedColumn = (getSelectedColumn: any) => {
  const uniqueSelectedColumn = getSelectedColumn?.reduce((acc: any[], current: any) => {
    const matchingObj = acc?.find((item) => item.id === current.id)
    if (!matchingObj) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
  return uniqueSelectedColumn
}

export const getOrderColumn = (data: any, order: any) => {
  if ([undefined, null].includes(data[0])) {
    return []
  } else {
    const correctData = data.map((item: any) => {
      if (order?.includes(item.id)) {
        return { ...item, viewInListing: true }
      } else {
        return { ...item, viewInListing: false }
      }
    })
    const sortedData = order?.length ? [...correctData] : [...data]
    const imageItem = sortedData?.filter((item: any) => item?.id === 'image')
    const restOfItems = sortedData?.filter((item: any) => item?.id !== 'image')
    const getSelectedColumn = restOfItems?.map((item: any) =>
      order?.includes(item.id) && !item.viewInListing ? { ...item, viewInListing: true } : item,
    )
    const getselectedColumnCopy = order?.length ? getSelectedColumn : restOfItems
    const sortedColumnData = getselectedColumnCopy
      ?.filter((item: any) => item?.viewInListing)
      .sort((a: any, b: any) => {
        const indexA = order.indexOf(a.id)
        const indexB = order.indexOf(b.id)
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB
        }
        if (indexA !== -1) {
          return -1
        }
        if (indexB !== -1) {
          return 1
        }
        return a.position - b.position
      })
    const unSelectedColumn = restOfItems?.filter((item: any) => !item.viewInListing)
    unSelectedColumn.sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
    let allColumns = []
    if (imageItem.length) {
      allColumns = [imageItem[0], ...sortedColumnData, ...unSelectedColumn]
    } else {
      allColumns = [...sortedColumnData, ...unSelectedColumn]
    }
    const finalColHeader = uniqueSelectedColumn(allColumns)
    return finalColHeader
  }
}

export const getRearrangeData = (ev: any, headersData: any) => {
  const { source, destination } = ev
  if (!destination) return
  const sourceIndex = source.index
  const destinationIndex = destination.index
  let copyData = JSON.parse(JSON.stringify(headersData))
  if (isValidArray(headersData)) {
    const [element] = copyData.splice(sourceIndex, 1)
    copyData.splice(destinationIndex, 0, element)
    copyData = updatingSortOrderValue(copyData)
    return copyData
  }
}

export const checkOptionSelected = (
  multiple: boolean,
  options: any,
  value: any,
  option: any,
  labelKey: string,
  selected: boolean,
  isPlatformIndependentSelect = false,
) => {
  if (
    multiple &&
    option?.[labelKey] === 'Select All' &&
    (options?.length === value?.length ||
      (isPlatformIndependentSelect && options?.length === value?.length + 1))
  ) {
    return true
  }
  return selected
}

export const getPartnerViewDeatils = (partnerData: any, listOptions: any) => {
  const partnerFields = JSON.parse(JSON.stringify(PARTNER_CONSTENT.initialField)) as Array<any>
  const partnerComplexFields = partnerFields.find(
    (item: any) => item.fieldName === 'allowedAssetTypeConfigurations',
  )
  const allowedAssetTypeConfigurationsColumns: any = []
  const allowedAssetTypeConfigurationsRows: any = []
  if (partnerComplexFields?.fieldConfig) {
    partnerComplexFields.fieldConfig.forEach((subAttr: any) => {
      allowedAssetTypeConfigurationsColumns.push({
        id: subAttr.fieldName,
        label: subAttr.label,
        sort: false,
        fieldType: subAttr.fieldType,
        isMultiple: subAttr?.isMultiple,
      })
    })

    if (partnerData?.allowedAssetTypeConfigurations?.length) {
      partnerData.allowedAssetTypeConfigurations.forEach((values: any) => {
        const row: any = {}
        partnerComplexFields.fieldConfig.forEach((subAttr: any) => {
          row[subAttr.fieldName] = {
            ...subAttr,
            id: subAttr.fieldName,
            attribute: {
              ...subAttr,
              value: values[subAttr.fieldName] ?? '',
              options: listOptions[subAttr.formFieldsOptionsKey] || [],
            },
          }
        })
        allowedAssetTypeConfigurationsRows.push(row)
      })
    }
  }
  return [
    { label: 'Partner Name', value: partnerData?.serviceName, isTitle: true },
    { label: 'Partner Alias', value: partnerData?.serviceAlias, isTitle: false },
    { label: 'Email ID', value: partnerData?.contactEmail, isTitle: false },
    { label: 'Contact Number', value: partnerData?.contactPhone, isTitle: false },
    { label: 'Status', value: partnerData?.status, isTitle: false },
    {
      label: 'Contract Start Date',
      value: partnerData.contractStartDate
        ? format(new Date(partnerData.contractStartDate), CONSTANTS.DATE_FORMAT_TIME)
        : 'NULL',
      isTitle: false,
    },
    {
      label: 'Contract End Date',
      value: partnerData.contractEndDate
        ? format(new Date(partnerData.contractEndDate), CONSTANTS.DATE_FORMAT_TIME)
        : 'NULL',
      isTitle: false,
    },
    {
      label: 'Allowed Masters',
      value: partnerData?.allowedMasters?.length
        ? partnerData?.allowedMasters.map((item: any, ind: number) =>
            partnerData?.allowedMasters?.length - 1 === ind ? item : `${item}, `,
          )
        : 'NULL',
    },
    { label: 'Prefix Value', value: partnerData?.partnerPrefix, isTitle: false },
    { label: 'Catalog Provider Folder', value: partnerData?.catalogProviderFolder, isTitle: false },
    { label: 'Queue Size', value: partnerData?.queueSize, isTitle: false },
    {
      viewType: 'complex',
      label: 'Allowed Asset Type',
      columns: allowedAssetTypeConfigurationsColumns,
      tableRows: allowedAssetTypeConfigurationsRows,
    },
  ]
}

export const getObjFromObjArr = (keyName: string, keyValue: string, objArr: any[]) => {
  if (objArr?.length > 0) {
    return objArr?.find((item: any) => item[keyName] === keyValue)
  }
  return {}
}

export const checkMetaCategoryPermission = (permissions: any) => {
  const metaCategories =
    Array.isArray(permissions) && permissions?.find((item: any) => item?.key === 'META_CATEGORIES')
  const checkReadAccessForMetaCategory = metaCategories?.submodules?.some(
    (submodule: any) => submodule?.permission.canRead === true,
  )
  return checkReadAccessForMetaCategory
}

export const mapLanguagesFromAssetGroup = (data: any, languageMaster: any) => {
  const uniqueLanguages =
    (data?.audio &&
      data?.audio?.reduce((acc: any, item: any) => {
        if (Array.isArray(item.language)) {
          item.language.forEach((lang: any) => acc.add(lang))
        } else {
          acc.add(item.language)
        }
        return acc
      }, new Set())) ||
    []
  return [...uniqueLanguages]
    .map((langKey) => {
      return languageMaster.find((lang: any) => lang.key === langKey?.toUpperCase())
    })
    .filter(Boolean)
}

export const getContentCategoryForContentItem = (
  contentCategory: any,
  assetGroupLang: any,
  languageList: any,
) => {
  const updatedcontentCategory = contentCategory?.metaAttributes?.map((item: any) => {
    if (item?.fieldName === 'defaultLang') {
      const selectedDefaultLang = languageList?.find(
        (lang: any) => lang?.key === item?.attribute?.value,
      )
      const assetGroupSelectedLang = mapLanguagesFromAssetGroup(assetGroupLang, languageList)
      const checkDefaultExistInAssetGroup =
        assetGroupSelectedLang &&
        assetGroupSelectedLang?.find((lang: any) => lang?.key === item?.attribute?.value)
      const finalLanguageList =
        checkDefaultExistInAssetGroup || !selectedDefaultLang
          ? assetGroupSelectedLang
          : [...assetGroupSelectedLang, selectedDefaultLang]
      return { ...item, attribute: { ...item.attribute, options: finalLanguageList } }
    } else {
      return item
    }
  })
  return { ...contentCategory, metaAttributes: updatedcontentCategory }
}

export const notNullUndefined = (value: any) => {
  return value !== null && value !== undefined ? true : false
}

export const formatCellValue = (columnId?: any, value?: any): string => {
  if (columnId === 'platform' && value) {
    return Array.isArray(value) ? value.flat().join(', ') : value
  }
  return value || ''
}

export const validateFile = (
  files: any,
  allowedExtensions: any[],
  fileConfig: any,
  setDialogProps: any,
  dispatch: any,
  dropzoneFileList: any[],
  cancelCallBack: any,
) => {
  let largeFile = true
  let extraFile = true
  let invalidExtension = true
  let noSpace = true
  let noSpecialChar = true
  const fileLength = dropzoneFileList?.length + files?.length
  const alphaNumaricWithUnderscore = /^[a-zA-Z0-9_-]+$/
  files.forEach((file: any) => {
    const fileNameSplited = file?.name?.split('.')
    const extension = fileNameSplited[fileNameSplited?.length - 1]
    const fileName = file?.name?.substring(0, file?.name.lastIndexOf('.'))
    const isValidRegex = alphaNumaricWithUnderscore.test(fileName)
    if (file.size / 1024 > fileConfig?.maxFileSize) {
      largeFile = false
      setDialogProps({
        heading: CONSTANTS.UPLOAD_FAILED_HEADING,
        description: `${CONSTANTS?.UPLOAD_FAILED_MANAGE_FILE_SIZE_DESC} ${
          fileConfig?.maxFileSize / 1024
        }MB.`,
        color: 'error',
        Icon: 'InactiveIcon',
        open: true,
        iconColor: 'error',
        successButtonLabel: 'Okay',
        handleSuccess: cancelCallBack,
      })
      return false
    } else if (fileLength > fileConfig?.maxFiles) {
      extraFile = false
      setDialogProps({
        heading: CONSTANTS.UPLOAD_FAILED_HEADING,
        description: `${CONSTANTS?.UPLOAD_FAILED_MANAGE_FILE_COUNT_DESC} ${fileConfig?.maxFiles}.`,
        color: 'error',
        Icon: 'InactiveIcon',
        open: true,
        iconColor: 'error',
        successButtonLabel: 'Okay',
        handleSuccess: cancelCallBack,
      })
      return false
    } else if (!allowedExtensions?.includes(extension)) {
      invalidExtension = false
      dispatch(showAlert([true, 'Invalid file type', 'error']))
      return false
    } else if (file.name.includes(' ')) {
      noSpace = false
      dispatch(showAlert([true, 'File name cannot have a space in it ', 'error']))
    } else if (!isValidRegex) {
      noSpecialChar = false
      dispatch(
        showAlert([true, 'Only alphanumeric characters and underscores are allowed.', 'error']),
      )
    }
  })
  return largeFile && extraFile && invalidExtension && noSpace && noSpecialChar
}

export const downloadDocument = (fileName: string, fileURL: string, dispatch: any) => {
  fetch(fileURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.blob()
    })
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      dispatch(showAlert([true, `'Error downloading image:' ${error}`, 'error']))
    })
}
export const checkAvailabilityDefference = (categoryContent: any) => {
  const startDate = categoryContent.metaAttributes.find(
    (attr: any) => attr.fieldName === 'contractStartDate',
  )?.attribute?.value
  const endDate = categoryContent.metaAttributes.find(
    (attr: any) => attr.fieldName === 'contractEndDate',
  )?.attribute?.value

  if (startDate && endDate) {
    const differenceInDays =
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60)
    return differenceInDays
  } else {
    return false
  }
}
export const getImageUrl = (value: any) => {
  return CONSTANTS.CLOUDFRONT_BASE_URL + '/' + value[0].imageKey
}

/**
 * To prepare starts in and expires in value for attribute filter array for payload
 * @function handleDateFilterForStartsInAndExpiresIn
 * @param {string} dateType - Name of the field in formData eg. startsIn / endsIn
 * @param {string} filterBy - name of fieldName in which this condition applu eg. contractStartDate/contractStartDate
 * @param {any} formData - form data object
 * @param {any} payload - payload object
 * @returns {void}
 */
export const handleDateFilterForStartsInAndExpiresIn = (
  dateType: string,
  filterBy: string,
  formData: any,
  payload: any,
) => {
  if (formData[dateType]?.filter && formData[dateType]?.value) {
    const currentDate = new Date()
    const futureDate =
      formData[dateType]?.filter === CONSTANTS.DAY_WEEK_FILTER[0].value
        ? addDays(currentDate, formData[dateType]?.value)
        : addWeeks(currentDate, formData[dateType]?.value)
    const endOfDate = endOfDay(futureDate)
    payload.attributeFilters.push({
      filterBy,
      filterCondition: 'greaterThanEquals',
      filterValue: currentDate.toISOString(),
    })
    payload.attributeFilters.push({
      filterBy,
      filterCondition: 'lessThanEquals',
      filterValue: isNaN(endOfDate.getTime()) ? null : endOfDate.toISOString(),
    })
  }
}

/**
 * To prepare starts in and expires in value for attribute filter for payload
 * @function handleDayFilterForStartsInAndExpiresIn
 * @param {string} dateType - Name of the field in formData eg. startsIn / endsIn
 * @param {any} formData - form data object
 * @param {any} payload - payload object
 * @returns {void}
 */
export const handleDayFilterForStartsInAndExpiresIn = (
  dateType: string,
  formData: any,
  payload: any,
) => {
  if (formData[dateType]?.filter && formData[dateType]?.value) {
    if (formData[dateType]?.filter === CONSTANTS.DAY_WEEK_FILTER[0].value) {
      payload[`${dateType}`] = Number(formData[dateType]?.value)
    } else {
      payload[`${dateType}`] = Number(formData[dateType]?.value) * 7
    }
  }
}
export const mapAllowedMasters = (allowedMasters: any, staticOptions: any) => {
  return allowedMasters?.map((master: any) => {
    const matchedOption = staticOptions?.find((option: any) => option.value === master)
    return matchedOption ? matchedOption?.masterKey : master
  })
}
export const formatIngestionErrors = (failureReason: any) => {
  const extractAssetCreateError = (text: any) => {
    try {
      const jsonMatch = text.match(/Asset Create Error: (.+)/)
      if (jsonMatch && jsonMatch[1]) {
        const jsonPart = JSON.parse(jsonMatch[1])
        const message = jsonPart.message || 'NA'
        const errorDetails = Array.isArray(jsonPart.errors) ? jsonPart.errors.join(', ') : 'NA'
        return `Message: ${message}. Error: ${errorDetails}`
      }
    } catch {
      return 'NA'
    }
    return text
  }

  const extractStackTraceError = (text: any) => {
    try {
      const firstLineMatch = text.match(/^(.*?): (.*?)(\n|$)/) // Extracts the first line (e.g., `TypeError: Cannot read properties...`)
      if (firstLineMatch && firstLineMatch[2]) {
        return `Error: ${firstLineMatch[2]}`
      }
    } catch {
      // Ignore parsing failures and return the original text
      return 'NA'
    }
    return text
  }

  const extractNestedErrorType = (nestedErrors: any) => {
    return nestedErrors
      .map(
        (error: any) =>
          `Error Type: ${error.error_type || 'NA'}, Property: ${error.property || 'NA'}`,
      )
      .join('; ')
  }

  const errorMessage = (reason: any) => {
    if (!reason) return 'NA'

    if (typeof reason === 'string') {
      if (reason.includes('502 Bad Gateway')) {
        return '502 Bad Gateway'
      }
      if (reason.match(/Asset Create Error: (.+)/)) {
        return extractAssetCreateError(reason)
      }
      if (reason.includes('\n') && reason.match(/at\s/)) {
        // Likely a stack trace
        return extractStackTraceError(reason)
      }
      return reason // Return the original string as-is
    }

    if (typeof reason === 'object') {
      // Handle object with a specific key
      if (reason['asset-group-id']) {
        return JSON.stringify(reason) // Serialize the object
      }

      const message = reason.message || 'NA'
      const errors = Array.isArray(reason.errors) ? reason.errors.join(', ') : 'NA'
      const data = Array.isArray(reason.data) ? reason.data.join(', ') : 'NA'

      if (Array.isArray(reason)) {
        // Handle array of errors or stack traces
        if (
          reason[0] &&
          typeof reason[0] === 'string' &&
          reason[0].includes('\n') &&
          reason[0].match(/at\s/)
        ) {
          return extractStackTraceError(reason[0])
        }
        return reason.join(', ')
      }
      try {
        // Handle stringified JSON with nested errors
        const parsed = JSON.parse(message)
        if (
          Array.isArray(parsed) &&
          parsed.length &&
          Object.keys(parsed[0]).includes('error_type') &&
          Object.keys(parsed[0]).includes('property')
        ) {
          return extractNestedErrorType(parsed)
        }
      } catch {
        // Continue to the next logic if JSON parsing fails
      }
      // Handle error and data fields
      if (reason.errors && errors !== 'NA') return `Message: ${message}. Error: ${errors}`
      if (reason.data && data !== 'NA') return `Message: ${message}. Error: ${data}`
      return message
    }

    return 'NA'
  }

  try {
    if (typeof failureReason === 'string') {
      return errorMessage(JSON.parse(failureReason))
    }
    return errorMessage(failureReason)
  } catch {
    return errorMessage(failureReason)
  }
}

export const getFileTypeName = (fileType?: string) => {
  return fileType === 'images'
    ? 'Image'
    : fileType === 'subtitle'
    ? 'Subtitle'
    : CONSTANTS.DEFAULT_TEXT
}
export const updateRearrangeCast = (
  ev: any,
  item: any,
  inx: any,
  contentCategory: any,
  updateFormInputData: any,
) => {
  const contentData: any = { ...contentCategory }
  const updatedData = getRearrangeData(ev, item?.attribute)
  const modifiedAttribute = {
    ...contentData,
    metaAttributes: contentData.metaAttributes?.map((ele: any, ind: number) =>
      ind === inx ? { ...item, attribute: updatedData } : ele,
    ),
  }
  updateFormInputData(modifiedAttribute)
}
/**
 * Get the status of recent activities.
 * @function getDisplayStatus
 * @param {string} state - state of the asset eg. PUBLISHED, RIGHTS_EXPIRED, DE_PUBLISH etc.
 * @returns {string} - Display value of state eg. Published, Rights Expired,  Depublish etc.
 */
export const getDisplayStatus = (state: string) => {
  const statusObj: TypeObject = { ...CONSTANTS.ASSET_STATE_DISPLAY }
  return statusObj[state]
}
export const getChildList = (responseArr: any, record: any) => {
  const FilteredData = responseArr.filter(
    (item: any) => item.state !== 'ARCHIEVED' && item._id !== record._id,
  )
  return FilteredData
}

export const removeAppliedFilter = (
  selectedAppliedFilter = [],
  detailViewState = {} as any,
  dispatch: any,
) => {
  const index = selectedAppliedFilter?.findIndex(
    (item: any) => item?.versionId === detailViewState?.data?._id,
  )
  if (index !== -1) {
    const copySelectedAppliedFilter = [...selectedAppliedFilter]
    copySelectedAppliedFilter.splice(index, 1)
    dispatch(setAppliedFilterinStore(copySelectedAppliedFilter) as any)
  }
}

export const extractIngestionContentFailureMessage = (errorObj: {
  failureReason: string
  response?: string
}): string => {
  const failurePrefixes = [
    'Content create failed:',
    'Content update failed:',
    'Bulk image failed:',
    'Media info update failed:',
    'MarkAsDone API failed:',
    'Content validation failed:',
  ]

  // Check if failureReason starts with any of the specified prefixes
  const matchingPrefix = failurePrefixes.find((prefix) => errorObj.failureReason.startsWith(prefix))

  if (typeof errorObj.failureReason === 'string' && matchingPrefix) {
    // Try parsing response if it exists and is a valid JSON string
    if (errorObj.response) {
      try {
        let parsedResponse = JSON.parse(errorObj.response)
        if (typeof parsedResponse === 'string') {
          parsedResponse = JSON.parse(parsedResponse)
        }

        if (parsedResponse && typeof parsedResponse === 'object' && 'message' in parsedResponse) {
          return `${matchingPrefix} ${parsedResponse.message}`
        }
      } catch (e) {
        console.error('Failed to parse response JSON:', e)
      }
    }
  }

  return errorObj.failureReason
}

export const sortListingHeaders = (headersArray: any) => {
  return headersArray.sort((a: any, b: any) => {
    if (a.position === undefined) return 1
    if (b.position === undefined) return -1
    return a.position - b.position
  })
}

export const getPlatformDropdown = (platformDropdown: any[], selectedPlatforms: any[]): any[] => {
  if (!platformDropdown?.length) return []

  const isPlatformIndependentSelected = selectedPlatforms?.some(
    (item) => item.id === 'platform_independent',
  )

  return platformDropdown.map((item) => ({
    ...item,
    disabled: isPlatformIndependentSelected
      ? item.id !== 'platform_independent'
      : selectedPlatforms?.length > 0 && item.id === 'platform_independent',
  }))
}
