import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CONSTANTS, { PAGES_ROUTES } from '../../Constants'
import { HOME } from '../../Constants/ApiDefinations'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'
import { getLocalStorage } from '../../Helpers/Storage'
import {
  checkMetaCategoryPermission,
  combineErrorMessage,
  getDateInFormat,
  getDisplayStatus,
} from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import CircleArrowIcon from '../Icons/CircleArrowIcon'
import RecentActivityHeaderIcon from '../Icons/RecentActivityHeaderIcon'

/**
 * RecentActivity Component
 *
 * Renders Recent Activity Asset Component.
 *
 * @returns {React.JSX.Element | null} JSX Element representing the RecentActivity component.
 */

interface RecentActivityProps {
  title: string
  scheduleStatus: string
  updatedAt: string
  _id: string
  contentId: number
  contentStatus: string
  updatedBy: string
  blitzId: number
  assetType: string
  contentCategory: string
  assetCategory: string
}

const RecentActivity: React.FC = () => {
  const [activities, setActivities] = useState<RecentActivityProps[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const permissions = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA)
  // console.log(permissions, 'permissions')
  const checkReadAccessForMetaCategory: any = checkMetaCategoryPermission(permissions)

  const access = checkPermission(PERMISSIONS.MANAGE_CONTENT, [PERMISSION_TYPE.CAN_ACCESS])
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    getRecentActivities()
  }, [])

  /**
   * Get the data of recent activities.
   * @function getRecentActivities
   * @returns {void}
   */
  const getRecentActivities = async () => {
    setLoader(true)
    const payload = HOME.RECENT_ACTIVITY
    const result: TypeObject = await mainApiService(payload)
    if (result?.error) {
      dispatch(showAlert([true, combineErrorMessage(result), 'info']))
      setLoader(false)

      return
    }

    if (result?.data?.recentActivity?.length) {
      setActivities(result.data.recentActivity)
    }
    setLoader(false)
  }

  const getRedirectionPath = (item: RecentActivityProps) => {
    return `/asset/view/${item._id}/${item.assetType}/${item.contentCategory}/${item.assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`
  }

  return (
    <div className='recent-activity-wrapper'>
      {loader ? (
        <div className='loader page'>
          <CircularProgress variant='indeterminate' />
        </div>
      ) : (
        <>
          <h3>
            <RecentActivityHeaderIcon />
            Recent Activity
          </h3>
          <div className='recent-activity-list'>
            {!!activities?.length &&
              activities.map((item: RecentActivityProps) => {
                const redirectionPath: any =
                  access && checkReadAccessForMetaCategory
                    ? getRedirectionPath(item)
                    : `/home/asset-state-details/recent-activity${PAGES_ROUTES?.AccessDenied}`
                return (
                  <Link
                    to={redirectionPath}
                    state={{ fromPage: location.pathname }}
                    style={{ textDecoration: 'none' }}
                    className='table-link'
                    key={item._id}
                  >
                    <div className='activity-row' data-testid='activity-row'>
                      <div className='icon-left'>
                        <CircleArrowIcon />
                      </div>
                      <div className='publised-id pl-1' data-testid='activity-id'>
                        {getDisplayStatus(item.contentStatus)}{' '}
                        <span className='paragraph-bold-6'>ID: {item.blitzId} </span>
                      </div>
                      <div
                        className='publised-text pl-1 text-truncate'
                        data-testid='activity-text'
                        title={item.title?.length > 45 ? item.title : ''}
                      >
                        {item.title}
                      </div>
                      <div className='publised-author pl-1' data-testid='activity-author'>
                        By: {item.updatedBy}{' '}
                        <span>{getDateInFormat(item.updatedAt, CONSTANTS.DATE_FORMAT_TIME)}</span>
                      </div>
                    </div>
                  </Link>
                )
              })}

            {!loader && activities?.length === 0 && (
              <div className='d-flex align-items-center justify-content-center mt-5 mb-5 heading-3'>
                No Records Found
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default RecentActivity
