import { Box } from '@mui/material'
import React from 'react'

interface BadgeProps {
  text: string // Ensure text is required
  color?: 'success' | 'primary' | 'warning' | 'error' // Default to MUI theme colors
}

const CommonBadge: React.FC<BadgeProps> = ({ text, color = 'primary' }) => {
  if (!text) return null // Prevent rendering if text is missing

  const colors = {
    success: { bg: '#f4f9ea', text: '#4c6a0b', border: '#4c6a0b' },
    primary: { bg: '#ece9f2', text: '#402080', border: '#402080' },
    warning: { bg: '#fff3cd', text: '#856404', border: '#ffc107' },
    error: { bg: '#ffdbd5', text: '#d4351c', border: '#d4351c' },
  }

  const { bg, text: textColor, border } = colors[color] || colors['primary']

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: '9px',
        lineHeight: '22px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 400,
        backgroundColor: bg,
        color: textColor,
        border: `1px solid ${border}`,
        fontFamily: 'Inter, sans-serif',
      }}
    >
      {text}
    </Box>
  )
}

export default CommonBadge
