import { Grid, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import CONSTANTS from '../../../Constants'
import { getCommonFilterOptions } from '../../../Helpers/ManageContent'
import { handleDayFilterForStartsInAndExpiresIn } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import { TypeObject } from '../../../Models'
import { DeleteIcon } from '../../Icons'
import MUIDateTimePicker from '../../MUIDateTimePicker'
import {
  MUIBox,
  MUIButton,
  MUIFieldWithoutControl,
  MUIGrid,
  MUIMultiselectAsset,
  MUISelectWithoutControl,
  SimpleDropdown,
} from '../../Shared'

/**
 * Constants to identify and map form values to content and attributes
 */
const contentFilters = [
  'blitzId',
  'contentCategory',
  'assetCategory',
  'assetType',
  'state',
  'serviceAlias',
  'createdAt',
  'firstCreatedOn',
  'assetGroupName',
  'contentId',
  'avsInactiveMigrated',
]
const excludeAttributeFilters = [
  { label: 'title', fieldName: 'assetTitle', filterCondition: 'contains' },
  { label: 'countries', fieldName: 'properties', filterCondition: 'in' },
]
const attributeFilters = [
  { label: 'title', fieldName: 'assetTitle', filterCondition: 'contains' },
  { label: 'availabilityStartTime', fieldName: 'contractStartDate' },
  { label: 'availabilityEndTime', fieldName: 'contractEndDate' },
  { label: 'countries', fieldName: 'properties', filterCondition: 'in' },
  { label: 'activePlatform', fieldName: 'platformName', filterCondition: 'in' },
]

/**
 * AdvancedSearchFormProps Interface for component
 */
interface AdvancedSearchFormProps {
  contentCategories: TypeObject[]
  attributes: TypeObject[]
  assetCategory: TypeObject[]
  platforms: TypeObject[]
  countries: TypeObject[]
  assetTypes: TypeObject[]
  submitForm: (data: TypeObject, formData?: any, from?: boolean, isFormFilter?: boolean) => void
  formData: any
  setFormData: any
}

/**
 * Advance Search Form Component
 *
 * Renders Advance Search Form Component.
 * @param contentCategories - list of content categories for multiselect option
 * @param attributes - list of attributes for multiselect option
 * @param assetCategory - list of asset category option for multi select
 * @param platforms - list of platforms for multiselect
 * @param countries - list of countries for multiselect
 * @param assetType - list of asset type definition for multiselect
 * @param submitForm - (data) => { void } function to submit the form
 *
 * @returns {React.JSX.Element | null} JSX Element representing the Advance Search Form component.
 */

const AdvancedSearchForm: React.FC<AdvancedSearchFormProps> = ({
  contentCategories,
  attributes,
  assetCategory,
  platforms,
  countries,
  assetTypes,
  submitForm,
  formData,
  setFormData,
}: AdvancedSearchFormProps) => {
  /**
   * To add and remove meta attribute in the form
   * @function addRemoveCategoryFilter
   * @param {string} type - type = add / remove
   * @param {string} idx - index of the meta-attribute where user have to remove the field
   *
   * @returns {void}
   */

  const addRemoveCategoryFilter = (type: string, idx: number) => {
    if (type === 'add') {
      const addedData = {
        ...formData,
        attributeFilters: [
          ...formData.attributeFilters,
          {
            filterBy: '',
            filterCondition: '',
            filterValue: '',
            id: formData.attributeFilters.length + 1,
          },
        ],
        assetGroupFilters: [
          ...formData.assetGroupFilters,
          {
            filterBy: '',
            filterCondition: '',
            filterValue: '',
            id: formData.assetGroupFilters.length + 1,
          },
        ],
        mediaInfoFilters: [
          ...formData.mediaInfoFilters,
          {
            filterBy: '',
            filterCondition: '',
            filterValue: '',
            id: formData.mediaInfoFilters.length + 1,
          },
        ],
      }
      setFormData(addedData)
    } else {
      const removedData = { ...formData }
      removedData.attributeFilters.splice(idx, 1)
      setFormData(removedData)
    }
  }

  /**
   * To get and return the values of form based on type and keys in case of multiselect form
   * @function getValues
   * @param {string} type - type = multi-select / field
   * @param {string} value - value of the field
   * @param {string} key - key of multiselect which we are saving in the form
   * @returns {string | array[string] | date}
   */

  const getValues = (value: any, type: string, key: string) => {
    if (type === 'multi-select') {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          return value.map((item: any) => item[key])
        } else {
          return value?.[key]
        }
      }
    } else {
      return value
    }
  }

  const refreshValueAndCondition = (attributeField: any, name: string) => {
    if (name.includes('.filterBy')) {
      attributeField.filterValue = ''
      attributeField.filterCondition = ''
    }
  }

  /**
   * To handle the change of the inputs in the form
   * @function handleChange
   * @param {string} type - type = multi-select / field
   * @param {string} value - value of the field
   * @param {string} key - key of multiselect which we are saving in the form
   * @param {string} name - name of the field to be saved in formData
   * @returns {void}
   */
  const handleChange = (
    value: any,
    name: string,
    type: string,
    key: string,
    addDataType = false,
  ) => {
    const mainValue: any = value
    let valueData = value
    if (key === 'fieldName') {
      if (typeof value === 'string')
        valueData =
          getOptionsData(attributes, value, 'label') ||
          getOptionsData(attributes, value, 'fieldName') ||
          value
    }
    const data = { ...formData }
    const nameKeys = name.split('.')
    if (nameKeys?.length === 1) {
      data[nameKeys[0]] = getValues(valueData, type, key)
      if (addDataType) {
        data.dataType = mainValue?.dataType
        data.isMultiple =
          mainValue?.isMultiple && !CONSTANTS.EXCULDE_MULTIPLE.includes(mainValue?.fieldType)
            ? true
            : false
      }
    } else if (nameKeys?.length === 2) {
      data[nameKeys[0]][nameKeys[1]] = getValues(valueData, type, key)
      if (addDataType) {
        data[nameKeys[0]].dataType = mainValue?.dataType
        data[nameKeys[0]].isMultiple =
          mainValue?.isMultiple && !CONSTANTS.EXCULDE_MULTIPLE.includes(mainValue?.fieldType)
            ? true
            : false
      }
      refreshValueAndCondition(data[nameKeys[0]], name)
    } else if (nameKeys?.length === 3) {
      data[nameKeys[0]][+nameKeys[1]][nameKeys[2]] = getValues(valueData, type, key)
      if (addDataType) {
        data[nameKeys[0]][+nameKeys[1]].dataType = mainValue?.dataType
        data[nameKeys[0]][+nameKeys[1]].isMultiple =
          mainValue?.isMultiple && !CONSTANTS.EXCULDE_MULTIPLE.includes(mainValue?.fieldType)
            ? true
            : false
      }
      refreshValueAndCondition(data[nameKeys[0]][+nameKeys[1]], name)
    }
    setFormData(data)
  }

  /**
   * To prepare payload and calls the onSubmit function from parent
   * @function handleSubmit
   * @returns {void}
   */

  const handleSubmit = (ev: any): void => {
    ev?.preventDefault()
    const payload: TypeObject = {
      contentFilters: [],
      attributeFilters: [],
      assetGroupFilters: [],
      mediaInfoFilters: [],
      sort: [],
      page: 1,
      limit: 10,
    }

    /**
     * To prepare content filters array for payload
     * @function addToContentFilters
     * @param {string} key - filterBy eg contentCategory, assetCategory, state, assetType etc
     * @param {string} filterValue - value of the filter applied
     * @param {string} filterCondition - filterCondtion eg. is, is equal to, greater than, less than etc.
     * @returns {void}
     */
    const addToContentFilters = (
      key: string,
      filterValue: any,
      filterCondition = 'equals',
      isNull = false,
    ) => {
      if (filterValue || isNull)
        payload.contentFilters.push({ filterBy: key, filterCondition, filterValue })
    }

    /**
     * To prepare attribute filters array for payload
     * @function addToAttributeFilters
     * @param {string} fieldName - fieldName eg assetTitle, episodeNumber, AvailabilityStartDate, availablilityEndDate etc
     * @param {string} filterValue - value of the filter applied
     * @param {string} filterCondition - filterCondtion eg. is, is equal to, greater than, less than etc.
     * @returns {void}
     */
    const addToAttributeFilters = (
      fieldName: string,
      filterValue: any,
      filterCondition = '',
      isNull = false,
    ) => {
      if (filterValue !== undefined) {
        if (filterCondition !== 'null') {
          if (typeof filterValue === 'string' && filterValue !== '') {
            payload.attributeFilters.push({
              filterBy: fieldName,
              filterCondition,
              filterValue: filterValue?.trim(),
            })
          } else if (Array.isArray(filterValue) && filterValue.length > 0) {
            payload.attributeFilters.push({ filterBy: fieldName, filterCondition, filterValue })
          } else if (filterValue instanceof Date) {
            payload.attributeFilters.push({
              filterBy: fieldName,
              filterCondition,
              filterValue: new Date(filterValue).toISOString(),
            })
          } else if (typeof filterValue === 'boolean') {
            payload.attributeFilters.push({ filterBy: fieldName, filterCondition, filterValue })
          } else if (filterValue === null && filterCondition === 'equals' && isNull) {
            payload.attributeFilters.push({ filterBy: fieldName, filterCondition, filterValue })
          }
        }
      }
    }
    contentFilters.forEach((key) => {
      const value: any = formData[key]
      if (Array.isArray(value) && value.length) {
        addToContentFilters(key, value, 'in')
      } else if (
        value?.length &&
        key !== 'blitzId' &&
        key !== 'contentId' &&
        key !== 'avsInactiveMigrated'
      ) {
        typeof value === 'string'
          ? addToContentFilters(key, value?.trim())
          : addToContentFilters(key, value)
      } else if (key === 'firstCreatedOn' || key === 'assetGroupName') {
        const filterConditionFinal = value?.filter === 'null' ? 'equals' : value?.filter
        const filterValueForIn = value?.filter === 'null' ? null : value?.value
        addToContentFilters(key, filterValueForIn, filterConditionFinal, value?.filter === 'null')
      } else if (key === 'blitzId' && value !== '') {
        const blitzIdArr = value?.split(',')?.map((ele: any) => parseInt(ele))
        addToContentFilters(key, blitzIdArr, 'in')
      } else if (key === 'contentId' && value !== '') {
        const contentIdArr = value?.split(',')?.map((ele: any) => parseInt(ele))
        addToContentFilters(key, contentIdArr, 'in')
      } else if (key === 'avsInactiveMigrated' && value?.length) {
        addToContentFilters(key, value === 'Yes' ? true : false, 'equals', true)
      }
    })

    attributeFilters.forEach(({ fieldName, label, filterCondition }) => {
      const value = formData[label]
      if (filterCondition) {
        addToAttributeFilters(fieldName, value, filterCondition)
      } else if (value && value.filter) {
        const finalValue = value.filter === 'null' ? null : value.value
        const finalCondition = value.filter === 'null' ? 'equals' : value.filter
        addToAttributeFilters(fieldName, finalValue, finalCondition, value?.filter === 'null')
      }
    })

    const updateFilterValue = (filterCondition: string, filterValue: any, isMultiple = false) => {
      if (filterCondition === 'null') return null
      if ((filterCondition === 'in' || isMultiple) && filterValue?.length)
        return (
          filterValue?.length &&
          filterValue?.split(',')?.map((ele: any) => (isNaN(ele) ? ele?.trim() : parseInt(ele)))
        )
      return typeof filterValue === 'string' ? filterValue?.trim() : filterValue
    }

    if (formData.attributeFilters?.length) {
      const filterData = formData.attributeFilters
        .map(({ filterBy, filterCondition, filterValue, dataType, isMultiple }: TypeObject) => ({
          filterBy,
          filterCondition: filterCondition === 'null' ? 'equals' : filterCondition,
          filterValue: (() => {
            if (filterBy === 'firstCreatedOn')
              addToContentFilters(
                filterBy,
                filterCondition === 'null' ? null : filterValue,
                filterCondition === 'null' ? 'equals' : filterCondition,
                true,
              )
            else if (dataType === 'IMAGE_TYPE') {
              const trimedFilterValue = filterValue?.trim()
              let finalUrl = trimedFilterValue
              if (process.env.REACT_APP_CLOUDFRONT_BASE_URL === trimedFilterValue) {
                finalUrl = ''
              } else if (trimedFilterValue?.startsWith(process.env.REACT_APP_CLOUDFRONT_BASE_URL)) {
                finalUrl =
                  trimedFilterValue.split(`${process.env.REACT_APP_CLOUDFRONT_BASE_URL}/`).pop() ||
                  ''
              }
              return updateFilterValue(filterCondition, finalUrl)
            } else if (filterValue instanceof Date)
              return updateFilterValue(filterCondition, new Date(filterValue).toISOString())
            else if (typeof filterValue === 'boolean')
              return updateFilterValue(filterCondition, filterValue)
            else if (dataType === 'NUMBER' && !isNaN(filterValue)) {
              return updateFilterValue(filterCondition, +filterValue)
            } else if (
              isMultiple &&
              (filterCondition === 'equals' || filterCondition === 'notEquals')
            ) {
              return updateFilterValue(filterCondition, filterValue, isMultiple)
            } else {
              return updateFilterValue(filterCondition, filterValue)
            }
          })(),
        }))
        .filter((attr: any) => attr.filterValue !== '' && attr.filterValue !== undefined)

      payload.attributeFilters.push(...filterData)
    }

    /**
     * Commented code for startsIn and expiresIn via date filter in payload attributeFilters
     * Added new code for startsIn and expiresIn via day filter in payload directly by key
    handleDateFilterForStartsInAndExpiresIn('startsIn', 'contractStartDate', formData, payload)
    handleDateFilterForStartsInAndExpiresIn('expiresIn', 'contractEndDate', formData, payload)
     */

    handleDayFilterForStartsInAndExpiresIn('startsIn', formData, payload)
    handleDayFilterForStartsInAndExpiresIn('expiresIn', formData, payload)

    const payloadCopy = JSON.parse(JSON.stringify(payload))
    payload?.attributeFilters?.forEach((filterObj: any) => {
      const { filterBy = '' } = filterObj
      const fieldName = filterBy?.split('.')
      if (
        fieldName?.length === 1 &&
        ['assetGroupName', 'mediaProfile', 'assetType'].includes(fieldName[0])
      ) {
        const indexToRemove = payloadCopy?.attributeFilters.findIndex(
          (obj: any) => obj.filterBy === filterBy,
        )
        payloadCopy?.attributeFilters.splice(indexToRemove, 1)
        payloadCopy?.assetGroupFilters.push({
          filterBy: filterObj.filterBy,
          filterCondition: filterObj.filterCondition,
          filterValue:
            typeof filterObj.filterValue === 'string'
              ? filterObj.filterValue?.trim()
              : filterObj.filterValue,
        })
      } else if (fieldName?.length > 1 && fieldName[0] === 'subtitleLanguageFiles') {
        const indexToRemove = payloadCopy?.attributeFilters.findIndex(
          (obj: any) => obj.filterBy === filterBy,
        )
        payloadCopy?.attributeFilters.splice(indexToRemove, 1)
        payloadCopy?.mediaInfoFilters.push({
          filterBy: filterObj.filterBy,
          filterCondition: filterObj.filterCondition,
          filterValue:
            typeof filterObj.filterValue === 'string'
              ? filterObj.filterValue?.trim()
              : filterObj.filterValue,
        })
      }
    })
    submitForm(payloadCopy, false, true)
  }

  /**
   * To find the option from array based on key for multiselect
   * @function getOptionsData
   * @param {array} options - options of multiselect
   * @param {string} value - value of multiselect
   * @param {string} key - key in array of object to by which the option could be find in array
   * @returns {array | string}
   */
  const getOptionsData = (options: any, value: any, key: string) => {
    const selectedOptions: any = []
    if (Array.isArray(value)) {
      if (value) {
        for (const i of value) {
          const k = options.find((item: any) => item[key] === i)
          if (k) {
            selectedOptions.push(k)
          }
        }
      }
      return selectedOptions ?? []
    } else {
      const k = options.find((item: any) => item[key] === value)
      return k ?? ''
    }
  }

  const getAttributes = () => {
    const filterSet: any = new Set(excludeAttributeFilters.map((filter) => filter.fieldName))
    const filteredAttributes = attributes.filter((item) => !filterSet.has(item.fieldName))
    return filteredAttributes
  }

  useEffect(() => {
    updateTagMetaData({ title: 'Search' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  return (
    <MUIBox>
      <form className=''>
        <div className='heading-6 d-flex text-left pt-1 pb-1 pl-2 pr-2'>Attributes</div>
        <hr className='border__box_width_hr' />
        <div className=' pt-2 pb-2 pl-2 pr-2'>
          <Grid container columnSpacing={7} rowSpacing={4}>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Title</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIFieldWithoutControl
                name='title'
                label='Title'
                type='text'
                maxLength={100}
                value={formData.title}
                onChange={(value: string) => handleChange(value, 'title', 'field', '')}
              />
            </Grid>

            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Blitz Id</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIFieldWithoutControl
                name='blitzId'
                label='Blitz Id'
                type='text'
                multiline={true}
                maxLength={800}
                value={formData.blitzId}
                onChange={(value: string) => handleChange(value, 'blitzId', 'field', '')}
              />
            </Grid>

            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Content Id</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIFieldWithoutControl
                name='contentId'
                label='Content Id'
                type='text'
                maxLength={800}
                multiline={true}
                value={formData.contentId}
                onChange={(value: string) => handleChange(value, 'contentId', 'field', '')}
              />
            </Grid>

            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Content Category</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'contentCategory'}
                multiple={true}
                label={'Select'}
                options={contentCategories}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'contentCategory', 'multi-select', 'key')
                }
                id={'contentCategory'}
                value={getOptionsData(contentCategories, formData.contentCategory, 'key')}
                labelKey={'value'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Asset Category</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'assetCategory'}
                multiple={true}
                label={'Select'}
                options={assetCategory}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'assetCategory', 'multi-select', 'key')
                }
                id={'assetCategory'}
                value={getOptionsData(assetCategory, formData.assetCategory, 'key')}
                labelKey={'value'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Asset Type</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'assetTypeValue'}
                multiple={true}
                label={'Select'}
                options={assetTypes}
                error={{}}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'assetType', 'multi-select', 'value')
                }
                id={'assetTypeValue'}
                valueKey='value'
                value={getOptionsData(assetTypes, formData.assetType, 'value')}
                labelKey={'assetType'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>State</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'state'}
                multiple={true}
                label={'Select'}
                options={CONSTANTS.LISTING_STATE_OPTIONS.filter((state: any) => state.value)}
                error={{}}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'state', 'multi-select', 'value')
                }
                valueKey='value'
                id={'state'}
                value={getOptionsData(CONSTANTS.LISTING_STATE_OPTIONS, formData.state, 'value')}
                labelKey={'label'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Allowed Countries</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'countries'}
                multiple={true}
                label={'Select'}
                options={countries}
                error={{}}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'countries', 'multi-select', 'key')
                }
                id={'allowedCountries'}
                value={getOptionsData(countries, formData.countries, 'key')}
                labelKey={'value'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Active Platforms</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name={'activePlatform'}
                multiple={true}
                label={'Select'}
                options={platforms}
                error={{}}
                onInputChange={(value: string, newValue: any) =>
                  handleChange(newValue, 'activePlatform', 'multi-select', 'key')
                }
                id={'activePlatform'}
                value={getOptionsData(platforms, formData.activePlatform, 'key')}
                labelKey={'value'}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Partner</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIFieldWithoutControl
                name='serviceAlias'
                label='Partner'
                type='text'
                maxLength={100}
                value={formData.serviceAlias}
                onChange={(value: string) => handleChange(value, 'serviceAlias', 'field', '')}
              />
            </Grid>
            <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Availability Start Time</div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUISelectWithoutControl
                name='availabilityStartTime'
                lable={'Select'}
                onChange={(value: string) =>
                  handleChange(value, 'availabilityStartTime.filter', 'field', '')
                }
                value={formData.availabilityStartTime.filter}
                options={CONSTANTS.NUMBER_DATE_FILTER}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              {formData.availabilityStartTime.filter !== 'null' && (
                <MUIDateTimePicker
                  label={'Value'}
                  type='datetime'
                  value={formData.availabilityStartTime.value}
                  onChange={(value: string) =>
                    handleChange(value, 'availabilityStartTime.value', 'field', '')
                  }
                  name={'availabilityStartDateValue'}
                  error={{}}
                />
              )}
            </Grid>
            <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Starts In</div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUISelectWithoutControl
                name='startsIn'
                lable={'Select'}
                value={formData.startsIn.filter}
                onChange={(value: string) => handleChange(value, 'startsIn.filter', 'field', '')}
                options={CONSTANTS.DAY_WEEK_FILTER}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUIFieldWithoutControl
                name='startsInValue'
                label='Value'
                value={formData.startsIn.value}
                onChange={(value: string) => handleChange(value, 'startsIn.value', 'field', '')}
                type='text'
                //
                maxLength={100}
              />
            </Grid>

            <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Availability End Time</div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUISelectWithoutControl
                name='availabilityEndTime'
                lable={'Select'}
                value={formData.availabilityEndTime.filter}
                onChange={(value: string) =>
                  handleChange(value, 'availabilityEndTime.filter', 'field', '')
                }
                options={CONSTANTS.NUMBER_DATE_FILTER}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              {formData.availabilityEndTime.filter !== 'null' && (
                <MUIDateTimePicker
                  error={{}}
                  label={'Value'}
                  type='datetime'
                  value={formData.availabilityEndTime.value}
                  onChange={(value: string) =>
                    handleChange(value, 'availabilityEndTime.value', 'field', '')
                  }
                  name={'availabilityEndDateValue'}
                />
              )}
            </Grid>

            <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Expires In</div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUISelectWithoutControl
                name='expiresIn'
                lable={'Select'}
                value={formData.expiresIn.filter}
                onChange={(value: string) => handleChange(value, 'expiresIn.filter', 'field', '')}
                options={CONSTANTS.DAY_WEEK_FILTER}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUIFieldWithoutControl
                name='expiresInValue'
                label='Value'
                type='text'
                value={formData.expiresIn.value}
                onChange={(value: string) => handleChange(value, 'expiresIn.value', 'field', '')}
                maxLength={100}
              />
            </Grid>
            {/* *********************** */}
            <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
              <div className='paragraph-regular-5'>Creation Date</div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              <MUISelectWithoutControl
                name='firstCreatedOn'
                lable={'Select'}
                value={formData.firstCreatedOn.filter}
                onChange={(value: string) =>
                  handleChange(value, 'firstCreatedOn.filter', 'field', '')
                }
                options={CONSTANTS.NUMBER_DATE_FILTER}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4} className=''>
              {formData.firstCreatedOn.filter !== 'null' && (
                <MUIDateTimePicker
                  error={{}}
                  label={'Value'}
                  type='datetime'
                  value={formData.firstCreatedOn.value}
                  onChange={(value: string) =>
                    handleChange(value, 'firstCreatedOn.value', 'field', '')
                  }
                  name={'createdAtDateValue'}
                />
              )}
            </Grid>
            <Grid className='text-left' item xs={12} md={6} lg={4} xl={4}>
              <div className='paragraph-regular-5'>AVS Inactive Migrated</div>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8} className=''>
              <MUIMultiselectAsset
                name='avsInactiveMigrated'
                label={'Select'}
                options={CONSTANTS.AVS_INACTIVE_MIGRATED_FILTER}
                labelKey={'label'}
                onInputChange={(value: string, newValue: any) => {
                  handleChange(newValue?.value, 'avsInactiveMigrated', 'field', '')
                }}
                id={'avsInactiveMigrated'}
                valueKey='value'
                value={formData.avsInactiveMigrated}
              />
            </Grid>

            {/* ************************* */}
            {formData?.attributeFilters?.map(
              (
                item: {
                  filterBy: string
                  filterCondition: string
                  filterValue: string
                  id: number
                },
                idx: number,
              ) => (
                <React.Fragment key={item.id}>
                  <Grid className='text-left' item xs={12} md={12} lg={4} xl={4}>
                    <MUIMultiselectAsset
                      name='filterBy'
                      label={'Select Meta Attribute'}
                      options={getAttributes()}
                      labelKey={'label'}
                      onInputChange={(value: string, newValue: any) => {
                        handleChange(
                          newValue,
                          `attributeFilters.${idx}.filterBy`,
                          'multi-select',
                          'fieldName',
                          true,
                        )
                      }}
                      id={'assetType'}
                      valueKey='fieldName'
                      value={getOptionsData(attributes, item.filterBy, 'fieldName')}
                    />
                  </Grid>
                  {(() => {
                    const attr = attributes.find(
                      (attrData) => attrData.fieldName === item?.filterBy,
                    )
                    let type
                    if (attr) {
                      if (attr.fieldType === 'DATE') {
                        type = 'date'
                      } else if (attr.fieldType === 'TIME') {
                        type = 'time'
                      } else {
                        type = 'datetime'
                      }
                    }

                    return (
                      <>
                        <Grid item xs={12} md={6} lg={4} xl={4} className=''>
                          <MUISelectWithoutControl
                            name='filterCondition'
                            value={item.filterCondition}
                            onChange={(value: string) =>
                              handleChange(
                                value,
                                `attributeFilters.${idx}.filterCondition`,
                                'field',
                                '',
                              )
                            }
                            lable={'Select'}
                            options={getCommonFilterOptions(attr?.fieldType, attr?.dataType, attr)}
                          />
                        </Grid>
                        {item.filterCondition === 'null' && (
                          <div className='metaattribute-relative'>
                            <IconButton
                              data-testid='remove-btn-search'
                              onClick={() => addRemoveCategoryFilter('remove', idx)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                        {item.filterCondition !== 'null' && (
                          <Grid item xs={12} md={6} lg={4} xl={4} className=' position-relative'>
                            <div className='metaattributes-fields'>
                              {(() => {
                                if (
                                  (attr?.fieldType === 'DATE' ||
                                    attr?.fieldType === 'TIME' ||
                                    attr?.fieldType === 'DATETIME') &&
                                  attr?.dataType !== 'NUMBER'
                                ) {
                                  return (
                                    <MUIDateTimePicker
                                      error={{}}
                                      label={'Value'}
                                      type={type as string}
                                      value={item.filterValue}
                                      onChange={(value: string) =>
                                        handleChange(
                                          value,
                                          `attributeFilters.${idx}.filterValue`,
                                          'field',
                                          '',
                                        )
                                      }
                                      name={'filterValue'}
                                    />
                                  )
                                } else if (attr?.dataType === 'BOOLEAN') {
                                  return (
                                    <MUIGrid className='d-flex col1 boolean-dropdown-wrapper'>
                                      <SimpleDropdown
                                        selectId='filter-option'
                                        handleChange={(e) => {
                                          handleChange(
                                            e.target.value,
                                            `attributeFilters.${idx}.filterValue`,
                                            'field',
                                            '',
                                          )
                                        }}
                                        fullWidth
                                        options={
                                          [
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false },
                                          ] as any
                                        }
                                        value={item.filterValue}
                                      />
                                    </MUIGrid>
                                  )
                                } else {
                                  return (
                                    <MUIFieldWithoutControl
                                      name='filterValue'
                                      label='Value'
                                      value={item.filterValue}
                                      onChange={(value: string) =>
                                        handleChange(
                                          value,
                                          `attributeFilters.${idx}.filterValue`,
                                          'field',
                                          '',
                                        )
                                      }
                                      type='text'
                                      maxLength={500}
                                    />
                                  )
                                }
                              })()}
                            </div>
                            {item.filterCondition !== 'null' && (
                              <div className='delete-icon'>
                                <IconButton
                                  data-testid='remove-btn-search'
                                  onClick={() => addRemoveCategoryFilter('remove', idx)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            )}
                          </Grid>
                        )}
                      </>
                    )
                  })()}
                </React.Fragment>
              ),
            )}
          </Grid>

          <Grid xs={12} item>
            <Grid container spacing={10} className='mt-0'>
              <Grid item xs={12} className='d-flex justify-content-start pt-0 mt-3'>
                <MUIButton
                  size='large'
                  handleClickCB={() => addRemoveCategoryFilter('add', 0)}
                  type={'button'}
                  data-testid='add-btn-search'
                  label={'Add Meta Attribute'}
                  variant={'outlined'}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} item className='pt-0'>
            <Grid container spacing={10} className='mt-0 pt-0'>
              <Grid
                item
                xs={12}
                sx={{ paddingTop: '24px !important' }}
                className='d-flex justify-content-end mt-1 pt-1'
              >
                <MUIButton
                  size='large'
                  type={'submit'}
                  label={'Search'}
                  data-testid='submit-btn-search'
                  variant='contained'
                  width={200}
                  className='c-btn'
                  handleClickCB={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </form>
    </MUIBox>
  )
}

export default AdvancedSearchForm
