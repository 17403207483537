/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import CONSTANTS from '../../Constants'
import { ASSET_CURD_API } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface OrchestrationState {
  list: any
  masters: any
  audioVideoList: any
  detailedAssetList: any
  selectedRow: any
  continuationToken: any
  subtitleList: any
  subtitles?: any
  subtitlesConfigureToken: string
  s3SubtitleList?: any
}

const initialState: OrchestrationState = {
  list: [],
  masters: [],
  audioVideoList: [],
  detailedAssetList: {},
  selectedRow: {},
  continuationToken: null,
  subtitleList: [],
  s3SubtitleList: [],
  subtitles: [],
  subtitlesConfigureToken: '',
}

const orchestrationSlice = createSlice({
  name: 'orchestration',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<boolean>) => {
      state.audioVideoList = action.payload
    },
    setMastersReducer: (state, action: PayloadAction<any>) => {
      state.masters = action.payload
    },
    setAudioVideoList: (state, action: PayloadAction<any>) => {
      const { data } = action.payload

      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      const folders = data?.url?.CommonPrefixes?.length
        ? data?.url?.CommonPrefixes.map((item: any) => ({
            Key: item.Prefix,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FOLDER,
          }))
        : []

      state.audioVideoList = [...folders, ...list]
      state.continuationToken = data?.url?.NextContinuationToken ?? null
    },
    setAudioVideoAppend: (state, action: PayloadAction<any>) => {
      const { data } = action.payload

      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      state.audioVideoList = [...state.audioVideoList, ...list]
      state.continuationToken = data?.url?.NextContinuationToken ?? null
    },
    setS3SubtitleList: (state, action: PayloadAction<any>) => {
      const { data } = action.payload

      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      const folders = data?.url?.CommonPrefixes?.length
        ? data?.url?.CommonPrefixes.map((item: any) => ({
            Key: item.Prefix,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FOLDER,
          }))
        : []
      state.s3SubtitleList = [...folders, ...list]
      state.subtitlesConfigureToken = data?.url?.NextContinuationToken ?? null
    },
    setS3SubtitleListAppend: (state, action: PayloadAction<any>) => {
      const { data } = action.payload
      const folders = data?.url?.CommonPrefixes?.length
        ? data?.url?.CommonPrefixes.map((item: any) => ({
            Key: item.Prefix,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FOLDER,
          }))
        : []
      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      state.s3SubtitleList = [...state.s3SubtitleList, ...folders, ...list]
      state.subtitlesConfigureToken = data?.url?.NextContinuationToken ?? null
    },
    setAudioVideoListSelected: (state, action: PayloadAction<any>) => {
      state.audioVideoList = action.payload
    },
    setSubtitleListS3Selected: (state, action: PayloadAction<any>) => {
      state.s3SubtitleList = action.payload
    },
    setSubtitleListSelected: (state, action: PayloadAction<any>) => {
      state.subtitleList = action.payload
    },
    setAssetDetailedList: (state, action: PayloadAction<any>) => {
      state.detailedAssetList = action.payload
    },
    setSelectedTableRow: (state, action: PayloadAction<any>) => {
      state.selectedRow = action.payload
    },
  },
})

export const {
  setList,
  setMastersReducer,
  setAudioVideoList,
  setAssetDetailedList,
  setSelectedTableRow,
  setAudioVideoListSelected,
  setAudioVideoAppend,
  setS3SubtitleListAppend,
  setS3SubtitleList,
  setSubtitleListSelected,
  setSubtitleListS3Selected,
} = orchestrationSlice.actions

export const workflowStatusService = createAsyncThunk(
  'orchestration/workflowStatus',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.WORKFLOW_STATUS(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const listAudioVideo = createAsyncThunk(
  'orchestration/listAudioVideo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    let reqData: any = {
      filePath: payload?.filePath ?? '',
      pageLimit: 20,
      contentId: payload?.contentId,
    }
    if (payload?.continuationToken) {
      reqData = { ...reqData, continuationToken: payload.continuationToken }
    }
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.LIST_AUDIO_VIDEO(reqData)
    const data: any = await mainApiService(requestedData)
    if (!data?.error) {
      if (payload?.continuationToken) {
        dispatch(setAudioVideoAppend(data))
      } else {
        dispatch(setAudioVideoList(data))
      }
    }
    dispatch(setLoader(false))
    return data
  },
)

export const listSubtitle = createAsyncThunk(
  'orchestration/listSubtitle',
  async (payload: any, { dispatch }) => {
    let reqData: any = {
      filePath: payload?.filePath ?? '',
      pageLimit: 20,
    }
    if (payload?.continuationToken) {
      reqData = { ...reqData, continuationToken: payload.continuationToken }
    }
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.LIST_SUBTITLE(reqData)
    const data: any = await mainApiService(requestedData)
    if (!data?.error) {
      if (data?.data?.url?.Contents || data?.data?.url?.CommonPrefixes) {
        dispatch(setS3SubtitleListAppend(data))
      }
      if (payload?.continuationToken) {
        dispatch(setAudioVideoAppend(data))
      } else {
        dispatch(setS3SubtitleList(data))
      }
    }

    return data
  },
)

export const getExistingAssetListService = createAsyncThunk(
  'orchestration/getExistingGroup',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.GET_EXISTING_LIST(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const generatePresignedUrlForSubtitle = createAsyncThunk(
  'image/attribute/variant/presignedUrl',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any =
      ASSET_CURD_API.VIDEO_CONTENT_TYPE.GENERATE_SUBTITLE_PRESIGNED_URL(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

const orchestrationSelector = (state: { orchestration: OrchestrationState }) => state.orchestration
export const selectDetailedAsset = createSelector(
  orchestrationSelector,
  (state) => state.detailedAssetList,
)
export const selectedTableRow = createSelector(orchestrationSelector, (state) => state.selectedRow)
export const selectVideoAudioList = createSelector(orchestrationSelector, (state) => state.list)
export const selectMasters = createSelector(orchestrationSelector, (state) => state.masters)
export const selectContinuationToken = createSelector(
  orchestrationSelector,
  (state) => state.continuationToken,
)
export const selectAudioVideoList = createSelector(
  orchestrationSelector,
  (state) => state.audioVideoList,
)
export const selectSubtitleList = createSelector(
  orchestrationSelector,
  (state) => state.subtitleList,
)
export const selectS3SubtitleList = createSelector(
  orchestrationSelector,
  (state) => state.s3SubtitleList,
)

export const selectSubtitles = createSelector(orchestrationSelector, (state) => state.subtitles)
export const selectSubtitleToken = createSelector(
  orchestrationSelector,
  (state) => state.subtitlesConfigureToken,
)
export default orchestrationSlice
