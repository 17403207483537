export const VideoAssetGroupConstants = {
  TITLE: 'Asset Group',
  INIT_STAGE: {
    CREATE: 'create',
    UPDATE: 'update',
    LOADING: 'loading',
  },
  INIT_STAGE_MESSAGE: 'There are no files selected for transcoding.',
  INIT_SUBTITLE_MESSAGE: 'There are no files selected for subtitles.',
  ASSET_GROUP_TYPE: {
    NEW: 'new',
    EXISTING: 'existing',
    RENAME: 'rename',
  },
  VIDEO_ADD: 'VIDEO_ADD',
  AUDIO_ADD: 'AUDIO_ADD',
  INTEGRATED_ADD: 'INTEGRATED_ADD',
  VIDEO_REMOVE: 'VIDEO_REMOVE',
  AUDIO_REMOVE: 'AUDIO_REMOVE',
  LANGUAGE: 'LANGUAGE',
  MEDIA_PROFILE: 'MEDIA_PROFILE',
  TRANSCODING_PROCESS_TYPE: 'TRANSCODING_PROCESS_TYPE',
  INTEGRATED_REMOVE: 'INTEGRATED_REMOVE',
  PROCESS_INTEGRATED_LABEL: 'Process Integrated Files',
  ADD_EXISTING_GROUP_LABEL: 'Add Existing Group',
  CREATE_NEW_GROUP_LABEL: 'Create New Group',
  FETCH_VALIDATE_LABEL: 'Fetch & Validate',
  VALIDATE_LABEL: 'Validate',
  VIDEO_LABEL: 'Video',
  AUDIO_LABEL: 'Audio',
  INTEGRATED_LABEL: 'Integrated',
  DEFAULT_LANGUAGE_WARNING_VERSION2:
    'To activate Fetch and Validate, choose Encryption and Default Language values in the Media Attribute section. Also, make sure every file has a selected language, with at least one file matching the Default Language value. Additionally, select a value for Transcoding Process Type.',
  DEFAULT_LANGUAGE_WARNING:
    'To enable Fetch and Validate, select value for the Encryption and Default language in the Media Attribute section. Additionally, ensure that a language is selected for all files, and at least one file`s language matches the Default Language value.',
  LANGUAGE_WARNING:
    'To enable Fetch & validate, choose the language for the selected file. Ensure the selected language for the files, matches the default language.',
  FETCH_VALIDATE_TOOLTIP_CSS: {
    tooltip: {
      sx: {
        maxWidth: 'none',
        width: '32rem',
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: '500',
        color: '#402080',
        bgcolor: '#ECE9F2',
        lineHeght: '24px',
        padding: '10px 15px',
        textAlign: 'center',
        '& .MuiTooltip-arrow': {
          color: '#ECE9F2',
        },
      },
    },
  },
  SAVE_SEARCH_TOOLTIP_CSS: {
    tooltip: {
      sx: {
        maxWidth: 'none',
        width: '20rem',
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: '500',
        color: '#402080',
        bgcolor: '#ECE9F2',
        lineHeght: '24px',
        padding: '10px 15px',
        textAlign: 'center',
        '& .MuiTooltip-arrow': {
          color: '#ECE9F2',
        },
      },
    },
  },
  IS_PREVIEWED_LABEL: 'Is Previewed',
  ALERT_TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
  },
  VIDEO_COLUMNS: [
    { id: 'path', label: 'File Name', sort: false },
    { id: 'resolution', label: 'Video Resolution', sort: false },
    { id: 'quality', label: 'Video Quality', sort: false },
    { id: 'action', label: 'Action', sort: false },
  ],
  AUDIO_COLUMNS: [
    { id: 'path', label: 'File Name', sort: false },
    { id: 'language', label: 'Language', sort: false },
    { id: 'qualityChannel', label: 'Audio Channel', sort: false },
    { id: 'action', label: 'Action', sort: false },
  ],
  INTEGRATED_COLUMNS: [
    { id: 'path', label: 'File Name', sort: false },
    { id: 'language', label: 'Language', sort: false },
    { id: 'action', label: 'Action', sort: false },
  ],
  DIALOG_TYPE: {
    AUDIO: 'audio',
    VIDEO: 'video',
    SUBTITLE: 'subtitle',
    INTEGRATED: 'integrated',
  },
  DIALOG_TEXTS: {
    AUDIO: {
      HEADING: 'Add Audio',
      SUB_HEADING: 'You can search and select existing audio files for the transcoding process.',
    },
    VIDEO: {
      HEADING: 'Add Video',
      SUB_HEADING: 'You can search and select existing video files for the transcoding process.',
    },
    INTEGRATED: {
      HEADING: 'Add Integrated',
      SUB_HEADING:
        'You can search and select existing integrated files for the transcoding process.',
    },
  },
  INITIATED_STATUS: [
    'VERIFICATION_INITIATED',
    'PREVIEW_INITIATED',
    'TRANSCODING_INITIATED',
    'CANCEL_INITIATED',
  ],
  AFTER_VALIDATION_TYPE: ['preview', 'transcoding'],
  AFTER_PREVIEW_TYPE: ['transcoding'],
  NEED_LONGPULL_STATUS: [
    'CREATE',
    'UPDATE',
    'VERIFICATION_INITIATED',
    'PREVIEW_INITIATED',
    'TRANSCODING_INITIATED',
    'CANCEL_INITIATED',
  ],
  API_REFRESH_INTERVAL: 10000,
  VIDEO_TRANSCODE_STAGES: {
    VALIDATTION: 'validation',
    PREVIEW: 'preview',
    TRANSCODING: 'transcoding',
    CANCEL: 'cancel',
  },
  HIDE_API_ERRORS: [
    'Invalid AssetGroupId! Data Not found in Database',
    'You don\'t have access to this section. Please reach out to your system administrator to get the access.',
  ],
  LANGUAGES_MODAL_EMPTY_MESSAGE:
    'Adjust the language sequence by using the drag-and-drop feature with the rearrange icon',
  LANGUAGE_MISMATCH: {
    TITLE: 'Language Mis-match',
    DESCRIPTION:
      'Please ensure that language of at least 1 file should match with the Default Language selected in Descriptive Metadata of the content.',
    BUTTON_LABEL: 'Okay',
  },
  CHANGE_LANGUAGE_DIALOG: {
    ADD_AUDIO_TITLE: 'Add Audio language',
    ADD_AUDIO_SUB_TITLE: 'Please add the language for audio files.',
    EDIT_AUDIO_TITLE: 'Edit Audio language',
    EDIT_AUDIO_SUB_TITLE: 'You can edit the selected languages for audio files.',
  },
  BREADCRUMS_DATA_IMAGE: [
    { label: 'Bucket', active: false, path: '' },
    { label: 'Videoasset_images', active: true, path: 'videoasset_images/' },
  ],
  BREADCRUMS_DATA: [{ label: 'Bucket', active: false, path: '.' }],
  FETCHING_INPROGRESS: 'Fetching in-progress',
  PREVIEW_INPROGRESS: 'Preview in-progress',
  TRANSCODING_INPROGRESS: 'Transcoding in-progress',
  CANCELLATION_INPROGRESS: 'Cancellation in-progress',
  CONF_CANCEL_PREVIEW: 'Cancel Preview Request',
  CONF_CANCEL_TRANSCODING: 'Cancel Transcoding Request',
  CONF_CANCEL_DESC: 'Are you sure, you want to cancel this action?',
  CONF_CANCEL_C_BTN: 'Cancel',
  CONF_CANCEL_P_BTN: 'Proceed',
}
