import { Container } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import AccessDeniedImage from '../../Assets/Images/access_denied.gif'
import { BackButton, Breadcrumbs } from '../../Components/Shared'
import CONSTANTS, { BREADCRUMS } from '../../Constants'
import { getDisplayStatus } from '../../Helpers/Util'
import '../AccessDenied/AccessDenied.scss'

const HomeAccessError = () => {
  const navigate = useNavigate()
  const { contentState = '' } = useParams()
  const label =
    CONSTANTS.HOME_NO_PERMISSION_TEXT?.[contentState] ||
    getDisplayStatus(contentState) ||
    contentState

  return (
    <div className='p-16'>
      <div className='p-8'>
        <div className='p-8'>
          <Breadcrumbs breadcrums={BREADCRUMS.HOME_ACCESS_DENIED(label) as any} />
        </div>
        <BackButton
          label={label}
          className='col73 p-8'
          handleOnClick={(e: any) => {
            e.preventDefault()
            if (!location?.pathname?.includes('recent-activity')) {
              return navigate(
                `${location.pathname.replace('/access-denied', '')}${location.search}`,
              )
            }
            navigate('/home')
          }}
        />
      </div>
      <div>
        <Container maxWidth='sm'>
          <div className=''>
            <img src={AccessDeniedImage} alt='Access Denied Image' />
          </div>
        </Container>
        <Container>
          <p className='message'>
            You don&apos;t have access to this section.
            <br />
            Please reach out to your system administrator to get the access
          </p>
        </Container>
      </div>
    </div>
  )
}

export default HomeAccessError
