// Define an enum for page names
export enum PageNames {
  Root = 'Root',

  Login = 'Login',
  Home = 'Home',

  ManageContent = 'ManageContent',
  ManageContentListing = 'ManageContentListing',
  Shorts = 'Shorts',
  Movies = 'Movies',
  Sports = 'Sports',
  TvShows = 'TvShows',
  Originals = 'Originals',
  NonVODAssest = 'NonVODAssest',
  IndependentVODAssest = 'IndependentVODAssest',
  ManageContentAssetCreate = 'ManageContentAssetCreate',
  ManageContentAssetView = 'ManageContentAssetView',
  ManageContentAssetEdit = 'ManageContentAssetEdit',
  ManageContentAssetAuditRails = 'ManageContentAssetAuditRails',
  ManageContentAssetAuditRailsView = 'ManageContentAssetAuditRailsView',
  ManageContentAssetAssociatedAssets = 'ManageContentAssetAssociatedAssets',

  Role = 'Role',
  RoleCreate = 'RoleCreate',
  RoleViewEdit = 'RoleViewEdit',
  RoleAssociatedUsers = 'RoleAssociatedUsers',

  User = 'User',
  UserCreate = 'UserCreate',
  UserEdit = 'UserEdit',
  UserView = 'UserView',

  MetaMaster = 'MetaMaster',

  AttributeRepo = 'AttributeRepo',
  AttributeRepoCreate = 'AttributeRepoCreate',
  AttributeRepoViewEdit = 'AttributeRepoViewEdit',

  AssetsDefination = 'AssetsDefination',
  AssetsDefinationCreate = 'AssetsDefinationCreate',
  AssetsDefinationViewEdit = 'AssetsDefinationViewEdit',

  GeoPolicy = 'GeoPolicy',
  GeoPolicyCreate = 'GeoPolicyCreate',
  GeoPolicyViewEdit = 'GeoPolicyViewEdit',

  Masters = 'Masters',
  MasterTypeList = 'MasterTypeList',
  MastersCreate = 'MastersCreate',
  MastersEdit = 'MastersEdit',
  MastersView = 'MastersView',
  ManageTips = 'ManageTips',

  Partners = 'Partners',
  PartnersCreate = 'PartnersCreate',
  PartnersViewEdit = 'PartnersViewEdit',

  AuditLogs = 'AuditLogs',
  AuditLogsListing = 'AuditLogsListing',
  AuditLogsMaster = 'AuditLogsMaster',
  AuditLogsMasterDetails = 'AuditLogsMasterDetails',
  AuditLogsContent = 'AuditLogsContent',
  AuditLogsContentDetails = 'AuditLogsContentDetails',
  AuditLogsTranscoding = 'AuditLogsTranscoding',
  AuditLogsTranscodingDetails = 'AuditLogsTranscodingDetails',
  AuditLogsUserAndRole = 'AuditLogsUserAndRole',
  AuditLogsUserAndRoleDetails = 'AuditLogsUserAndRoleDetails',

  Reports = 'Reports',
  BlukUpdate = 'BlukUpdate',
  BlukUpdateAddEdit = 'BlukUpdateAddEdit',
  ReportsDataMigration = 'ReportsDataMigration',
  ManageFiles = 'ManageFiles',
  ManageFilesAddEdit = 'ManageFilesAddEdit',

  NotFound = 'NotFound',
  ErrorPage = 'ErrorPage',
  AccessDenied = 'AccessDenied',
  AuthValidate = 'AuthValidate',
  InternalServerError = 'InternalServerError',
}

// Define a type for page URLs
export type PageURLs = Record<PageNames, string>

// Define a type for query parameters
export type QueryParameters = Record<string, string>

// Define a type for route parameters
export type RouteParameters = Record<string, string>

// Define your page URLs
export const PAGE_URL: PageURLs = {
  [PageNames.Root]: '/',

  [PageNames.Login]: '/login',
  [PageNames.AuthValidate]: '/api/v1/service/sso/action/login',

  [PageNames.Home]: '/home',

  [PageNames.ManageContent]: '/manage-content',
  [PageNames.ManageContentListing]: '/manage-content/:contentCatagory',
  [PageNames.Shorts]: '/manage-content/shorts',
  [PageNames.Movies]: '/manage-content/movies',
  [PageNames.Sports]: '/manage-content/sports',
  [PageNames.TvShows]: '/manage-content/tv_shows',
  [PageNames.Originals]: '/manage-content/originals',
  [PageNames.NonVODAssest]: '/manage-content/non_vod_assets',
  [PageNames.IndependentVODAssest]: '/manage-content/independent_vod_assets',

  [PageNames.ManageContentAssetCreate]: '/asset/create',
  [PageNames.ManageContentAssetView]:
    '/asset/view/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
  [PageNames.ManageContentAssetEdit]:
    '/asset/update/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
  [PageNames.ManageContentAssetAuditRails]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/audit-trails/:contentIdNumber/:blitzId',
  [PageNames.ManageContentAssetAuditRailsView]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/audit-trails/:contentIdNumber/view/:auditId',
  [PageNames.ManageContentAssetAssociatedAssets]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/associated-assets/:assetId',

  [PageNames.Role]: '/manage-role',
  [PageNames.RoleCreate]: '/manage-role/create',
  // [PageNames.RoleEdit]: '/manage-role/edit/:roleId',
  // [PageNames.RoleView]: '/manage-role/view/:roleId',
  [PageNames.RoleViewEdit]: '/manage-role/:rolePageType/:roleId',
  [PageNames.RoleAssociatedUsers]: '/manage-role/:rolePageType/:roleId/users',

  [PageNames.User]: '/manage-user',
  // [PageNames.UserCreate]: '/manage-user/create',
  // [PageNames.UserEdit]: '/manage-user/edit/:userId',
  // [PageNames.UserView]: '/manage-user/view/:userId',
  [PageNames.UserCreate]: '/manage-user/:id',
  [PageNames.UserEdit]: '/manage-user/:userId',
  [PageNames.UserView]: '/manage-user/:id/:userId',

  [PageNames.MetaMaster]: '/metamaster',

  [PageNames.AttributeRepo]: '/metamaster/attribute-repo',
  // [PageNames.AttributeRepoCreate]: '/metamaster/attribute-repo/create',
  // [PageNames.AttributeRepoEdit]: '/metamaster/attribute-repo/edit/:attributeRepoId',
  // [PageNames.AttributeRepoView]: '/metamaster/attribute-repo/view/:attributeRepoId',
  [PageNames.AttributeRepoCreate]: '/metamaster/attribute-repo/:attributePageType',
  [PageNames.AttributeRepoViewEdit]: '/metamaster/attribute-repo/:attributePageType/:attributeId',

  [PageNames.AssetsDefination]: '/metamaster/assets-defination',
  // [PageNames.AssetsDefinationCreate]: '/metamaster/assets-defination/create',
  // [PageNames.AssetsDefinationEdit]: '/metamaster/assets-defination/edit/:assetsDefinationId',
  // [PageNames.AssetsDefinationView]: '/metamaster/assets-defination/view/:assetsDefinationId',
  [PageNames.AssetsDefinationCreate]: '/metamaster/assets-defination/:id',
  [PageNames.AssetsDefinationViewEdit]: '/metamaster/assets-defination/:id/:assetId',

  [PageNames.GeoPolicy]: '/metamaster/geo-policy',

  // [PageNames.GeoPolicyCreate]: '/metamaster/geo-policy/create',
  // [PageNames.GeoPolicyEdit]: '/metamaster/geo-policy/edit/:geoPolicyId',
  // [PageNames.GeoPolicyView]: '/metamaster/geo-policy/view/:geoPolicyId',
  [PageNames.GeoPolicyCreate]: '/metamaster/geo-policy/:geoPolicyPageType',
  [PageNames.GeoPolicyViewEdit]: '/metamaster/geo-policy/:geoPolicyPageType/:geoPolicyId',

  [PageNames.Partners]: '/partners',
  [PageNames.PartnersCreate]: '/partners/:partnerPageType',
  [PageNames.PartnersViewEdit]: '/partners/:partnerPageType/:partnerId',

  [PageNames.Masters]: '/metamaster/masters',
  [PageNames.MasterTypeList]: '/metamaster/masters/:masterType',

  [PageNames.ManageTips]: '/metamaster/manage-tips',

  [PageNames.MastersCreate]: '/metamaster/masters/:masterName/create',
  [PageNames.MastersEdit]: '/metamaster/masters/:masterName/edit/:masterId',
  [PageNames.MastersView]: '/metamaster/masters/:masterName/view/:masterId',

  [PageNames.AuditLogs]: '/audit-logs',
  [PageNames.AuditLogsListing]: '/audit-logs/:auditLogsCategory',
  [PageNames.AuditLogsMaster]: '/audit-logs/master-logs',
  [PageNames.AuditLogsMasterDetails]: '/audit-logs/master-logs/view/:id',
  [PageNames.AuditLogsContent]: '/audit-logs/content-logs',
  [PageNames.AuditLogsContentDetails]: '/audit-logs/content-logs/view/:id',
  [PageNames.AuditLogsTranscoding]: '/audit-logs/transcoding-logs',
  [PageNames.AuditLogsTranscodingDetails]: '/audit-logs/transcoding-logs/view/:id',
  [PageNames.AuditLogsUserAndRole]: '/audit-logs/user-and-role-logs',
  [PageNames.AuditLogsUserAndRoleDetails]: '/audit-logs/user-and-role-logs/view/:id',

  [PageNames.Reports]: '/reports',
  [PageNames.ReportsDataMigration]: '/reports/data-migration-reports',

  [PageNames.BlukUpdate]: '/bulk-update',
  [PageNames.BlukUpdateAddEdit]: '/bulk-update/:pageType/:id',

  [PageNames.ManageFiles]: '/manage-files',
  [PageNames.ManageFilesAddEdit]: '/manage-files/:pageType/:id',

  [PageNames.ErrorPage]: '/error',
  [PageNames.NotFound]: '/not-found',
  [PageNames.AccessDenied]: '/access-denied',
  [PageNames.InternalServerError]: '/internal-server-error',
}

export const MANAGE_CONTENT_URLS = {
  SHORTS: PageNames.Shorts,
  MOVIES: PageNames.Movies,
  SPORTS: PageNames.Sports,
  TV_SHOW: PageNames.TvShows,
  ORIGINALS: PageNames.Originals,
  NON_VOD_ASSETS: PageNames.NonVODAssest,
  INDEPENDENT_VOD_ASSETS: PageNames.IndependentVODAssest,
}

// Define a function to get page URLs
export const getPageURL = (
  pageName: PageNames,
  options: {
    query?: QueryParameters
    params?: RouteParameters
  },
): string => {
  const replaceParams = (template: string, params: any = {}) => {
    let result = template
    Object.entries(params).forEach(([key, value]: any) => {
      result = result.replace(`:${key}`, value)
    })
    return result
  }

  const { query, params } = options
  const queryParams = query ? `?${new URLSearchParams(query).toString()}` : ''

  return `${replaceParams(PAGE_URL[pageName], params)}${queryParams}`
}
