/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnyCnameRecord } from 'dns'
import CONSTANTS from '../../Constants'
import {
  ASSET_TYPE_DEFINITION_CRUD,
  ATTRIBUTE_REPOSITORY_CRUD,
  CATEGORY_APIS,
  SYSTEM_CONFIG_APIS,
} from '../../Constants/ApiDefinations'
import { customSort } from '../../Helpers/Workflow'
import { viewRoleAPIDefinationModal } from '../../Models'
import {
  AddAssetTypeAPIModal,
  AddAssetTypeModal,
  AssetDefinitionState,
  attributeListResponseModal,
  categoryListApiDefinitionModal,
  categoryListResponseModal,
  StatusChangeAPIDefinitionModal,
  StatusChangeAPIPayloadModal,
} from '../../Models/AssetDefinitionModal'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: AssetDefinitionState = {
  categories: [],
  attributes: [],
  allowedParents: [],
  contentCategory: [],
  assetCategory: [],
  inheritanceRule: [],
  scope: [],
  standalonePublished: [],
  dataSource: [],
  assetDefinition: {
    tollGate: {},
    attributes: [],
  },
  assetDefinitionViewData: {},
  assetValidationsList: [],
  assetDefinitionActionList: [],
  updatedTollgate: {},
  otherMetaCategories: [],
  validationOnAttribute: {
    categoryKey: '',
    attributeId: '',
  },
}

const assetDefinitionSlice = createSlice({
  name: 'assetDefinition',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<categoryListResponseModal>) => {
      const { data } = action.payload
      state.categories = []
      state.assetDefinition.attributes = []
      state.categories = customSort(
        data.map((item) => ({ ...item, metaAttributes: [] })),
        CONSTANTS.META_CATEGORIES_SORTING.ORDER,
        CONSTANTS.META_CATEGORIES_SORTING.KEY2,
      )
      state.assetDefinition = {
        ...state.assetDefinition,
        attributes: state.categories.map((item) => ({
          metaCategory: item.categoryKey,
          metaAttributes: state?.assetDefinition?.attributes?.length
            ? state.assetDefinition.attributes
            : [],
        })),
        tollGate: {},
      }
    },
    setTollgatesListInAsset: (state, action: PayloadAction<any>) => {
      if (action?.payload?.length) {
        for (const i of action.payload) {
          const a = state.assetDefinition
          const k = { ...a, tollGate: { ...a.tollGate, [i.categoryKey]: false } }
          state.assetDefinition = k
        }
      }
    },
    setAttributes: (state, action: PayloadAction<attributeListResponseModal>) => {
      const { data } = action.payload
      state.attributes = data.records
    },
    setAssetDefinition: (state, action: PayloadAction<any>) => {
      const { data } = action.payload

      if (data) {
        state.assetDefinition = data
      }
    },
    setAssetDefinitionViewData: (state, action: PayloadAction<any>) => {
      const { data } = action.payload
      if (data) {
        state.assetDefinitionViewData = data
      }
    },
    updateAttributesAsset: (state, action: PayloadAction<{ categoryKey: any; newValue: any }>) => {
      const { categoryKey, newValue } = action.payload
      const index = state.assetDefinition.attributes.findIndex(
        (item: any) => item.metaCategory === categoryKey,
      )
      state.assetDefinition.attributes[index].metaAttributes = newValue
    },
    updateAttributesAssetValidation: (
      state,
      action: PayloadAction<{
        categoryKeyName: any
        attributeKeyName: any
        validations: any
        inheritanceRuleName: string
        isRequired: boolean
        defaultValue: string
      }>,
    ) => {
      const {
        categoryKeyName = '',
        attributeKeyName = '',
        validations = {},
        inheritanceRuleName = '',
        isRequired,
        defaultValue,
      } = action.payload
      const index = state.assetDefinition.attributes.findIndex(
        (item: any) => item.metaCategory === categoryKeyName,
      )
      const metaAttributeIndex = state.assetDefinition.attributes[index].metaAttributes.findIndex(
        (item: any) => item.fieldName === attributeKeyName,
      )
      state.assetDefinition.attributes[index].metaAttributes[metaAttributeIndex].validations =
        validations
      state.assetDefinition.attributes[index].metaAttributes[metaAttributeIndex].inheritanceRule =
        inheritanceRuleName
      state.assetDefinition.attributes[index].metaAttributes[metaAttributeIndex].isRequired =
        isRequired
      state.assetDefinition.attributes[index].metaAttributes[metaAttributeIndex].defaultValue =
        defaultValue
    },
    setMastersData: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { data, key } = action.payload

      switch (key) {
        case CONSTANTS.MASTERS_KEY_ALLOWED_PARENTS:
          if (data?.data?.records?.length) {
            state.allowedParents = data.data.records.map((item: any) => ({
              key: item._id,
              value: `${item.assetType} (${item.contentCategory})`,
            }))
          } else {
            state.allowedParents = []
          }
          state.allowedParents?.push({
            key: '',
            value: 'None',
          })
          break
        case CONSTANTS.MASTERS_KEY_ASSET_CATEGORY:
          state.assetCategory = data.data
          break
        case CONSTANTS.MASTERS_KEY_CONTENT_CATEGORY:
          state.contentCategory = data.data
          break
        case CONSTANTS.MASTERS_KEY_INHERITANCE_RULE:
          state.inheritanceRule = data.data
          break
        case CONSTANTS.MASTERS_KEY_SCOPE:
          state.scope = data.data
          break
        case CONSTANTS.MASTERS_KEY_STANDALONE_PUBLISHED:
          state.standalonePublished = data.data
          break
        case CONSTANTS.MASTERS_KEY_DATA_SOURCE:
          state.dataSource = data.data
          break
        default:
          return state
      }
    },
    updateTollgateAssetTypet: (state, action: PayloadAction<any>) => {
      state.updatedTollgate = {
        ...state.updatedTollgate,
        ...action.payload,
      }
    },
    clearToolgate: (state, _action: PayloadAction<any>) => {
      state.updatedTollgate = {}
    },
    setInitialData: (state) => {
      state.assetDefinition.attributes = []
    },
    deleteAttribute: (state, action: PayloadAction<{ categoryKey: string; index: number }>) => {
      const { categoryKey, index } = action.payload

      const idx = state.assetDefinition.attributes.findIndex(
        (item: any) => item.metaCategory === categoryKey,
      )

      if (
        state.assetDefinition.attributes[idx].metaAttributes?.[index].fieldType === 'COMPLEXTYPE'
      ) {
        const k =
          state.assetDefinition?.attributes?.[idx]?.metaAttributes?.[index]?.attributeRef || []
        for (const i of k) {
          const idIndex = state.assetDefinition.attributes[idx].metaAttributes.findIndex(
            (item: any) => item._id === i._id,
          )
          state.assetDefinition.attributes[idx].metaAttributes.splice(idIndex, 1)
        }
        state.assetDefinition.attributes[idx].metaAttributes.splice(index, 1)
      } else {
        state.assetDefinition.attributes[idx].metaAttributes.splice(index, 1)
      }
    },
    updateAttributeValidation: (
      state,
      action: PayloadAction<{ categoryKey: string; index: number; data: AnyCnameRecord }>,
    ) => {
      const { categoryKey, index, data } = action.payload

      const idx = state.assetDefinition.attributes.findIndex(
        (item: any) => item.metaCategory === categoryKey,
      )
      state.assetDefinition.attributes[idx].metaAttributes[index].validation = data
    },
    setAssetValidationsList: (state, action: PayloadAction<any>) => {
      const { data } = action.payload
      state.assetValidationsList = data
    },
    setAssetDefinitionActionList: (state, action: PayloadAction<any[]>) => {
      state.assetDefinitionActionList = action.payload
    },
    setValidationOnAttribute: (
      state,
      action: PayloadAction<{ categoryKey: string; attributeId: number }>,
    ) => {
      const { categoryKey, attributeId } = action.payload
      state.validationOnAttribute.categoryKey = categoryKey
      state.validationOnAttribute.attributeId = attributeId
    },
    clearValidationOnAttribute: (state) => {
      state.validationOnAttribute = {
        categoryKey: '',
        attributeId: '',
      }
    },
    clearAssetDefinitionStore: (state: any) => {
      state.categories = []
      state.attributes = []
      state.allowedParents = []
      state.contentCategory = []
      state.assetCategory = []
      state.inheritanceRule = []
      state.scope = []
      state.standalonePublished = []
      state.dataSource = []
      state.assetDefinition = {
        tollGate: {},
        attributes: [],
      }
      state.assetDefinitionViewData = {}
      state.assetValidationsList = []
      state.assetDefinitionActionList = []
      state.updatedTollgate = {}
      state.validationOnAttribute = {
        categoryKey: '',
        attributeId: '',
      }
    },
    setOtherMetaCategories: (state: any, action: PayloadAction<any[]>) => {
      const { data = [] } = action.payload as any
      state.otherMetaCategories = data
    },
    clearAssetDefinitionData: (state) => {
      state.assetDefinition = {
        tollGate: {},
        attributes: [],
      }
    },
  },
})

export const {
  setCategories,
  setTollgatesListInAsset,
  setAttributes,
  setAssetDefinition,
  setAssetDefinitionViewData,
  updateAttributesAsset,
  updateAttributesAssetValidation,
  updateTollgateAssetTypet,
  setMastersData,
  deleteAttribute,
  setInitialData,
  updateAttributeValidation,
  setAssetValidationsList,
  setAssetDefinitionActionList,
  clearAssetDefinitionStore,
  setOtherMetaCategories,
  setValidationOnAttribute,
  clearValidationOnAttribute,
  clearAssetDefinitionData,
  clearToolgate,
} = assetDefinitionSlice.actions

export const categoryListService = createAsyncThunk(
  'categories/categoryList',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: categoryListApiDefinitionModal = CATEGORY_APIS.LIST_CATEGORIES()
    const data = await mainApiService(requestedData)
    dispatch(setCategories(data as categoryListResponseModal))
    dispatch(setLoader(false))
  },
)

export const viewAssetDefinition = createAsyncThunk(
  'asset/viewAsset',
  async (assetId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal =
      ASSET_TYPE_DEFINITION_CRUD.VIEW_ASSET_TYPE(assetId)
    const data = await mainApiService(requestedData)
    dispatch(setAssetDefinition(data as any))
    dispatch(setAssetDefinitionViewData(data as any))
    dispatch(setLoader(false))
    return data
  },
)

export const viewAssetDefinitionByAssetType = createAsyncThunk(
  'asset/viewAsset',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal =
      ASSET_TYPE_DEFINITION_CRUD.VIEW_ASSET_TYPE_BY_ASSET_TYPE(payload)
    const data: any = await mainApiService(requestedData)
    const res = data?.data?.records?.[0] ?? data
    dispatch(setAssetDefinition({ data: res } as any))
    dispatch(setLoader(false))
  },
)

export const attrbuteListService = createAsyncThunk(
  'attribute/attributeList',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = ATTRIBUTE_REPOSITORY_CRUD.LIST_ATTRIBUTE_REPO(
      'page=1&limit=1000&isActive=1',
    )
    const data = await mainApiService(requestedData)
    dispatch(setAttributes(data as attributeListResponseModal))
    dispatch(setLoader(false))
    return data
  },
)

export const addAssetType = createAsyncThunk(
  'assetTypeDefinition/addAssetType',
  async (payload: AddAssetTypeModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: AddAssetTypeAPIModal =
      ASSET_TYPE_DEFINITION_CRUD.ADD_ASSET_TYPE_DEFINITION(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const updateAssetTypeService = createAsyncThunk(
  'assetTypeDefinition/updateAssetType',
  async (payload: AddAssetTypeModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: AddAssetTypeAPIModal =
      ASSET_TYPE_DEFINITION_CRUD.UPDATE_ASSET_TYPE_DEFINITION(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getMastersData = createAsyncThunk(
  'assetTypeDefinition/getMastersData',
  async (key: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = SYSTEM_CONFIG_APIS.LIST_MASTERS(key)
    const data = await mainApiService(requestedData)
    dispatch(setMastersData({ key, data }))
    dispatch(setLoader(false))
    return data
  },
)

export const getAllowedParentsData = createAsyncThunk(
  'assetTypeDefinition/getAllowedParentsData',
  async (key: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal =
      ASSET_TYPE_DEFINITION_CRUD.LIST_ASSET_TYPE('assetCategory=parent')
    const data = await mainApiService(requestedData)

    dispatch(setMastersData({ key: CONSTANTS.MASTERS_KEY_ALLOWED_PARENTS, data }))
    dispatch(setLoader(false))
    return data
  },
)

export const getDataSource = createAsyncThunk(
  'assetTypeDefinition/getDataSource',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = ASSET_TYPE_DEFINITION_CRUD.LIST_DATA_SOURCE()
    const data = await mainApiService(requestedData)

    dispatch(setMastersData({ key: CONSTANTS.MASTERS_KEY_DATA_SOURCE, data }))
    dispatch(setLoader(false))
    return data
  },
)

export const getAssetValidationList = createAsyncThunk(
  'assetTypeDefinition/getAssetValidationList',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_TYPE_DEFINITION_CRUD.LIST_ASSET_VALIDATION()
    const data = await mainApiService(requestedData)
    dispatch(setAssetValidationsList(data as any))
    dispatch(setLoader(false))
    return data
  },
)

export const setAssetStatusService = createAsyncThunk(
  'assetTypeDefinition/setAssetStatus',
  async (payload: StatusChangeAPIPayloadModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: StatusChangeAPIDefinitionModal =
      ASSET_TYPE_DEFINITION_CRUD.UPDATE_ASSET_TYPE_DEFINITION_STATUS(payload)
    const data = await mainApiService(requestedData)

    dispatch(setLoader(false))
    return data
  },
)

export const getAssetContentCategoryData = createAsyncThunk(
  'assetTypeDefinition/getAssetContentCategoryData',
  async (data: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_TYPE_DEFINITION_CRUD.LIST_ASSET_TYPE(data)
    try {
      const response = await mainApiService(requestedData)
      const records = response?.data?.records || []
      dispatch(setAssetDefinitionActionList(records))
      dispatch(setLoader(false))
      return records
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
  },
)

export const getOtherMetaCategories = createAsyncThunk(
  'assetTypeDefinition/getOtherMetaCategories',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = SYSTEM_CONFIG_APIS.LIST_MASTERS('TOLLGATES')
    const data: any = await mainApiService(requestedData)
    dispatch(setOtherMetaCategories(data) as any)
    dispatch(setLoader(false))
    return data
  },
)

const assetDefinitionSelector = (state: { assetDefinition: AssetDefinitionState }) =>
  state.assetDefinition

export const selectCategories = createSelector(assetDefinitionSelector, (state) => state.categories)
export const selectAttributes = createSelector(assetDefinitionSelector, (state) => state.attributes)
export const selectAsset = createSelector(assetDefinitionSelector, (state) => state.assetDefinition)
export const selectAllowedParents = createSelector(
  assetDefinitionSelector,
  (state) => state.allowedParents,
)
export const selectContentCategory = createSelector(
  assetDefinitionSelector,
  (state) => state.contentCategory,
)
export const selectAssetCategory = createSelector(
  assetDefinitionSelector,
  (state) => state.assetCategory,
)

export const selectStandalonePublished = createSelector(
  assetDefinitionSelector,
  (state) => state.standalonePublished,
)
export const selectScope = createSelector(assetDefinitionSelector, (state) => state.scope)
export const selectDataSource = createSelector(assetDefinitionSelector, (state) => state.dataSource)
export const selectInheritanceRule = createSelector(
  assetDefinitionSelector,
  (state) => state.inheritanceRule,
)
export const assetValidationsList = createSelector(
  assetDefinitionSelector,
  (state) => state.assetValidationsList,
)

export const selectAssetDefinitionActionList = createSelector(
  assetDefinitionSelector,
  (state) => state.assetDefinitionActionList,
)

export const selectUpdatedTollgate = createSelector(
  assetDefinitionSelector,
  (state) => state.updatedTollgate,
)

export const selectAssetDefinitionViewData = createSelector(
  assetDefinitionSelector,
  (state) => state.assetDefinitionViewData,
)

export const selectOtherMetaCategories = createSelector(
  assetDefinitionSelector,
  (state) => state.otherMetaCategories,
)

export const selectValidationOnAttribute = createSelector(
  assetDefinitionSelector,
  (state) => state.validationOnAttribute,
)

export default assetDefinitionSlice
