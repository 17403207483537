import * as yup from 'yup'

export const VALIDATIONS = {
  REQUIRED: 'This field is required.',
  EMAIL: 'Please enter a valid email',
  EMAIL_EXIST: 'Email Id already exist',
  CYBERARKID_EXIST: 'CyberArkId Already exists',
  EMAILID_EXIST: 'Email id already exists in our system',
  ONLY_ALPHABET: 'Only alphabets are allowed',
  ONLY_ALPHANUMERIC: 'Only alpha-numeric are allowed',
  MIN_NEGATIVE_FIELD: 'Only positive values are allowed',
  VALID_URL: 'Please enter a valid URL',
}
export const RegexPatterns = {
  onlyAlphabate: /^[A-Za-z\s]+$/i,
  alphaNumeric: /^[A-Za-z0-9\s]+$/i,
  url: /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,
  daiUrl: /^(https:\/\/|dai:\/\/)/,
}
export const USER_VALIDATION_SCHEMA = yup
  .object({
    userName: yup
      .string()
      .required('User Name is required')
      .matches(/^[A-Za-z0-9\s]+$/, VALIDATIONS.ONLY_ALPHANUMERIC),
    status: yup.string().required('Status is required'),
    email: yup.string().required('Email is required'),
    cyberArkId: yup.string().required('CyberarkId is required'),
    roleName: yup.string().required('Role name is required'),
  })
  .required()
