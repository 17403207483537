import {
  AuditLogsIcon,
  BulkUpdateIcon,
  HomeIcon,
  ManageContentIcon,
  ManageFIlesIcon,
  MetaMasterIcon,
  PartnerActiveIcon,
  PartnerIcon,
  ReportIcon,
  RoleManagementIcon,
  UserManagementIcon,
} from '../../../Components/Shared/MUI-Drawer/MUI-Drawer.svgIcons'
import { PAGE_NAME } from '../../PagesRoutes'
import { PAGE_URL, PageNames } from '../../PagesRoutes/PageURLs'
import { BLITZ_MODULE } from '../../Roles'

export const DrawerData = [
  {
    lable: PAGE_NAME.HomeLabel,
    icon: <HomeIcon />,
    activeIcon: <HomeIcon color='#402080' />,
    id: 1,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Home],
    child: [],
  },
  {
    lable: PAGE_NAME.ManageContentLabel,
    icon: <ManageContentIcon />,
    activeIcon: <ManageContentIcon color='#402080' />,
    id: 2,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.ManageContent],
    role: BLITZ_MODULE.MANAGE_CONTENT.key,
    child: [],
    /**
    child: [
      {
        lable: PAGE_NAME.TVShows,
        icon: '',
        activeIcon: '',
        id: 2.1,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.TvShows],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.Originals,
        icon: '',
        activeIcon: '',
        id: 2.2,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Originals],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.Movies,
        icon: '',
        activeIcon: '',
        id: 2.3,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Movies],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.Sports,
        icon: '',
        activeIcon: '',
        id: 2.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Sports],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.Shorts,
        icon: '',
        activeIcon: '',
        id: 2.5,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Shorts],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.IndependentVODAssest,
        icon: '',
        activeIcon: '',
        id: 2.6,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.IndependentVODAssest],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
      {
        lable: PAGE_NAME.NonVODAssest,
        icon: '',
        activeIcon: '',
        id: 2.7,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.NonVODAssest],
        role: BLITZ_MODULE.MANAGE_CONTENT.key,
        child: [],
      },
    ],
    */
  },
  {
    lable: PAGE_NAME.RoleManagementLabel,
    icon: <RoleManagementIcon />,
    activeIcon: <RoleManagementIcon color='#402080' />,
    id: 3,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Role],
    child: [],
    role: BLITZ_MODULE.ROLE_AND_USER_MANAGEMENT.key,
  },
  {
    lable: PAGE_NAME.UserManagementLabel,
    icon: <UserManagementIcon />,
    activeIcon: <UserManagementIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.User],
    child: [],
    role: BLITZ_MODULE.ROLE_AND_USER_MANAGEMENT.key,
  },
  {
    lable: PAGE_NAME.MetaMasterLabel,
    icon: <MetaMasterIcon />,
    activeIcon: <MetaMasterIcon color='#402080' />,
    id: 5,
    navigate: false,
    disabled: false,
    path: PAGE_URL[PageNames.MetaMaster],
    role: BLITZ_MODULE.META_MASTER.key,
    child: [
      {
        lable: PAGE_NAME.AttributeRepoLabel,
        icon: '',
        activeIcon: '',
        id: 5.1,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AttributeRepo],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.AssetDefinitionLabel,
        icon: '',
        activeIcon: '',
        id: 5.2,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AssetsDefination],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.GeoPolicyManagementLabel,
        icon: '',
        activeIcon: '',
        id: 5.3,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.GeoPolicy],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.MastersLabel,
        icon: '',
        activeIcon: '',
        id: 5.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Masters],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.ManageTipsLabel,
        icon: '',
        activeIcon: '',
        id: 5.5,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.ManageTips],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
    ],
  },
  {
    lable: PAGE_NAME.AuditLogsLabel,
    icon: <AuditLogsIcon />,
    activeIcon: <AuditLogsIcon color='#402080' />,
    id: 6,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.AuditLogs],
    role: BLITZ_MODULE.AUDIT_TRAILS.key,
    child: [],
    /**
    child: [
      {
        lable: PAGE_NAME.ContentLogsLabel,
        icon: '',
        activeIcon: '',
        id: 5.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AuditLogsContent],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.MastersLogsLabel,
        icon: '',
        activeIcon: '',
        id: 5.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AuditLogsMaster],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.UserAndRoleLogsLabel,
        icon: '',
        activeIcon: '',
        id: 5.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AuditLogsUserAndRole],
        role: BLITZ_MODULE.META_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.TranscodingLogsLabel,
        icon: '',
        activeIcon: '',
        id: 5.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AuditLogsTranscoding],
        role: BLITZ_MODULE.AUDIT_TRAILS.key,
        child: [],
      },
    ],
    */
  },
  {
    lable: PAGE_NAME.partnerLabel,
    icon: <PartnerIcon />,
    activeIcon: <PartnerActiveIcon />,
    id: 7,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Partners],
    child: [],
    role: BLITZ_MODULE.PARTNERS.key,
  },

  {
    lable: PAGE_NAME.ReportsLabel,
    icon: <ReportIcon />,
    activeIcon: <ReportIcon color='#402080' />,
    id: 8,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Reports],
    child: [],
    role: BLITZ_MODULE.REPORTING.key,
  },
  {
    lable: PAGE_NAME.BulkUpdateLabel,
    icon: <BulkUpdateIcon />,
    activeIcon: <BulkUpdateIcon color='#402080' />,
    id: 9,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.BlukUpdate],
    child: [],
    role: BLITZ_MODULE.BULKUPDATES.key,
  },
  {
    lable: PAGE_NAME.ManageFileLabel,
    icon: <ManageFIlesIcon />,
    activeIcon: <ManageFIlesIcon color='#402080' />,
    id: 10,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.ManageFiles],
    child: [],
    role: BLITZ_MODULE.MANAGE_FILE.key,
  },
]
