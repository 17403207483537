import { CONSTANTS } from '../Constants/Constant'
import { getLocalStorage } from './Storage'

export const PERMISSIONS = {
  ROLE_USER: 'BLITZ_MODULE.ROLE_AND_USER_MANAGEMENT',
  AUDIT_TRAILS: 'BLITZ_MODULE.AUDIT_TRAILS',
  REPORTING: 'BLITZ_MODULE.REPORTING',
  MANAGE_CONTENT: 'BLITZ_MODULE.MANAGE_CONTENT',
  META_MASTER: 'BLITZ_MODULE.META_MASTER',
  BULK_UPDATE: 'BLITZ_MODULE.BULK_UPDATE',
  INBOUND_SERVICE: 'BLITZ_MODULE.INBOUND_SERVICE',
  META_CAT_DESCRIPTIVE_METADATA: 'META_CATEGORIES.DESCRIPTIVE_METADATA',
  META_CAT_RIGHTS_AND_AVAILABILITY: 'META_CATEGORIES.RIGHTS_AND_AVAILABILITY',
  META_CAT_SEO_METADATA: 'META_CATEGORIES.SEO_METADATA',
  META_CAT_ADVERTISEMENT_METADATA: 'META_CATEGORIES.ADVERTISEMENT_METADATA',
  META_CAT_BUSINESS_RULES: 'META_CATEGORIES.BUSINESS_RULES',
  META_CAT_TECHNICAL_METADATA: 'META_CATEGORIES.TECHNICAL_METADATA',
  META_CAT_CONTENT_MEDIA_IMAGE: 'META_CATEGORIES.CONTENT_MEDIA_IMAGE',
  META_CAT_CONTENT_MEDIA_VIDEO: 'META_CATEGORIES.CONTENT_MEDIA_VIDEO',
  META_CAT_SPORTS_METADATA: 'META_CATEGORIES.SPORTS_METADATA',
  META_CAT_MISCELLANEOUS_METADATA: 'META_CATEGORIES.MISCELLANEOUS_METADATA',
  META_CAT_CHECKLIST: 'META_CATEGORIES.CHECKLIST',

  TOLL_DESCRIPTIVE_METADATA: 'TOLLGATES.DESCRIPTIVE_METADATA',
  TOLL_RIGHTS_AND_AVAILABILITY: 'TOLLGATES.RIGHTS_AND_AVAILABILITY',
  TOLL_SEO_METADATA: 'TOLLGATES.SEO_METADATA',
  TOLL_ADVERTISEMENT_METADATA: 'TOLLGATES.ADVERTISEMENT_METADATA',
  TOLL_BUSINESS_RULES: 'TOLLGATES.BUSINESS_RULES',
  TOLL_TECHNICAL_METADATA: 'TOLLGATES.TECHNICAL_METADATA',
  TOLL_CONTENT_MEDIA_IMAGE: 'TOLLGATES.CONTENT_MEDIA_IMAGE',
  TOLL_SPORTS_METADATA: 'TOLLGATES.SPORTS_METADATA',
  TOLL_MISCELLANEOUS_METADATA: 'TOLLGATES.MISCELLANEOUS_METADATA',
  TOLL_CHECKLIST: 'TOLLGATES.CHECKLIST',
  TOLL_QC_PROCESS: 'TOLLGATES.QC_PROCESS',
  TOLL_PUBLISHING: 'TOLLGATES.PUBLISHING',
  TOLL_RIGHTS_EXPIRED: 'TOLLGATES.RIGHTS_EXPIRED',
  TOLL_DEPUBLISH: 'TOLLGATES.DEPUBLISH',

  META_MASTER_ATTRIBUTE_REPO: 'META_MASTER.ATTRIBUTES_REPO',
  META_MASTER_ASSET_DEFINITION: 'META_MASTER.ASSET_DEFINITION',
  META_MASTER_GEO_POLICY: 'META_MASTER.GEO_POLICY',
  META_MASTER_MASTERS: 'META_MASTER.MASTERS',
  META_MASTER_MANAGE_TIPS: 'META_MASTER.MANAGE_TIPS',
  PARTNER_MANAGEMENT: 'PARTNER_MANAGEMENT',
  MANAGE_FILE: 'BLITZ_MODULE.MANAGE_FILE',
}

export const PERMISSION_TYPE = {
  CAN_ACCESS: 'canAccess',
  CAN_READ: 'canRead',
  CAN_WRITE: 'canWrite',
  CAN_SKIP_VALIDATION: 'canSkipValidation',
  CAN_STATE_TRANSITION: 'canStateTransition',
}

export const checkPermission = (permission: string, permissionTypes: string[]): boolean => {
  const permissions = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA) as any

  if (permission && permissions && permissions.length) {
    const [moduleKey, subModuleKey] = permission.split('.')

    const module = permissions.find((item: any) => item.key === moduleKey)

    if (module) {
      const submodulePermissions = module.submodules || []
      const submodule = submodulePermissions.find((sub: any) => sub.key === subModuleKey)

      if (submodule && submodule.permission) {
        return permissionTypes.every((type) => submodule.permission[type])
      }
    }
  }

  return false
}

export const checkAnyMetaWritePermission = () => {
  const permissions = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA) as any
  if (permissions && permissions.length) {
    const module = permissions.find((item: any) => item.key === 'META_CATEGORIES')
    if (module) {
      const isPermisson = (module.submodules || []).find(
        (item: any) => item.permission[PERMISSION_TYPE.CAN_WRITE],
      )
      if (isPermisson) {
        return true
      }
    }
  }
  return false
}
