import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PartnersEditor } from '../../Components/Partners'
import CONSTANTS, { PAGES_ROUTES } from '../../Constants'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'

const CreatePartners: React.FC = () => {
  const navigate = useNavigate()
  const { CREATE } = CONSTANTS.PAGE_TYPE
  const dispatch = useAppDispatch()
  const params = useParams()
  let pageType: any = params?.partnerPageType
  if (!params?.partnerId) {
    pageType = CREATE
  }

  useEffect(() => {
    const canAccess = checkPermission(PERMISSIONS.INBOUND_SERVICE, [PERMISSION_TYPE.CAN_ACCESS])
    if (!canAccess) {
      dispatch(showAlert([true, 'No Permission', 'error']))
      navigate(PAGES_ROUTES.AccessDenied)
    }
  }, [])

  return <PartnersEditor id={pageType} partnerId={params?.partnerId} />
}

export default CreatePartners
