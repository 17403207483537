import { CSVLink } from 'react-csv'
import CONSTANTS from '../../Constants'
import { showAlert } from '../../Store/Slice/alert.slice'
import Style from '../BulkUpdate/BulkupdateStyle.module.scss'
import { DownloadIcon } from '../Icons/PlayListPlay'
import MUITooltip from '../Shared/MuiTooltipComponent'

const getJobStatus = (status: string) => {
  return (
    <div className={`${Style['bulk-update-status']} ${Style[status]}`}>
      {manageFileStatus(status)}
    </div>
  )
}
export default getJobStatus

export const manageFileStatus = (status: string) => {
  if (status === 'IN_PROGRESS') {
    return 'In Progress'
  } else if (status === 'COMPLETED') {
    return 'Completed'
  } else if (status === 'SCHEDULED') {
    return 'Scheduled'
  } else {
    return status
  }
}

export const headerCSV: any = [
  { label: 'File Name', key: 'fileName' },
  { label: 'Result', key: 'result' },
  { label: 'URL', key: 'url' },
]

export const getFileName = (fileName: string) =>
  fileName?.split('/')[fileName?.split('/').length - 1]

export const getCSVData = (manageFileDetails: any) => {
  if (manageFileDetails?.fileNames?.length) {
    return manageFileDetails?.fileNames?.map((fileName: string) => {
      return {
        fileName: getFileName(fileName),
        result: manageFileDetails?.status === 'COMPLETED' ? 'Success' : 'Fail',
        url: `${CONSTANTS.CLOUDFRONT_BASE_URL}/${fileName}`,
      }
    })
  }
  return []
}

export const manageFilesListingAction = (manageFileDetails: any, dispatch: any) => {
  return (
    <>
      <span className='d-flex allign-items-center mbn-7'>
        <MUITooltip title={'Download Report'} placement='bottom' arrow>
          <div
            data-testid='download-id'
            style={{
              opacity: manageFileDetails.status !== 'COMPLETED' ? 0.5 : 1,
              cursor: manageFileDetails.status !== 'COMPLETED' ? 'auto' : 'pointer',
            }}
          >
            {manageFileDetails.status === 'COMPLETED' ? (
              <CSVLink
                data={getCSVData(manageFileDetails)}
                headers={headerCSV}
                filename={`${manageFileDetails?.jobName}.csv`}
                asyncOnClick={true}
                onClick={(event: any, done: any) => {
                  event.stopPropagation()
                  done(true)
                  dispatch(showAlert([true, 'Report downloaded successfully.', 'success']))
                }}
              >
                <DownloadIcon />
              </CSVLink>
            ) : (
              <span
                onClick={(event: any) => {
                  event.stopPropagation()
                  event.preventDefault()
                }}
              >
                <DownloadIcon />
              </span>
            )}
          </div>
        </MUITooltip>
      </span>
    </>
  )
}
