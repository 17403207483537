import { EditOutlined, ExpandMore } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import CONSTANTS from '../../Constants'
import { getSubstring } from '../../Helpers/Util'
import ErrorSolidIcon from '../Icons/ErrorSolidIcon'
import InfoIcon from '../Icons/InfoIcon'
import ShieldIcon from '../Icons/ShieldIcon'
import SuccessIcon from '../Icons/SuccessIcon'
import ComplexTypeTable from '../Molecule/ComplexTypeTable/ComplextTypeTable'
import { ChipButton, MUIBox } from '../Shared'
import { TooltipText } from '../TooltipText/TooltipText'
interface ViewPageModal {
  pageName?: string
  view?: any
  openDialog?: (arg?: any) => void
  dialogProps?: any
  editButtonRedirectionPath?: string
  viewData?: any[]
  secretButtonFunction?: () => void
  showSecret?: boolean
  showStatus?: any
}
const DetailViewRender: React.FC<ViewPageModal> = ({
  pageName = '',
  view,
  openDialog,
  editButtonRedirectionPath,
  viewData = [],
  showSecret = false,
  showStatus = false,
  secretButtonFunction = () => {
    return
  },
}: ViewPageModal) => {
  const navigate = useNavigate()

  const renderStatus = (value: string) => {
    if (value === 'ACTIVE') {
      return <ChipButton type='success' label='Active' style={{ padding: '0px 8px' }} />
    } else if (value === 'INACTIVE') {
      return <ChipButton type='secondary' label='In Active' style={{ padding: '0px 8px' }} />
    }
    return null
  }

  const renderValue = (item: any) => {
    if (item.label === 'Status') {
      return renderStatus(item?.value)
    }
    if (item?.isTitle) {
      if (typeof item?.value === 'string') {
        return getSubstring(item?.value, 40) || CONSTANTS.DEFAULT_TEXT
      }
      if (Array.isArray(item?.value)) {
        return <TooltipText text={item.value.join(', ')} length={25} />
      }
      return item?.value || CONSTANTS.DEFAULT_TEXT
    }
    return item?.value || CONSTANTS.DEFAULT_TEXT
  }

  return (
    <>
      {view?._id ? (
        <MUIBox>
          <div>
            <div className='heading-6 d-flex text-left rolebox__header-padding'>
              <div className='d-flex align-items-center'>{pageName}</div>
              <div className='ml-auto d-flex'>
                {showStatus && (
                  <div className='mr-07'>
                    {view.emailVerified === false && (
                      <ChipButton
                        type='danger'
                        Icon={ErrorSolidIcon as any}
                        label='Verification Failed'
                      />
                    )}
                    {view.emailVerified === true && (
                      <ChipButton type='darkSuccess' Icon={SuccessIcon as any} label='Verified' />
                    )}
                    {view.emailVerified === null && (
                      <ChipButton type='info' Icon={InfoIcon as any} label='Verification Pending' />
                    )}
                  </div>
                )}

                <div className='mr-07'>
                  <ChipButton
                    type={view?.status === 'ACTIVE' ? 'success' : 'secondary'}
                    dropdown
                    value={view?.status ?? 'ACTIVE'}
                    Icon={ExpandMore}
                    label={view?.status === 'ACTIVE' ? 'Active' : 'In Active'}
                    suffix
                    options={CONSTANTS.ROLE_STATUS}
                    action={openDialog}
                  />
                </div>
                {showSecret && (
                  <div
                    onClick={() => navigate(editButtonRedirectionPath as string)}
                    data-testid='edit__chip-btn'
                  >
                    <ChipButton type='primary' Icon={EditOutlined} label='Edit' />
                  </div>
                )}
                <div
                  className='ml-07'
                  onClick={() => secretButtonFunction()}
                  data-testid='secret__chip-btn'
                >
                  <ChipButton
                    type={'darkPrimary'}
                    Icon={ShieldIcon as any}
                    label={'Issue New Token'}
                  />
                </div>
              </div>
            </div>
            <div className={'hr'}></div>
            {viewData.map((item: any, inx: number) => {
              const keyName = `${item?.label}_${inx}` // NOSONAR
              return (
                <div className='' key={keyName}>
                  <Grid container>
                    <Grid
                      item
                      xs={item?.viewType === 'complex' ? 12 : 6}
                      md={item?.viewType === 'complex' ? 12 : 3}
                    >
                      <div
                        className={`sub-heading-6 view__role-list text-left${
                          item?.viewType === 'complex' ? '' : ' col34'
                        }`}
                      >
                        {item?.label}
                      </div>
                    </Grid>
                    {item?.viewType === 'complex' ? (
                      <Grid item xs={12} md={12} className='view__role-list pt-0'>
                        <MUIBox>
                          <ComplexTypeTable columns={item.columns} tableRows={item.tableRows} />
                        </MUIBox>
                      </Grid>
                    ) : (
                      <Grid item xs={6} md={9}>
                        <div className='sub-heading-6 view__role-list text-left col36 word-wrap'>
                          {renderValue(item)}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )
            })}
          </div>
        </MUIBox>
      ) : (
        <></>
      )}
    </>
  )
}

export default DetailViewRender
