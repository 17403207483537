import { IconButton } from '@mui/material'
import CONSTANTS from '../../../../Constants'
import {
  checkIsPartnerContentItemFailed,
  checkIsPartnerParentsFailed,
} from '../../../../Helpers/CommonListingHelper'
import {
  extractIngestionContentFailureMessage,
  formatIngestionErrors,
  isValidObject,
} from '../../../../Helpers/Util'
import { setcontentIngestionRetrigger } from '../../../../Store/Slice/reports.slice'
import { IngestionReportErrorIcon } from '../../../Icons'
import ReTriggerIcon from '../../../Icons/ReTrigger'
import { processTranscodingErrors } from '../../../ManageContent/ManageAsset/AssetEditor/MediaVideo/MediaVideoUtils'
import ChipButton from '../../ChipButton'
import { useDialog } from '../../Dialog/DialogContext'
import MUITooltip from '../../MuiTooltipComponent'

const commonListingReportActionBtn = (dispatch: any, item: any, label: string, rowsData: any) => {
  const isRetriggerEnable =
    checkIsPartnerContentItemFailed(item) || checkIsPartnerParentsFailed(item, rowsData)
  return (
    <div className='d-flex align-items-center'>
      <div
        onClick={(e: any) => {
          e.stopPropagation()
          e.preventDefault()
          if (!isRetriggerEnable) {
            return
          }
          if (label === 'Re-trigger') {
            dispatch(
              setcontentIngestionRetrigger({
                show: true,
                data: item || {},
              }) as any,
            )
          }
        }}
        className={`${isRetriggerEnable ? 'cursor-pointer' : 'retrigger-disabled'}`}
        data-testid='edit__chip-btn'
        style={{
          width: 'fit-content',
          opacity: isRetriggerEnable ? 1 : 0.5,
        }}
      >
        <MUITooltip title={isRetriggerEnable ? 'Retrigger' : ''} disableInteractive={true}>
          <span>
            <ChipButton
              type='primary'
              style={{ gap: 0, padding: '14px 8px' }}
              label={''}
              iconTooltip={isRetriggerEnable ? 'Retrigger' : ''}
              Icon={ReTriggerIcon as any}
            />
          </span>
        </MUITooltip>
      </div>
      <CommonListingReportActionError item={item} />
    </div>
  )
}

const CommonListingReportActionError = ({ item }: any) => {
  const showDialog = useDialog()

  const showErrorDialog = (data: any) => {
    let errorDetails: any = extractIngestionContentFailureMessage(data)
    if (
      typeof errorDetails !== 'string' &&
      (isValidObject(errorDetails) || Array.isArray(errorDetails))
    ) {
      errorDetails = processTranscodingErrors(errorDetails)
    }
    errorDetails = formatIngestionErrors(errorDetails)

    showDialog('confirm', {
      width: '500px',
      icon: 'DeleteRoleIcon',
      title: 'Error Details',
      description: !!errorDetails && errorDetails !== '""' ? errorDetails : CONSTANTS.DEFAULT_TEXT,
      btn1Label: 'Close',
      onClose: () => undefined,
    })
  }

  let failureReason = ''
  if (
    typeof item?.failureReason === 'string' &&
    ['VALIDATION_FAILED', 'FAILURE'].includes(item?.blitzContentStatus)
  ) {
    try {
      failureReason = JSON.parse(failureReason)
    } catch (error) {
      failureReason = item?.failureReason
    }
  }

  return (
    <div
      className='d-flex align-items-center'
      onClick={(e) => {
        if (!failureReason) {
          e.stopPropagation()
          e.preventDefault()
        }
      }}
    >
      <MUITooltip title={failureReason ? 'Failure Reason' : ''} disableInteractive={true}>
        <IconButton
          data-testid='expand_row'
          size='small'
          style={{
            opacity: failureReason ? 1 : 0.5,
            cursor: failureReason ? 'pointer' : 'default',
            backgroundColor: 'transparent',
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            e.preventDefault()
            if (failureReason) {
              showErrorDialog(item)
            }
          }}
          disabled={!failureReason}
        >
          <span>
            <ChipButton
              type='primary'
              style={{ gap: 0, padding: '14px 4px' }}
              label={''}
              Icon={IngestionReportErrorIcon as any}
            />
          </span>
        </IconButton>
      </MUITooltip>
    </div>
  )
}

export default commonListingReportActionBtn
