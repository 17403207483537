import CONSTANTS from '../Constants'
import { TypeObject } from '../Models'
import { showAlert } from '../Store/Slice/alert.slice'

export const convertFilesArrayToBase64 = (filesData: any) => {
  const promises = Array.from(filesData).map((file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        return resolve({
          fileName: file.name.replaceAll('.', '_'),
          type: file.type,
          base64: reader.result,
          file: file,
        })
      }
      reader.onerror = () => reject(reader.error)
      reader.readAsDataURL(file)
    })
  })

  return Promise.all(promises)
}

export const separateMappedUnmappedItems = (
  data: Record<string, string>[],
  asset: TypeObject,
  prefixData: Record<string, string>[],
) => {
  const imageCategory =
    asset?.attributes?.find(
      (item: { metaCategory: string }) =>
        item?.metaCategory === CONSTANTS.CATEGORIES.CONTENT_MEDIA_IMAGE,
    ) ?? []
  const imageFieldName: any = {}
  for (const i of imageCategory.metaAttributes) {
    imageFieldName[i.fieldName] = true
  }

  const imageInitialsData: Record<string, string>[] = prefixData
  const mappedImages = []
  const unMappedImages = []
  for (const image of data) {
    const existing = imageInitialsData.find(
      (item) =>
        item.initials?.length &&
        item.initials === image.fileName.slice(0, item.initials.length) &&
        imageFieldName[item.fieldName],
    )
    if (existing) {
      mappedImages.push({ ...existing, ...image })
    } else {
      unMappedImages.push(image)
    }
  }

  return { mappedImages, unMappedImages }
}

export const findDuplicates = (arr: TypeObject[]) => {
  const map: any = {}
  arr.forEach((item: any) => {
    if (!map[item.fieldName]) {
      map[item.fieldName] = { imageKey: [], fieldName: item.name }
    }
    map[item.fieldName].imageKey.push(item.file.name)
  })

  const duplicates = Object.values(map).filter((item: any) => item.imageKey.length > 1)
  return duplicates
}

export const findExistingImage = (allImages: any, detailViewState: any) => {
  const existingImagesData: any = []
  const existingAttributes = detailViewState?.data?.ContentMetaCategory?.[0]?.attributes

  if (!existingAttributes) return

  Object.entries(existingAttributes).forEach(([fieldName, attribute]: [string, any]) => {
    const exist = allImages.find(
      (item: any) => item?.fieldName === fieldName && attribute?.find((item: any) => item.imageKey),
    )
    if (exist) {
      const { mimeType } = attribute[0]
      existingImagesData.push({ type: `image/${mimeType}`, imageName: exist?.name })
    }
  })

  return existingImagesData
}

export const prepareBulkImageUploadPayload = ({
  contentId,
  metaCategory,
  detailViewState,
  imageKeysData,
} // asset,
: any) => {
  const imagesData: any = {
    contentId: contentId,
    metaCategory: metaCategory,
    markDoneStatus: detailViewState?.data?.ContentMetaCategory?.[0]?.markDoneStatus,
    isOverWrite: true,
    attributes: {},
  }

  for (const i of imageKeysData) {
    imagesData.attributes[i.fieldName] = [
      {
        title: 'Primary',
        targeting: ['All'],
        segmentId: ['All'],
        url: CONSTANTS.S3_BUCKET_BASE_URL + '/' + i.imageKey,
        imageKey: i.imageKey,
        imageUploadType: 'UPLOAD',
      },
    ]
  }

  return imagesData
}

export const redirect = (
  path: any,
  redirectAnyHow: any,
  navigateFn: any,
  handleClose: any,
  mappedImagesData: any,
  setDialogProps: any,
) => {
  if (redirectAnyHow) {
    navigateFn(path)
    handleClose()
    return
  } else {
    if (mappedImagesData?.length === 0) navigateFn(path)
    else
      setDialogProps({
        heading: CONSTANTS.UNSAVE_CHANGES,
        description: CONSTANTS.UNSAVED_CHANGES_DESCRIPTION_BULK_IMAGES,
        color: 'warning',
        Icon: 'DeleteRoleWarIcon',
        open: true,
        iconColor: 'warning',
        successButtonLabel: 'Ignore',
        cancelButtonLabel: 'Okay',
        handleClose: handleClose,
        handleSuccess: () =>
          redirect(path, true, navigateFn, handleClose, mappedImagesData, setDialogProps),
      })
  }
}

export const handleConfirmationDialog = (
  type: string,
  idx: number,
  currentTab: number,
  setMappedImageData: any,
  setUnMappedImageData: any,
  mappedImageData: any,
  unMappedImageData: any,
  handleClose: any,
  setDialogProps: any,
) => {
  const open = true
  const color = 'error'
  const Icon = 'DeleteRoleIcon'
  const iconColor = 'error'
  const heading = (() => {
    if (type === 'unsaved-changes') return CONSTANTS.MESSAGES.IMAGE_UNSAVED_CHANGES
    else return CONSTANTS.MESSAGES.REMOVE_IMAGE_HEADING
  })()
  const description = (() => {
    if (type === 'unsaved-changes') return CONSTANTS.MESSAGES.IMAGE_UNSAVED_CHANGES_DESCRIPTION
    else return CONSTANTS.MESSAGES.REMOVE_IMAGE_DESCRIPTION
  })()
  const successButtonLabel = 'Remove'
  const cancelButtonLabel = 'Cancel'

  const handleSuccessRemoveImage = () => {
    if (currentTab === 0) {
      setMappedImageData(mappedImageData.filter((d: any, i: any) => i !== idx))
    } else {
      setUnMappedImageData(unMappedImageData.filter((d: any, i: any) => i !== idx))
    }
    handleClose()
  }

  setDialogProps({
    open: open,
    heading: heading,
    description: description,
    color: color,
    Icon: Icon,
    iconColor: iconColor,
    successButtonLabel: successButtonLabel,
    handleSuccess: handleSuccessRemoveImage,
    cancelButtonLabel: cancelButtonLabel,
    handleClose: handleClose,
  })
}

export const handleEditImage = (
  index: number,
  currentTab: number,
  mappedImageData: any,
  setMappedImageData: any,
  unMappedImageData: any,
  setUnMappedImageData: any,
  dispatch: any,
  setUpdateImageDialogProps: any,
  handleClose: any,
  attributesImage?: any,
) => {
  const handleSuccess = (idx: number, imageAttr: string) => {
    if (currentTab === 0) {
      const imgDataAddProp = attributesImage?.find((item: any) => item.fieldName === imageAttr)
      if (imgDataAddProp) {
        mappedImageData[idx] = {
          ...mappedImageData[idx],
          name: imgDataAddProp.label,
          fieldName: imgDataAddProp.fieldName,
        }
        setMappedImageData(mappedImageData)
        dispatch(showAlert([true, 'Image file mapped', 'success']))
      }
    } else {
      const imgDataAddProp = attributesImage?.find((item: any) => item.fieldName === imageAttr)
      if (imgDataAddProp) {
        unMappedImageData[idx] = {
          ...unMappedImageData[idx],
          name: imgDataAddProp.label,
          fieldName: imgDataAddProp.fieldName,
        }
        mappedImageData.push(unMappedImageData[idx])
        setMappedImageData(mappedImageData)
        setUnMappedImageData(unMappedImageData.filter((d: any, i: number) => i !== idx))
        dispatch(showAlert([true, 'Image file mapped', 'success']))
      }
    }
    handleClose()
  }

  setUpdateImageDialogProps({
    open: true,
    handleSuccess: (idx: number, imageAttr: string) => handleSuccess(idx, imageAttr),
    handleClose: handleClose,
    idx: index,
  })
}
