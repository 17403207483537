import React, { useEffect, useRef, useState } from 'react'
import downArrowIcon from '../../../Assets/Images/svgs/downArrowIcon.svg'
import upArrowIcon from '../../../Assets/Images/svgs/upArrowIcon.svg'
import CONSTANTS from '../../../Constants'
import { secondsToHMS } from '../../../Helpers/DurationHelper'
import { fetchOptionsValue, getDateInFormat } from '../../../Helpers/Util'
import './AssetViewMoreAndLessValue.scss' // CSS file for line clamping

interface Option {
  value: any
  label: string
}

interface ElementAttribute {
  dataType?: string
  options?: Option[]
}

interface Element {
  attribute?: ElementAttribute
}

interface AssetViewMoreAndLessValue {
  value: any
  fieldType: string
  tagInput: boolean
  dataType: string
  element: Element
}

const AssetViewMoreAndLessValue: React.FC<AssetViewMoreAndLessValue> = ({
  value,
  fieldType,
  tagInput,
  dataType,
  element,
}) => {
  const textRef = useRef<any>(null)
  const [isClamped, setIsClamped] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [displayValue, setDisplayValue] = useState('')
  const [textLineCount, setTextLineCount] = useState(0)

  const getDisplayValue = () => {
    if (fieldType !== CONSTANTS.FIELDTYPES.FILE) {
      if ((typeof value !== 'boolean' && !value) || (Array.isArray(value) && value.length === 0)) {
        return CONSTANTS.DEFAULT_TEXT
      }
      if (
        tagInput ||
        (fieldType === CONSTANTS.FIELDTYPES.SELECT &&
          dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN)
      ) {
        return Array.isArray(value)
          ? value.slice(0, isExpanded ? undefined : CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS)
          : typeof value === 'boolean'
          ? value
            ? 'Yes'
            : 'No'
          : value
      } else if (fieldType === CONSTANTS.FIELDTYPES.SELECT) {
        if (Array.isArray(value)) {
          return value
            .map((item: any) => fetchOptionsValue(element?.attribute?.options, item))
            .slice(0, isExpanded ? undefined : CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS)
        } else {
          return fetchOptionsValue(element?.attribute?.options, value)
        }
      } else if (
        fieldType === CONSTANTS.FIELDTYPES.DATE ||
        fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
        fieldType === CONSTANTS.FIELDTYPES.TIME
      ) {
        return dataType === CONSTANTS.FIELDTYPES.NUMBER
          ? secondsToHMS(value)
          : getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME)
      } else if (
        fieldType === CONSTANTS.FIELDTYPES.CHECKBOX ||
        fieldType === CONSTANTS.FIELDTYPES.RADIO ||
        fieldType === CONSTANTS.FIELDTYPES.SWITCH
      ) {
        return value ? 'Yes' : 'No'
      } else {
        return value
      }
    }
    return ''
  }

  useEffect(() => {
    setDisplayValue(getDisplayValue())
  }, [isExpanded, value])

  useEffect(() => {
    const checkClamped = () => {
      const element = textRef.current
      if (element) {
        const { clientHeight, scrollHeight } = element
        setIsClamped(scrollHeight > clientHeight)
      }
      if (
        (fieldType === CONSTANTS.FIELDTYPES.TEXT || fieldType === CONSTANTS.FIELDTYPES.TEXTAREA) &&
        textRef.current
      ) {
        const lines = Math.ceil(
          textRef?.current?.scrollHeight /
            parseInt(window.getComputedStyle(textRef.current).lineHeight),
        )
        setTextLineCount(lines)
      }
    }

    checkClamped()

    // Optional: Check on window resize in case of responsive layout changes
    window.addEventListener('resize', checkClamped)
    return () => window.removeEventListener('resize', checkClamped)
  }, [displayValue])

  return (
    <div className='sub-heading-6 view__role-list text-left col36 word-wrap display-value-container flex-row-between'>
      <div
        ref={textRef}
        className={`text-value${Array.isArray(displayValue) ? '' : ' clamped-text'}${
          isExpanded ? ' expanded' : ''
        }${isClamped && !isExpanded ? ' clamped' : ''}`}
        style={{ WebkitLineClamp: isExpanded ? 'unset' : CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_LINES }}
      >
        {Array.isArray(displayValue) ? displayValue.join(', ') : displayValue}
      </div>
      {((Array.isArray(value) && value.length > CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS) ||
        (!Array.isArray(value) && textLineCount > CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_LINES)) && (
        <div
          className={`paragraph-medium-bold-2 col1 text-right cursor-pointer view-more-less min-w-fit-content${
            isClamped && !isExpanded && !Array.isArray(displayValue)
              ? ' not-fixed-view-more'
              : ' fixed-view-more'
          }`}
        >
          <span onClick={() => setIsExpanded(!isExpanded)} data-testid='viewMoreLess'>
            {isExpanded ? 'View Less' : 'View More'}
            {Array.isArray(displayValue) ? ` (${value.length})` : ''}
            <img src={isExpanded ? upArrowIcon : downArrowIcon} className='pl-05' />
          </span>
        </div>
      )}
    </div>
  )
}

export default AssetViewMoreAndLessValue
