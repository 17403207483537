import React, { Suspense } from 'react'
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import RoleUsers from '../Components/MangeRole/RoleEditor/RoleUsers'
import Loader from '../Components/Shared/Loader'
import { PAGES_ROUTES } from '../Constants/PagesRoutes'
import { PAGE_URL, PageNames } from '../Constants/PagesRoutes/PageURLs'
import DefaultLayout from '../Layouts/DefaultLayout'
import PlainLayout from '../Layouts/PlainLayout'
import SearchAdvanced from '../Pages/AdvancedSearch'
import CreateUpdateAttribute from '../Pages/AttributeRepository/Editor'
import Home from '../Pages/Home'
import HomeAccessError from '../Pages/Home/HomeAccessError'
import BulkImageUpload from '../Pages/ManageContent/BulkImageUpload'
import ToolTipList from '../Pages/ManageTips'
import MastersList from '../Pages/Masters'
import AffiliatePartnersAddEdit from '../Pages/Masters/AffiliatePartners/AffiliatePartnersAddEdit'
import CountriesAddEdit from '../Pages/Masters/Countries/CountriesAddEdit'
import DownstreamPlatformsAddEdit from '../Pages/Masters/DownstreamPlatforms/DownstreamPlatformsAddEdit'
import EntitlementPackagesAddEdit from '../Pages/Masters/EntitlementPackages/EntitlementPackagesAddEdit'
import LanguagesAddEdit from '../Pages/Masters/LanguagesComp/LanguagesAddEdit'
import CreateSystemConfiguration from '../Pages/Masters/SystemConfiguration/Edit/CreateSystemConfigration'
import CreatePartners from '../Pages/Partners'
import ReportsList from '../Pages/ReportsPage'
import SaveSearchList from '../Pages/SaveSearchList'
import { authenticationGuard } from './auth'
const NotFound = React.lazy(() => import('../Pages/Erros/404'))
const ErrorFound = React.lazy(() => import('../Pages/Erros/500'))
const AccessDeniedComponent = React.lazy(() => import('../Pages/AccessDenied'))
const ComingSoonComponent = React.lazy(() => import('../Pages/ComingSoon'))
const InternalServerErrorComponent = React.lazy(() => import('../Pages/InternalServerError'))
const Login = React.lazy(() => import('../Pages/Login'))
const AuthCallback = React.lazy(() => import('../Pages/Auth'))
const CommonListing = React.lazy(() => import('../Components/CommonListing'))
const CreateUpdateRole = React.lazy(() => import('../Pages/ManageRole/CreateUpdateRole'))
const CreateUpdateUser = React.lazy(() => import('../Pages/ManageUser/CreateUpdateUser'))
const AssetTypeDefinitonMain = React.lazy(() => import('../Pages/AssetTypeDefiniton/Editor'))
const AssociatedAsset = React.lazy(() => import('../Pages/AssociatedAssets'))
const DataMigrationReportsListComponent = React.lazy(
  () => import('../Pages/ReportsPage/DataMigrationReports'),
)
const AuditTrailsList = React.lazy(() => import('../Pages/AuditTrails/AuditTrailsList'))
const CommonAuditLogsList = React.lazy(() => import('../Pages/AuditTrails/AuditTrailsListing'))
const CommonAuditLogsDetails = React.lazy(() => import('../Components/CommonAuditDetails'))
const AssetListingComponent = React.lazy(
  () => import('../Pages/ManageContent/AssetListingComponent'),
)
const AuditTrailsListDetailPage = React.lazy(
  () => import('../Components/ManageContent/ManageAsset/AssetEditor/AuditTrailsListDetailPage'),
)
const CreateUpdateAsset = React.lazy(() => import('../Pages/ManageContent/CreateUpdateAsset'))
const SystemConfigurationEdit = React.lazy(
  () => import('../Pages/Masters/SystemConfiguration/Edit'),
)
const CreateUpdateGeoPolicy = React.lazy(() => import('../Pages/GeoPolicy/CreateUpdateGeopolicy'))
const AssetStatusListing = React.lazy(() => import('../Components/Home/AssetStatusListing'))
const BulkUpdate = React.lazy(() => import('../Pages/BulkUpdate'))
const ManageFiles = React.lazy(() => import('../Pages/ManageFiles'))

const {
  LoginPage,
  AuthValidatePage,
  ErrorPage,
  RootPage,
  HomePage,
  MetaMaster,
  ManageTips,
  AccessDenied,
  ComingSoon,
  InternalServerError,
  Masters,
  AffiliatePartners,
  ViewEditAffiliatePartnersPage,
  AffiliatePartnersId,
  Languages,
  ViewEditLanguagesPage,
  LanguagesId,
  Countries,
  ViewEditCountriesPage,
  CountriesId,
  EntitlementPackages,
  ViewEditEntitlementPackagesPage,
  EntitlementPackagesId,
  DownstreamPlatforms,
  ViewEditDownstreamPlatformsPage,
  DownstreamPlatformsId,
  SystemConfigurationPageType,
  SystemConfigurationId,
  PageType,
  Reports,
  DataMigrationReports,
  AdvancedSearch,
  CreateSystemConfirgurationPage,
  CreateSystemConfigurationPageType,
  SaveSearch,
} = PAGES_ROUTES

export const routes: RouteObject[] = [
  {
    path: LoginPage,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
    errorElement: <ErrorFound />,
  },
  {
    path: AuthValidatePage,
    element: (
      <Suspense fallback={<Loader />}>
        <AuthCallback />
      </Suspense>
    ),
    errorElement: <ErrorFound />,
  },
  {
    path: ErrorPage,
    element: (
      <Suspense fallback={<Loader />}>
        <InternalServerErrorComponent />
      </Suspense>
    ),
  },
  {
    path: PAGES_ROUTES.Asset,
    element: <PlainLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: `:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/${PAGES_ROUTES.AuditTrails}/:contentIdNumber/:blitzId`,
        element: <AuditTrailsList />,
        loader: authenticationGuard,
      },
      {
        path: `:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/${PAGES_ROUTES.AuditTrails}/:contentIdNumber/:blitzId/view/:auditId`,
        element: <AuditTrailsListDetailPage />,
        loader: authenticationGuard,
      },
      {
        path: `:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/${PAGES_ROUTES.AssociatedAssets}/:assetId`,
        element: <AssociatedAsset />,
      },
      {
        path: ':pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
        element: <CreateUpdateAsset />,
      },
      {
        path: 'bulk-image-upload/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
        element: <BulkImageUpload />,
      },
    ],
  },
  {
    path: '/home/asset-state-details/',
    element: <PlainLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ':contentState',
        element: <AssetStatusListing />,
        loader: authenticationGuard,
      },
      {
        path: AdvancedSearch,
        element: <SearchAdvanced />,
      },
      {
        path: SaveSearch,
        element: <SaveSearchList />,
      },
      {
        path: ':contentState/access-denied',
        element: <HomeAccessError />,
      },
    ],
  },
  {
    path: RootPage,
    element: <DefaultLayout />,
    errorElement: <ErrorFound />,
    children: [
      {
        path: HomePage,
        element: <Home />,
        loader: authenticationGuard,
      },

      /**
       * * * * * * * * * * * * * * * * * * * * * * Roles Routes Start * * * * * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.Role],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.RoleCreate],
        element: <CreateUpdateRole />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.RoleViewEdit],
        element: <CreateUpdateRole />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.RoleAssociatedUsers],
        element: <RoleUsers />,
        loader: authenticationGuard,
      },

      /**
       * * * * * * * * * * * * * * * * * * * * * * Roles Routes End * * * * * * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * Users Routes Start * * * * * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.User],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.UserCreate],
        element: <CreateUpdateUser />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.UserEdit],
        element: <CreateUpdateUser />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.UserView],
        element: <CreateUpdateUser />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * Users Routes End * * * * * * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * Attribute Repo Routes Start * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.AttributeRepo],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AttributeRepoCreate],
        element: <CreateUpdateAttribute />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AttributeRepoViewEdit],
        element: <CreateUpdateAttribute />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * Attribute Repo Routes End * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * Asset Defination Routes Start * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.AssetsDefination],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AssetsDefinationCreate],
        element: <AssetTypeDefinitonMain />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AssetsDefinationViewEdit],
        element: <AssetTypeDefinitonMain />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * Asset Defination Routes End * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Geo Policy Routes Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.GeoPolicy],
        element: <CommonListing />,
        loader: authenticationGuard,
      },

      {
        path: PAGE_URL[PageNames.GeoPolicyCreate],
        element: <CreateUpdateGeoPolicy />,
      },
      {
        path: PAGE_URL[PageNames.GeoPolicyViewEdit],
        element: <CreateUpdateGeoPolicy />,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Geo Policy Routes End * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Partner Management Routes Start * * * * * * * * * * * * * * * * * *
       */

      {
        path: PAGE_URL[PageNames.Partners],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.PartnersCreate],
        element: <CreatePartners />,
      },
      {
        path: PAGE_URL[PageNames.PartnersViewEdit],
        element: <CreatePartners />,
      },

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Partner Management Routes End * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Bulk Update Routes Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.BlukUpdateAddEdit],
        element: <BulkUpdate />,
      },
      {
        path: PAGE_URL[PageNames.BlukUpdate],
        element: <CommonListing />,
        loader: authenticationGuard,
      },

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Bulk Update Routes End * * * * * * * * * * * * * * * * * *
       */
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Manage Files Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.ManageFiles],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.ManageFilesAddEdit],
        element: <ManageFiles />,
      },
      /**
       * /**
       * * * * * * * * * * * * * * * * * * * * * * * Manage Files End * * * * * * * * * * * * * * * * * *
       */
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Master Routes Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.Masters],
        element: <MastersList />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.MasterTypeList],
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${AffiliatePartners}/${ViewEditAffiliatePartnersPage}/${AffiliatePartnersId}`,
        element: <AffiliatePartnersAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${AffiliatePartners}/${ViewEditAffiliatePartnersPage}`,
        element: <AffiliatePartnersAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${Languages}/${ViewEditLanguagesPage}/${LanguagesId}`,
        element: <LanguagesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${Languages}/${ViewEditLanguagesPage}`,
        element: <LanguagesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${Countries}/${ViewEditCountriesPage}/${CountriesId}`,
        element: <CountriesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${Countries}/${ViewEditCountriesPage}`,
        element: <CountriesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${EntitlementPackages}/${ViewEditEntitlementPackagesPage}/${EntitlementPackagesId}`,
        element: <EntitlementPackagesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${EntitlementPackages}/${ViewEditEntitlementPackagesPage}`,
        element: <EntitlementPackagesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${DownstreamPlatforms}/${ViewEditDownstreamPlatformsPage}/${DownstreamPlatformsId}`,
        element: <DownstreamPlatformsAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${DownstreamPlatforms}/${ViewEditDownstreamPlatformsPage}`,
        element: <DownstreamPlatformsAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${SystemConfigurationPageType}/${PageType}`,
        element: <SystemConfigurationEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${SystemConfigurationPageType}/${PageType}/${SystemConfigurationId}`,
        element: <SystemConfigurationEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${MetaMaster}/${Masters}/${CreateSystemConfigurationPageType}/${CreateSystemConfirgurationPage}`,
        element: <CreateSystemConfiguration />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Master Routes End * * * * * * * * * * * * * * * * * *
       */

      /**
       * * * * * * * * * * * * * * * * * * * * * * * Audit Logs Routes Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: PAGE_URL[PageNames.AuditLogsUserAndRole],
        element: <CommonAuditLogsList />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsUserAndRoleDetails],
        element: <CommonAuditLogsDetails />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsMaster],
        element: <CommonAuditLogsList />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsMasterDetails],
        element: <CommonAuditLogsDetails />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsTranscoding],
        element: <CommonAuditLogsList />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsTranscodingDetails],
        element: <CommonAuditLogsDetails />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsContent],
        element: <CommonAuditLogsList />,
        loader: authenticationGuard,
      },
      {
        path: PAGE_URL[PageNames.AuditLogsContentDetails],
        element: <CommonAuditLogsDetails />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Audit Logs Master Routes End * * * * * * * * * * * * * * * * * *
       */

      {
        path: `manage-content/${PAGES_ROUTES.contentCategory}`,
        element: <AssetListingComponent stateDetailsPage={false} />,
        loader: authenticationGuard,
      },

      {
        path: AccessDenied,
        element: <AccessDeniedComponent />,
      },
      {
        path: ComingSoon,
        element: <ComingSoonComponent />,
      },
      {
        path: InternalServerError,
        element: <InternalServerErrorComponent />,
      },
      {
        path: `${MetaMaster}/${ManageTips}`,
        element: <ToolTipList />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Reports Routes Start * * * * * * * * * * * * * * * * * *
       */
      {
        path: Reports,
        element: <ReportsList />,
        loader: authenticationGuard,
      },
      {
        path: `${Reports}/${DataMigrationReports}`,
        element: <DataMigrationReportsListComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${Reports}/content-ingestion-report`,
        element: <CommonListing />,
        loader: authenticationGuard,
      },
      /**
       * * * * * * * * * * * * * * * * * * * * * * * Reports Routes End * * * * * * * * * * * * * * * * * *
       */
      {
        path: AdvancedSearch,
        element: <SearchAdvanced />,
      },
      {
        path: '/',
        element: <Navigate to='/home' replace />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default router
