import ActivateRoleIcon from '../../../Assets/Images/activate-role-icon.png'
import ActivateUserIcon from '../../../Assets/Images/activate-user-icon.png'
import ActiveIcon from '../../../Assets/Images/active-icon.png'
import CopyClipboardIcon from '../../../Assets/Images/clone-icon.png'
import DeactivateRoleIcon from '../../../Assets/Images/deactivate-role-icon.png'
import DeactivateUserIcon from '../../../Assets/Images/delete-deactivate-user-icon.png'
import DeleteRoleIcon from '../../../Assets/Images/delete-role-icon.png'
import DeleteRolePriIcon from '../../../Assets/Images/delete-role-pri-icon.png'
import DeleteRoleWarIcon from '../../../Assets/Images/delete-role-warn-icon.png'
import DialogEditIcon from '../../../Assets/Images/dialog-edit-icon.svg'
import DialogViewIcon from '../../../Assets/Images/dialog-view-icon.svg'
import ExportLimitCloseIcon from '../../../Assets/Images/export-limit-close-icon.png'
import AssetLinkGridWarnIcon from '../../../Assets/Images/featured-icon-red.png'
import AssetLinkGridIcon from '../../../Assets/Images/featured-icon.png'
import GreentickIcon from '../../../Assets/Images/gren-tick-icon.png'
import InactiveIcon from '../../../Assets/Images/inactive-icon.png'
import IngestionReportErrorIcon from '../../../Assets/Images/svgs/ingestion-report-error.svg'
import PinnedIcon from '../../../Assets/Images/svgs/pined-icon.svg'

interface IProps {
  IconName: any
}

// Create a mapping object for icons
const iconMap: Record<string, string> = {
  ActivateRoleIcon,
  ActivateUserIcon,
  ActiveIcon,
  CopyClipboardIcon,
  DeactivateRoleIcon,
  DeactivateUserIcon,
  DeleteRoleIcon,
  DeleteRoleWarIcon,
  DeleteRolePriIcon,
  DialogEditIcon,
  DialogViewIcon,
  ExportLimitCloseIcon,
  AssetGridIcon: AssetLinkGridIcon,
  AssetGridWarnIcon: AssetLinkGridWarnIcon,
  GreentickIcon,
  InactiveIcon,
  Pinned: PinnedIcon,
  IngestionReportErrorIcon,
}

const ModalIcon = ({ IconName }: IProps) => {
  // Get the corresponding icon from the map, or use a fallback (e.g., empty string)
  const iconSrc = iconMap[IconName] || ''

  return <img src={iconSrc} alt={IconName} />
}

export default ModalIcon
