import { VideoAssetGroupConstants } from '../../../../../Constants'
import { isValidArray, updateAudioLanguage } from '../../../../../Helpers/Util'

export const prepareNewAssetGroupPayload = (contentId: string, assetGroupName: string) => {
  if (contentId && assetGroupName) {
    return {
      contentId: contentId,
      assetGroupName: assetGroupName,
      isIntegrated: false,
      validateAssetGroup: false,
    }
  }
  return null
}

export const prepareRenameAssetGroupPayload = (
  contentId: string,
  assetGroupUUID: string,
  assetGroupName: string,
) => {
  if (contentId && assetGroupUUID) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupUUID,
      assetGroupName: assetGroupName,
    }
  }
  return null
}

export const prepareLinkAssetGroupPayload = (contentId: string, assetGroupUUID: string) => {
  if (contentId && assetGroupUUID) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupUUID,
      linkContent: true,
    }
  }
  return null
}

export const prepareUnlinkAssetGroupPayload = (contentId: string, assetGroupTmpId: string) => {
  if (contentId && assetGroupTmpId) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupTmpId,
    }
  }
  return null
}

const getAddEventType = (forType: string) => {
  switch (forType) {
    case VideoAssetGroupConstants.VIDEO_LABEL:
      return VideoAssetGroupConstants.VIDEO_ADD
    case VideoAssetGroupConstants.AUDIO_LABEL:
      return VideoAssetGroupConstants.AUDIO_ADD
    case VideoAssetGroupConstants.INTEGRATED_LABEL:
      return VideoAssetGroupConstants.INTEGRATED_ADD
    default:
      return ''
  }
}

export const prepareFilesUpdateAssetGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  files: [],
  forType: string,
  multiAsset: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: getAddEventType(forType),
    }
    if (forType === VideoAssetGroupConstants.VIDEO_LABEL) {
      const tmpArr = files?.map((item: any) => item?.Key)
      assetGroupDetails?.video?.map((item: any) => {
        tmpArr.push(item?.path)
      })
      payload['video'] = tmpArr || []
      payload['isIntegrated'] = false
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      const tmpArr: any = []
      files?.map((item: any) => {
        tmpArr.push({ language: '', path: item?.Key })
      })
      assetGroupDetails?.audio?.map((item: any) => {
        tmpArr.push(item)
      })
      payload['audio'] = tmpArr || []
      payload['isIntegrated'] = false
    }
    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      const tmpArrV = files?.map((item: any) => item?.Key)
      assetGroupDetails?.video?.map((item: any) => {
        tmpArrV.push(item?.path)
      })
      payload['video'] = tmpArrV || []

      const tmpArrA: any = []
      files?.map((item: any) => {
        tmpArrA.push({ language: [], path: item?.Key })
      })
      assetGroupDetails?.audio?.map((item: any) => {
        tmpArrA.push(item)
      })
      payload['audio'] = tmpArrA || []
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

export const getSearchLabel = (type: string) => {
  switch (type) {
    case 'video':
      return 'Search Video Files'
    case 'audio':
      return 'Search Audio Files'
    case 'integrated':
      return 'Search Integrated Files'
    default:
      return 'Search Asset Group'
  }
}

const getRemoveEventType = (forType: string) => {
  switch (forType) {
    case VideoAssetGroupConstants.VIDEO_LABEL:
      return VideoAssetGroupConstants.VIDEO_REMOVE
    case VideoAssetGroupConstants.AUDIO_LABEL:
      return VideoAssetGroupConstants.AUDIO_REMOVE
    case VideoAssetGroupConstants.INTEGRATED_LABEL:
      return VideoAssetGroupConstants.INTEGRATED_REMOVE
    default:
      return ''
  }
}

export const prepareFilesDeleteAssetGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  index: number,
  forType: string,
  multiAsset: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: getRemoveEventType(forType),
    }
    if (forType === VideoAssetGroupConstants.VIDEO_LABEL) {
      const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.video))
      copyArr?.splice(index, 1)
      const tmpArr = copyArr?.map((item: any) => item?.path)
      payload['video'] = tmpArr || []
      payload['isIntegrated'] = false
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
      copyArr?.splice(index, 1)
      payload['audio'] = copyArr || []
      payload['isIntegrated'] = false
    }
    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      const copyArrA = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
      copyArrA?.splice(index, 1)
      payload['audio'] = copyArrA || []

      const copyArrB = JSON.parse(JSON.stringify(assetGroupDetails?.video))
      copyArrB?.splice(index, 1)
      const tmpArr = copyArrB?.map((item: any) => item?.path)
      payload['video'] = tmpArr || []
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

const updateIntegratedLanguage = (assetGroupDetails: any, language: any, atIndex: any) => {
  let languagesArr: any = []
  if (language && isValidArray(language)) {
    languagesArr = language.map((element: any) => element.key)
  }
  const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
  copyArr.forEach((item: any, index: number) => {
    if (index === atIndex) {
      item.language = languagesArr || []
    }
  })
  return copyArr || []
}

export const prepareFilesLanguagePayload = (
  contentId: string,
  assetGroupDetails: any,
  forType: string,
  language: any,
  atIndex: number,
  multiAsset: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: VideoAssetGroupConstants.LANGUAGE,
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      payload['audio'] = updateAudioLanguage(assetGroupDetails, language, atIndex)
      payload['isIntegrated'] = false
    }

    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      payload['audio'] = updateIntegratedLanguage(assetGroupDetails, language, atIndex)
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

export const prepareFetchValidatePayload = (contentId: string, assetGroupDetails: any) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
    }
  }
  return null
}

export const prepareWorkflowPayload = (
  contentId: string,
  assetGroupDetails: any,
  assetGroupEvent: string,
) => {
  if (contentId && assetGroupDetails?.tmpId) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.tmpId,
      assetGroupEvent: assetGroupEvent,
      contentVersionId: contentId,
    }
  }
  return null
}

export const getIntegratedLanguageCompleteName = (
  languages: Array<string>,
  languageList: any[],
  filterOnValue = false,
) => {
  try {
    const resultArr: Array<string> = []
    if (isValidArray(languages)) {
      languages.forEach((language: any) => {
        if (language === 'empty') {
          resultArr.push('EMPTY')
        } else {
          const languageValueUC = getLanguageCompleteName(language, languageList, filterOnValue)
          resultArr.push(languageValueUC)
        }
      })
    }
    return resultArr.filter((item) => item?.length)
  } catch (error) {
    return []
  }
}

export const getLanguageCompleteName = (
  language: string,
  languageList: any[],
  filterOnValue = false,
) => {
  const matchedLang = languageList?.find?.((item: any) => {
    if (filterOnValue) {
      return (
        item?.value?.toUpperCase() === language?.toUpperCase() ||
        item?.key === language?.toUpperCase()
      )
    } else {
      return item?.key === language?.toUpperCase()
    }
  })
  return matchedLang?.value || ''
}

export const prepareMediaProfileGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  mediaProfileValue: string,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      mediaProfile: mediaProfileValue,
      eventType: VideoAssetGroupConstants.MEDIA_PROFILE,
    }
  }
  return null
}
/**
 * Preparing payload for transcoding process type api
 *
 * Renders Object of key value pare of required payload.
 *
 * @function
 * @param contentId - Content Id of selected asset group.
 * @param assetGroupDetails - containing details of assetgroup.
 * @param transcodingprocessTypeValue - Value of selected transcoding process type from dropdown.
 * @returns returning payload with expected key value pare
 */
export const prepareTranscodingProcessTypePayload = (
  contentId: string,
  assetGroupDetails: any,
  transcodingprocessTypeValue: string,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      mediaProfile: assetGroupDetails.mediaProfile,
      assetType: transcodingprocessTypeValue,
      eventType: VideoAssetGroupConstants.TRANSCODING_PROCESS_TYPE,
    }
  }
  return null
}

export const prepareIntegratedCheckboxPayload = (
  contentId: string,
  assetGroupDetails: any,
  isIntegrated: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      isIntegrated: isIntegrated,
    }
  }
  return null
}

export const getFieldError = (item: any, fieldErrors: any) => {
  return fieldErrors?.[item.fieldName] ?? ''
}

/**
 * Extracts the MIME type for a given URL based on its file extension.
 * @param {string} url - The URL for which the MIME type is to be extracted.
 * @returns {string | undefined} - The MIME type corresponding to the given URL's file extension. Returns undefined if the file extension is not recognized.
 */
export const extractType = (url: string) => {
  if (url.indexOf('m3u8') !== -1) {
    return 'application/x-mpegURL'
  } else if (url.indexOf('mpd') !== -1) {
    return 'application/dash+xml'
  } else {
    return
  }
}

/**
 * Comparator function for sorting language objects based on their keys.
 * @param {any} a - The first language object to compare.
 * @param {any} b - The second language object to compare.
 * @returns {number} - An integer indicating the relative ordering of the two language objects.
 */
export const languagesSort = (a: any, b: any) => {
  if (a.key < b.key) {
    return -1
  }
  if (a.key > b.key) {
    return 1
  }
  return 0
}

export const prepareLinkedAssetGroupIdsPayload = (contentId: string, assetGroupDetails: any) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: true,
    }
  }
  return null
}

type ErrorDetails = {
  [key: string]: string
}[]

interface Response {
  error_details?: ErrorDetails
  'error-details'?: ErrorDetails | { [key: string]: string }
}

export const processTranscodingErrors = (response: Response): string[] => {
  const errors: string[] = []

  if (response?.error_details?.length) {
    response.error_details.forEach((ele) => {
      errors.push(...(typeof ele === 'string' ? [ele] : Object.values(ele)))
      errors.push(' ')
    })
  } else {
    if (Array.isArray(response['error-details'])) {
      response['error-details'].forEach((ele) => {
        errors.push(...Object.values(ele))
        errors.push(' ')
      })
    } else if (response['error-details'] && typeof response['error-details'] === 'object') {
      if (!errors.length && Object.keys(response['error-details']).length) {
        Object.entries(response['error-details']).forEach(([key, value]) => {
          errors.push(`${key}: "${value}"`)
          errors.push(' ')
        })
      } else {
        errors.push(JSON.stringify(response))
        errors.push(' ')
      }
    } else {
      errors.push(JSON.stringify(response))
      errors.push(' ')
    }
  }

  return errors
}

export const sortS3List = (copyList: any[], sortingFor: string, sortingType: string): any[] => {
  return copyList.sort((a, b) => {
    let valueA, valueB

    if (sortingFor === 'name') {
      valueA = a.Key || '' // Treat blank as an empty string
      valueB = b.Key || ''
    } else if (sortingFor === 'type') {
      valueA = a.type || '' // Treat blank as an empty string
      valueB = b.type || ''
    } else if (sortingFor === 'time') {
      valueA = a.lastUpdatedOn ? new Date(a.lastUpdatedOn).getTime() : null
      valueB = b.lastUpdatedOn ? new Date(b.lastUpdatedOn).getTime() : null
    }

    if (sortingFor === 'time') {
      if (sortingType === 'asc') {
        if (valueA === null && valueB !== null) return -1 // Blank (null) values first
        if (valueB === null && valueA !== null) return 1
        return (valueA as number) - (valueB as number) // Smaller to larger
      } else {
        if (valueA === null && valueB !== null) return 1 // Blank (null) values last
        if (valueB === null && valueA !== null) return -1
        return (valueB as number) - (valueA as number) // Larger to smaller
      }
    } else {
      if (sortingType === 'asc') {
        if (valueA === '' && valueB !== '') return -1 // Blank values first
        if (valueB === '' && valueA !== '') return 1
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
      } else {
        if (valueA === '' && valueB !== '') return 1 // Blank values last
        if (valueB === '' && valueA !== '') return -1
        if (valueA < valueB) return 1
        if (valueA > valueB) return -1
      }
    }
    return 0
  })
}

export const getCurrentStatus = (status: string) => {
  switch (status) {
    case VideoAssetGroupConstants.INITIATED_STATUS[0]:
      return VideoAssetGroupConstants.FETCHING_INPROGRESS
    case VideoAssetGroupConstants.INITIATED_STATUS[1]:
      return VideoAssetGroupConstants.PREVIEW_INPROGRESS
    case VideoAssetGroupConstants.INITIATED_STATUS[2]:
      return VideoAssetGroupConstants.TRANSCODING_INPROGRESS
    case VideoAssetGroupConstants.INITIATED_STATUS[3]:
      return VideoAssetGroupConstants.CANCELLATION_INPROGRESS
    default:
      return ''
  }
}

export const getSuccessStatus = (state: string) => {
  switch (state) {
    case 'validation':
      return 'Fetching Successful'
    case 'transcoding':
      return 'Transcoding Success'
    case 'preview':
      return 'Preview Request Successful'
    default:
      return `${state} is Successfully Done`
  }
}

export const checkMediaDisable = (
  filesInProcess: boolean,
  assetGroupIsTranscoded: boolean,
): boolean => {
  return filesInProcess || assetGroupIsTranscoded
}

export const checkFetchValidateDisable = (
  filesInProcess: boolean,
  assetGroupIsPreviewed: boolean,
  assetGroupIsTranscoded: boolean,
  defaultLanguage: string | null,
  DRMType: string | null,
  assetGroupDetails: {
    assetType?: string
    video?: any[]
    audio?: { language?: string }[]
    isIntegrated?: boolean
  },
): boolean => {
  if (
    filesInProcess ||
    assetGroupIsPreviewed ||
    assetGroupIsTranscoded ||
    !defaultLanguage ||
    !DRMType ||
    !assetGroupDetails?.assetType
  ) {
    return true
  }

  const { video = [], audio = [] } = assetGroupDetails
  if (video.length > 0 && audio.length > 0) {
    let isLangEmptyCount = 0
    audio.forEach((element) => {
      if (assetGroupDetails.isIntegrated && element?.language?.length === 0) {
        isLangEmptyCount++
      } else if (element?.language === '') {
        isLangEmptyCount++
      }
    })
    return isLangEmptyCount > 0
  }

  return true
}

export const checkIsPreviewedDisable = (
  filesInProcess: boolean,
  assetGroupIsTranscoded: boolean,
  assetGroupIsFetchValidated: boolean,
  assetGroupIsPreviewed: boolean,
): boolean => {
  if (filesInProcess || assetGroupIsTranscoded) {
    return true
  }
  return !(assetGroupIsFetchValidated && assetGroupIsPreviewed && !assetGroupIsTranscoded)
}

export const checkPreviewDisable = (
  assetGroupIsFetchValidated: boolean,
  assetGroupIsPreviewed: boolean,
): boolean => {
  return !(assetGroupIsFetchValidated || assetGroupIsPreviewed)
}

export const checkTranscodeDisable = (
  filesInProcess: boolean,
  previewCheck: { isChecked: boolean } | null,
  assetGroupIsFetchValidated: boolean,
  assetGroupIsPreviewed: boolean,
): boolean => {
  if (filesInProcess) {
    return true
  }
  return !(previewCheck?.isChecked && assetGroupIsFetchValidated && assetGroupIsPreviewed)
}

export const checkAnyFilePresent = (
  videoFilesTable: any[],
  audioFilesTable: any[],
  integratedFilesTable: any[],
): boolean => {
  return videoFilesTable.length > 0 || audioFilesTable.length > 0 || integratedFilesTable.length > 0
}

export const getAddLabel = (activeTab = 0) => {
  if (activeTab === 1) return VideoAssetGroupConstants.VIDEO_LABEL
  if (activeTab === 2) return VideoAssetGroupConstants.AUDIO_LABEL
  return VideoAssetGroupConstants.INTEGRATED_LABEL
}

export const getColumns = (activeTab = 0) => {
  if (activeTab === 1) return VideoAssetGroupConstants.VIDEO_COLUMNS
  if (activeTab === 2) return VideoAssetGroupConstants.AUDIO_COLUMNS
  return VideoAssetGroupConstants.INTEGRATED_COLUMNS || []
}

export const getTableRows = (
  activeTab: number,
  videoFilesTable: any,
  audioFilesTable: any,
  integratedFilesTable: any,
) => {
  if (activeTab === 1) return videoFilesTable
  if (activeTab === 2) return audioFilesTable
  return integratedFilesTable || []
}

export const getProgressStatusText = (progressStatus: any) => {
  if (progressStatus?.type === 'validation') return 'Fetching Failed'
  if (progressStatus?.type === 'preview') return 'Preview Failed'
  if (progressStatus?.type === 'cancel') return 'Cancellation Failed'
  return 'Transcoding Failed'
}

export const getDialogType = (activeTab: number, DIALOG_TYPE: any) => {
  if (activeTab === 3) return DIALOG_TYPE.INTEGRATED
  if (activeTab === 1) return DIALOG_TYPE.VIDEO
  return DIALOG_TYPE.AUDIO
}

export const getErrorHeading = (progressStatus: any) => {
  if (progressStatus?.type === 'validation') return 'Fetching Error'
  if (progressStatus?.type === 'preview') return 'Preview Error'
  if (progressStatus?.type === 'cancel') return 'Cancellation Error'
  return 'Transcoding Error'
}

export const getCancelConfirmationDetails = (progressStatus: any) => {
  if (progressStatus?.type === 'preview')
    return {
      title: VideoAssetGroupConstants.CONF_CANCEL_PREVIEW,
      cancelType: VideoAssetGroupConstants.VIDEO_TRANSCODE_STAGES.PREVIEW,
    }
  if (progressStatus?.type === 'transcoding')
    return {
      title: VideoAssetGroupConstants.CONF_CANCEL_TRANSCODING,
      cancelType: VideoAssetGroupConstants.VIDEO_TRANSCODE_STAGES.TRANSCODING,
    }
  return { title: '', cancelType: '' }
}
