import { useEffect, useState } from 'react'
import AssetStatus from '../../Components/Home/AssetStatus'
import ContentCategory from '../../Components/Home/ContentCategory'
import MyDashboard from '../../Components/Home/MyDashboard/MyDashboard'
import RecentActivity from '../../Components/Home/RecentActivity'
import { HOME_PAGE, SYSTEM_CONFIG_APIS } from '../../Constants/ApiDefinations'
import { objectToQueryString } from '../../Helpers/Util'
import updateTagMetaData from '../../Helpers/updateTagMetaData'
import { ViewMastersAPIDefinition } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { setLoader } from '../../Store/Slice/loader.slice'

function Home() {
  const dispatch = useAppDispatch()
  const [assetStatusData, setassetStatusData] = useState<any>([])
  const [metaCategoryList, setmetaCategoryList] = useState([])
  const getHomepageData = async () => {
    const payload = {
      assetStatus: 'TRUE',
      contentCategories: 'TRUE',
      assetType: 'TRUE',
      publishedAssets: 'TRUE',
    }
    const paramsData = objectToQueryString(payload)
    dispatch(setLoader(true))
    const requestedData: any = HOME_PAGE.GET_HOME_PAGE(paramsData)
    const result: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result?.responseCode === 200) {
      setassetStatusData(result.data)
    }
  }
  const getMetaCategories = async () => {
    const metaCatoryCopy: any = []
    const requestedData: ViewMastersAPIDefinition =
      SYSTEM_CONFIG_APIS.LIST_MASTERS('CONTENT_CATEGORY')
    const result: any = await mainApiService(requestedData)
    result?.data?.forEach((ele: any) => {
      let key = ele.key
      if (key === 'INDEPENDENT_VOD_ASSETS') {
        key = 'Independent VOD'
      } else if (key === 'NON_VOD_ASSETS') {
        key = 'Non VOD'
      }
      metaCatoryCopy.push({
        value: ele.key,
        key: key,
      })
    })

    setmetaCategoryList(metaCatoryCopy)
  }
  useEffect(() => {
    updateTagMetaData({ title: 'Homepage' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  useEffect(() => {
    getHomepageData()
    getMetaCategories()
  }, [])
  return (
    <div className='homepage-wrapper'>
      <div className='mb-2'>
        <MyDashboard />
      </div>
      <div className='mb-2'>
        <AssetStatus homepageData={assetStatusData} />
      </div>
      <div className='d-flex column-gap-20 mb-2'>
        <ContentCategory
          homepageData={assetStatusData?.contentCategories}
          metaCategoryList={metaCategoryList}
        />
        <RecentActivity />
      </div>
      {/* <div className='d-flex column-gap-20 mb-2'>
      <AssetType />
      <PublishedAsset />
    </div> */}
    </div>
  )
}

export default Home
