/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import CONSTANTS from '../../Constants'
import { ASSET_CREATION, ASSET_CURD_API, MEDIA_INFO } from '../../Constants/ApiDefinations'
import assetData from '../../Constants/MockAsset'
import {
  assetCRUDAPIDefinitionModal,
  assetViewApiModal,
  ViewAssetModal,
} from '../../Models/AssetModal'
import mainApiService from '../../Services'
import { showAlert } from './alert.slice'
import { setLoader } from './loader.slice'

interface AssetState {
  asset: any
  imageVariantsData?: any
  viewAsset?: ViewAssetModal | null
  contentMetaCategoryData?: any
  addColumn?: any[]
  isAssetSaved?: boolean
  markAsDoneErrors?: string[]
  // viewAsset?: any | null
  imageVariantsCountries?: string[]
  isPrimaryImageAvailable?: boolean
  imageVariantsSegmentIds?: string[]
  s3ImagesList?: any
  s3ImagesContinuationToken?: string
  selectedImagesList?: any
}

const initialState: AssetState = {
  asset: assetData,
  viewAsset: null,
  imageVariantsData: null,
  contentMetaCategoryData: null,
  addColumn: [],
  isAssetSaved: false,
  markAsDoneErrors: [],
  imageVariantsCountries: [],
  isPrimaryImageAvailable: false,
  imageVariantsSegmentIds: [],
  s3ImagesList: [],
  s3ImagesContinuationToken: '',
  selectedImagesList: [],
}

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAsset: (state) => {
      state.asset = assetData
    },
    setViewAssetData: (state, action) => {
      state.viewAsset = action.payload
    },
    setImageVariantsData: (state, action) => {
      state.imageVariantsData = action.payload
    },
    setContentMetaCategoryData: (state, action) => {
      state.contentMetaCategoryData = action.payload
    },
    setAddAssetColumn: (state, action) => {
      state.addColumn = action.payload
    },
    setIsSaveAssetData: (state, action) => {
      state.isAssetSaved = action.payload
    },
    setMarkAsDoneErrors: (state, action) => {
      state.markAsDoneErrors = action.payload
    },
    setImageVariantsCountries: (state, action) => {
      state.imageVariantsCountries = action.payload
    },
    setIsPrimaryImageAvailable: (state, action) => {
      state.isPrimaryImageAvailable = action.payload
    },
    setImageVariantsSegmentIds: (state, action) => {
      state.imageVariantsSegmentIds = action.payload
    },
    setS3ImagesList: (state, action) => {
      const { data } = action.payload
      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      const folders = data?.url?.CommonPrefixes?.length
        ? data?.url?.CommonPrefixes.map((item: any) => ({
            Key: item.Prefix,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FOLDER,
          }))
        : []
      state.s3ImagesList = [...folders, ...list]
      state.s3ImagesContinuationToken = data?.url?.NextContinuationToken ?? null
    },
    setS3ImagesListAppend: (state, action: PayloadAction<any>) => {
      const { data } = action.payload

      const list = data?.url?.Contents?.length
        ? data?.url?.Contents.map((item: any) => ({
            ...item,
            selected: false,
            type: CONSTANTS.ASSET_GROUP_FILE_TYPE.FILE,
            lastUpdatedOn: item.LastModified,
          }))
        : []
      state.s3ImagesList = [...state.s3ImagesList, ...list]
      state.s3ImagesContinuationToken = data?.url?.NextContinuationToken ?? null
    },
    setSelectedImagesList: (state, action: PayloadAction<any>) => {
      state.s3ImagesList = action.payload
    },
  },
})
export const {
  setAsset,
  setViewAssetData,
  setImageVariantsData,
  setContentMetaCategoryData,
  setAddAssetColumn,
  setIsSaveAssetData,
  setMarkAsDoneErrors,
  setImageVariantsCountries,
  setIsPrimaryImageAvailable,
  setImageVariantsSegmentIds,
  setS3ImagesList,
  setS3ImagesListAppend,
  setSelectedImagesList,
} = assetSlice.actions

export const deleteAssetService = createAsyncThunk(
  'asset/delete',
  async (contentId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetViewApiModal = ASSET_CREATION.DELETE_ASSET_REPO(contentId)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)

export const createAssetService = createAsyncThunk(
  'attribute/create',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal = ASSET_CURD_API.CREATE_ASSET(
      payload?.payload,
      payload?.updatedAt,
    )
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const markAsDoneService = createAsyncThunk(
  'attribute/markDone',
  async (payload: { contentId: string; metaCategory: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal = ASSET_CURD_API.MARK_DONE(payload)
    const result = await mainApiService(requestedData)

    dispatch(setLoader(false))

    return result
  },
)

export const generatePresignedUrlForImageAssetVariantService = createAsyncThunk(
  'image/attribute/variant/presignedUrl',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GENERATE_PRESIGNED_URL(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const mediaInfoAddService = createAsyncThunk(
  'assetGroup/mediaInfo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MEDIA_INFO.ADD_MEDIA_INFO(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const mediaInfoUpdateService = createAsyncThunk(
  'assetGroup/updateMediaInfo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MEDIA_INFO.UPDATE_MEDIA_INFO(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const deleteImageVariantService = createAsyncThunk(
  'image/attribute/variant/delete',
  async (
    payload: {
      attributeName: any
      variantId: any
      contentId: any
      targeting: any
      segmentId: any
      updatedAt: any
    },
    { dispatch },
  ) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal =
      ASSET_CURD_API.IMAGE_CONTENT_TYPE.DELETE_IMAGE_VARIANT(
        payload?.attributeName,
        payload?.variantId,
        payload?.contentId,
        payload?.targeting,
        payload?.segmentId,
        payload?.updatedAt,
      )
    const result = await mainApiService(requestedData)

    if (result?.error) {
      dispatch(showAlert([true, result.error.errors.join('\n'), 'error']))
      dispatch(setLoader(false))
      return false
    }

    dispatch(setLoader(false))
    dispatch(showAlert([true, result.message, 'success']))
  },
)

export const getImageDetailsService = createAsyncThunk(
  'image/details',
  async (contentId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal =
      ASSET_CURD_API.IMAGE_CONTENT_TYPE.GET_IMAGE_DETAILS(contentId)
    const result = await mainApiService(requestedData)
    if (result?.error) {
      dispatch(setLoader(false))
      return
    }
    dispatch(setImageVariantsData(result))
    const { data = {} } = result || {}
    const { attributes = {} } = data
    // console.log('attributes >>>>>', attributes)
    let segmentIdsArr: string[] = []
    let targetingsArr: string[] = []
    let primaryExists = false
    for (const [, value] of Object.entries(attributes) as any) {
      for (let i = 0; i < value?.length; i++) {
        const { title = '', segmentId = [], targeting = [], url } = value[i]
        if (title === 'Variant') {
          segmentIdsArr = segmentIdsArr.concat(segmentId)
          targetingsArr = targetingsArr.concat(targeting)
        } else if (title?.toLowerCase() === 'primary' && url) {
          primaryExists = true
        }
      }
    }
    dispatch(setIsPrimaryImageAvailable(primaryExists))
    dispatch(setImageVariantsCountries(Array.from(new Set(targetingsArr))))
    dispatch(setImageVariantsSegmentIds(Array.from(new Set(segmentIdsArr))))
    dispatch(setLoader(false))
  },
)

export const getS3ImagesListAPICaLL = createAsyncThunk(
  'orchestration/getS3ImagesList',
  async (payload: any, { dispatch }) => {
    let reqData: any = {
      filePath: payload?.filePath ?? '',
      pageLimit: 20,
    }
    if (payload?.continuationToken) {
      reqData = { ...reqData, continuationToken: payload.continuationToken }
    }
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GET_S3_IMAGES_LIST(reqData)
    const data: any = await mainApiService(requestedData)
    if (!data?.error) {
      if (payload?.continuationToken) {
        dispatch(setS3ImagesListAppend(data))
      } else {
        dispatch(setS3ImagesList(data))
      }
    }
    return data
  },
)

const assetSelector = (state: { asset: AssetState }) => state.asset

export const selectAsset = createSelector(assetSelector, (state) => state.asset)
export const viewSelectAsset = createSelector(assetSelector, (state) => state.viewAsset)
export const selectAddColumn = createSelector(assetSelector, (state) => state.addColumn)
export const isAssetSavedValue = createSelector(assetSelector, (state) => state.isAssetSaved)
//
export const selectImageVariantsData = createSelector(
  assetSelector,
  (state) => state.imageVariantsData,
)
export const selectContentMetaCategoryData = createSelector(
  assetSelector,
  (state) => state.contentMetaCategoryData,
)
export const selectMarkAsDoneErrors = createSelector(
  assetSelector,
  (state) => state.markAsDoneErrors,
)
export const selectImageVariantsCountries = createSelector(
  assetSelector,
  (state) => state.imageVariantsCountries,
)
export const selectIsPrimaryImageAvailable = createSelector(
  assetSelector,
  (state) => state.isPrimaryImageAvailable,
)
export const selectImageVariantsSegmentIds = createSelector(
  assetSelector,
  (state) => state.imageVariantsSegmentIds,
)
export const selectS3ImagesList = createSelector(assetSelector, (state) => state.s3ImagesList)
export const selectS3ImagesContinuationToken = createSelector(
  assetSelector,
  (state) => state.s3ImagesContinuationToken,
)

export default assetSlice
