import { Grid } from '@mui/material'
import Menu from '@mui/material/Menu'
import { useState } from 'react'
import { MUIButton, MUICheckbox } from '../Shared'
import { FilterSearch } from '../Shared/Filters/FilterSearch'
import '../Shared/Filters/index.scss'

interface HeadersColMenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  headersColListTemp: Array<{ label: string; select: boolean }>
  setHeadersColListTemp: (arg: any) => void
  setHeadersColList: (arg: any) => void
  showLoaderFn: (arg: any) => void
  columnDefaultOrder?: Array<any>
  clear?: boolean
  search?: string
}

const HeadersColMenu = ({
  anchorEl,
  open,
  onClose,
  headersColListTemp,
  setHeadersColListTemp,
  setHeadersColList,
  showLoaderFn,
  clear = false,
  search,
}: HeadersColMenuProps) => {
  const [searchInput, setSearchInput] = useState<any>('')
  let filteredData = headersColListTemp
  if (searchInput) {
    filteredData = headersColListTemp.filter(
      (itemObj: any) => itemObj?.label?.toLowerCase().includes(searchInput?.toLowerCase()),
    )
  }
  return (
    <Menu
      className='border-12 '
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{ minWidth: '268px' }}
    >
      {search && (
        <div className='filter-l-search'>
          <FilterSearch
            placeholder={search}
            onChange={(e: any) => {
              setSearchInput(e.target.value)
            }}
          />
        </div>
      )}
      <div style={{ maxHeight: '200px' }} className='scroll-y'>
        {filteredData?.map((column: any, indx: number) => (
          <div
            key={column.id}
            className='d-flex justify-content-start align-items-center p-8 scroll-x cursor-pointer view__role-list font-12p'
            style={{ border: '1px solid #F3F4F4' }}
            onClick={(e: any) => {
              e.preventDefault()
              filteredData[indx].select = !filteredData[indx].select
              setHeadersColListTemp([...headersColListTemp])
            }}
          >
            <span className='pl-half'>
              <MUICheckbox
                data-testid='check-box'
                handleChange={() => {
                  filteredData[indx].select = !filteredData[indx].select
                  setHeadersColListTemp([...headersColListTemp])
                }}
                checked={column?.select || false}
              />
            </span>
            <div>{column?.label}</div>
          </div>
        ))}
      </div>
      <Grid
        item
        xs={12}
        className='d-flex justify-content-end align-items-center p-6'
        style={{ padding: '12px 14px 12px 12px', borderTop: '1px solid #F3F4F4' }}
      >
        {clear && (
          <Grid
            item
            xs={3}
            className='clear-text'
            data-testid='clear-all-selection'
            onClick={() => {
              headersColListTemp.forEach((item: any) => {
                item.select = false
              })
              setHeadersColListTemp([...headersColListTemp])
            }}
          >
            <span className='primary_ul'>Clear All</span>
          </Grid>
        )}
        <Grid className='d-flex justify-content-end' xs={9} item>
          <MUIButton
            textTransform={'capitalize'}
            variant='outlined'
            size='large'
            color={'secondary'}
            type='button'
            label={'Cancel'}
            width={'20%'}
            handleClickCB={() => {
              onClose()
              setHeadersColListTemp([])
            }}
          />
          &nbsp; &nbsp;&nbsp;
          <MUIButton
            type='button'
            textTransform={'capitalize'}
            variant={'contained'}
            size='large'
            handleClickCB={() => {
              showLoaderFn(true)
              onClose()
              setHeadersColList([...headersColListTemp])
              setHeadersColListTemp([])
              setTimeout(() => {
                showLoaderFn(false)
              }, 1000)
            }}
            label={'Submit'}
            width={'20%'}
          />
        </Grid>
      </Grid>
    </Menu>
  )
}

export default HeadersColMenu
