/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MANAGE_FILE } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface ManageFilesStateI {
  ManageFileDetails: any
}

const initialState: ManageFilesStateI = {
  ManageFileDetails: {},
}

const ManageFilesSlice = createSlice({
  name: 'ManageFiles',
  initialState,
  reducers: {
    resetState: (state: any, _action: PayloadAction<any>) => {
      state.ManageFileDetails = {}
    },
  },
})

export const { resetState } = ManageFilesSlice.actions

export const getManageFileDetails = createAsyncThunk(
  'ManageFiles/getManageFileDetails',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MANAGE_FILE.GET_JOB_DETAILS(payload?.jobId)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default ManageFilesSlice
