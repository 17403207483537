import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useEffect, useState } from 'react'
import { getTipsForPage } from '../../../Helpers/ManageTips'
import { useAppDispatch, useAppSelector } from '../../../Store/Hooks'
import { getAppTooltips } from '../../../Store/Slice/app-tooltips.slice'
import BulbIcon from '../../Icons/Bulb'
import styles from './MUICard.module.scss'

interface MUICardTipsModal {
  tipsArr?: Array<string>
  module?: string
  pageType?: string
  page?: string // For the metacategory uses
}

/**
 * MUITipsCard Component displays a card with tips.
 *
 * @component
 * @example
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   tipsArr={['Tip 1', 'Tip 2']}
 * />
 *
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   module="ExampleModule"
 *   pageType="ExamplePageType"
 * />
 *
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   module="ExampleModule"
 *   pageType="ExamplePageType"
 *   page="ExamplePage"
 * />
 *
 * @param {Object} props - The properties of the MUITipsCard component.
 * @param {Array<string>} [props.tipsArr=[]] - An array of tips to display.
 * @param {string} [props.module] - The module for which the tips are displayed.
 * @param {string} [props.pageType] - The page type for which the tips are displayed.
 * @param {string} [props.page] - The specific page for which the tips are displayed.
 *
 * @returns {React.Element} The rendered MUITipsCard component.
 */
const MUITipsCard = ({ tipsArr = [], module, pageType, page }: MUICardTipsModal) => {
  const dispatch = useAppDispatch()
  const { tooltips } = useAppSelector((state) => state.appTooltips)
  const [tooltipsData, setTooltipsData] = useState<any>([])

  useEffect(() => {
    if (!tooltips.length) {
      dispatch(getAppTooltips() as any)
    }
  }, [])

  useEffect(() => {
    if (tooltips.length && module && pageType) {
      const tootipsData: any = getTipsForPage(tooltips, module, pageType, page)
      setTooltipsData(tootipsData)
    } else {
      setTooltipsData(tipsArr)
    }
  }, [module, pageType, page, tooltips])

  return (
    <Card className={`${styles.tipContainer} mui-tip`} sx={{ width: 250 }}>
      <CardContent className={`${styles.tipCardMain}`}>
        <div className={`${styles.cardHeader}`}>
          <BulbIcon />
          <p className={'sub-heading-4'}>Tips</p>
        </div>
        <div>
          {(() => {
            const tooltipArr = tooltipsData?.length ? tooltipsData : tipsArr
            return (
              !!tooltipArr?.length && (
                <ul className={`${styles.tipsListWrapper}`}>
                  {tooltipArr?.map((tips: string) => {
                    return (
                      <li key={tips}>
                        <p className='paragraph-regular-5'>{tips}</p>
                      </li>
                    )
                  })}
                </ul>
              )
            )
          })()}
        </div>
      </CardContent>
    </Card>
  )
}
export default MUITipsCard
